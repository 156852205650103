@charset "utf-8";
.modal {
    .btn {
        font-size: inherit;
    }
    input[type="checkbox"] {
        display: inline-block;
    }
    .image-object-fit {
        overflow: hidden;
        img {
            width: 500px;
            object-fit: cover;
            height: 100%;
            max-height: 400px;
            margin-bottom: 10px;
        }
    }
}

.modal.offer-modal {
    .btn-primary {
        background-color: $light-blue;
        border-color: $light-blue;
    }
    .modal-dialog {
        .modal-header {
            padding: 0.9em 1.2em 0.8em 1.2em;
            .modal-title {
                margin-bottom: 10px;
            }
            .input-group {
                input[type="text"] {
                    padding: 6px 10px;
                }
            }
            .offerd-clinic {
                font-size: 0.9em;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
        .modal-body {
            min-height: 350px;
            .form-check-input {
                width: 1.2em;
                height: 1.2em;
                margin-top: 0;
            }
            .modal-clinic-name {
                font-size: 1.5rem;
                font-weight: bold;
            }
            .address {
                padding-left: 1.3rem;
                padding-bottom: 0;
            }
        }
    }
}

.modal.job-modal {
    %title-deco {
        border-left: 10px solid $pink;
    }
    .modal-header {
        padding: 1em;
        .modal-title {
            padding: 0.5em;
            font-size: 1.3em;
            @extend %title-deco;
        }
    }
    @media screen and (max-width: $sp) {
        font-size: 1.4rem;
    }
    .modal-body {
        a {
            text-decoration: underline;
        }
        .search-box {
            border: none;
            padding: 0;
            .clinic-info {
                .clinic-info-inner {
                    width: 100%;
                    display: block;
                    .image_wrap {
                        display: flex;
                        gap: 20px;
                        max-width: 800px;
                        overflow-x: auto;
                        scroll-snap-type: x mandatory;
                        box-shadow: 0 3px 10px 0 rgba(#000, 0.1);
                        -webkit-overflow-scrolling: touch;
                        scroll-padding: 1rem;
                        padding: 1rem;
                        margin: 2rem auto;
                        border-radius: 5px;
                        .image-object-fit {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex: 0 0 500px;
                            width: 30px;
                            height: 320px;
                            scroll-snap-align: start;
                            font-size: 1.5rem;
                            @media screen and (max-width: $sp) {
                                flex: 0 0 300px;
                                height: 200px;
                            }
                        }
                    }
                    table {
                        margin-left: 0;
                        tr {
                            grid-template-columns: 25% 75% !important;
                            @media screen and (max-width: $sp) {
                                display: flex;
                                flex-wrap: wrap;
                            }
                            td {
                                &:first-of-type {
                                    &:before {
                                        content: "●";
                                        display: inline-block;
                                        color: $pink;
                                    }
                                    @media screen and (max-width: $sp) {
                                        width: 100%;
                                    }
                                }
                                &:nth-of-type(2) {
                                    @media screen and (max-width: $sp) {
                                        padding-left: 1em;
                                    }
                                }
                                &.line-two {
                                    line-height: 130%;
                                }
                                .h_list {
                                    display: flex;
                                    flex-wrap: wrap;
                                    dt {
                                        width: 100%;
                                    }
                                    dd {
                                        margin-right: 1em;
                                    }
                                }
                                p {
                                    padding-bottom: 0;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal.introduce-modal {
    .text-sm {
        font-size: 1.2rem;
    }
    .modal-dialog {
        .modal-header {
            padding: 1.5rem 2.1rem;
        }
        .modal-body {
            padding: 15px;
            min-height: 300px;
            input {
                padding: 5px;
            }
            .result-box {
                .message {
                    margin-bottom: 0;
                }
                .user-detail {
                    display: grid;
                    grid-template-columns: 26% 1fr;
                    line-height: 140%;
                    margin-bottom: 20px;
                    > dt {
                        grid-column: 1/2;
                        &:before {
                            content: "●";
                            color: $light-blue;
                            display: inline-block;
                            margin-top: 0.1em;
                            margin-right: 0.1em;
                            font-size: 1.4rem;
                        }
                        &.text-sm {
                            & + dd {
                                padding-top: 0.2em;
                            }
                        }
                    }
                    > dd {
                        grid-column: 2/3;
                        margin-top: 0.1em;
                        p {
                            padding-bottom: 0;
                            line-height: 90%;
                        }
                        dl {
                            dt {
                                font-weight: bold;
                            }
                            dd {
                                padding-left: 1em;
                            }
                        }
                    }
                }
            }
        }
        .modal-footer {
        }
    }
    &.lg {
        .modal-dialog {
            .modal-body {
                .result-box {
                    .user-detail {
                        grid-template-columns: 15% 1fr;
                    }
                }
            }
        }
    }
}

.modal.resume-modal {
    .modal-dialog {
        padding: 1rem;
        .modal-content {
            .modal-body {
                padding: 1.5rem;
                .grid-resume {
                    display: grid;
                    grid-template-columns: 25% 1fr;
                    @media screen and (max-width: $sp) {
                        display: block;
                    }
                    dt,
                    dd {
                        line-height: 120%;
                        display: block;
                        text-align: left;
                    }
                    dt {
                        grid-column: 1 / 2;
                        margin-bottom: 1em;
                        small {
                            font-size: 0.6em;
                        }
                        &:before {
                            content: "●";
                        }
                    }

                    dd {
                        display: block;
                        grid-column: 2 /3;
                        margin-bottom: 1em;
                        img.profile {
                            max-width: 250px;
                        }
                        img.licence {
                            max-width: 400px;
                        }
                        p {
                            line-height: 120%;
                            margin-bottom: 0;
                            padding-bottom: 0.5em;
                        }
                    }
                }
            }
        }
    }
}
