@charset "utf-8";

.image-icon-round.talk-icon {
    img {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        object-fit: cover;
        object-position: center;
        @media screen and (max-width: $tb) {
            width: 60px !important;
            height: 60px !important;
        }
    }
}
.image-icon-round.talk-icon.small {
    img {
        width: 45px;
        height: 45px;
    }
}

//chat 共通
.tk-list {
    .tk-one {
        .per-name {
            @media screen and (max-width: $pc) {
                padding-bottom: 0;
            }
        }
    }
}
.line__container {
    @media screen and (min-width: $pc) {
        min-height: 80vh;
    }
    .scroll {
        padding-bottom: 20px;
        .line__center__days {
            clear: both;
            position: relative;
            top: 20px;
        }
        .line__left {
            max-width: 40%;
            @media screen and (min-width: 768px) and (max-width: $pc) {
                max-width: 80%;
            }
        }
    }
    .txt-input {
        .txt-submit {
            input {
                font-size: 2rem;
            }
        }
    }
}

#chat {
    .line__container {
        position: relative;
        #line_content {
        }
    }
}
