@charset "utf-8";
//override style
.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
    -moz-appearance: textfield !important;
}

.w30 {
    width: 30% !important;
}
.w100 {
    width: 100% !important;
}

.d-none {
    display: none !important;
}

.hidden {
    visibility: hidden;
}

.nobreak {
    display: inline-block;
}

textarea {
    padding: 5px;
}

.transparent_color {
    color: transparent;
}
//btn
.btn_green {
    background: $green !important;
}
.swal2-input {
    width: auto;
}
.submit-btn {
    input {
        display: block;
    }
}

// utility
.no-border {
    border: none !important;
}
//common
a.link-default {
    text-decoration: underline;
    @extend %link-blue;
}

.fileup {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    input {
        display: none;
    }
    .title {
        margin-right: 10px;
    }
    .image_wrap {
        position: relative;
        margin-bottom: 10px;
        img {
            width: 200px;
        }
        .delete_btn {
            padding-bottom: 0;
            width: 20px;
            height: 20px;
            text-align: center;
            line-height: 19px;
            background-color: #000;
            color: $white;
            border-radius: 50%;
            position: absolute;
            top: -5px;
            left: -5px;
            cursor: pointer;
        }
    }
    .file_label {
        margin-left: 10px;
        padding: 5px 10px;
        height: 20px;
        background-color: #ddd;
        border: 1px solid #000;
        cursor: pointer;
    }
}

.js-custom-placeholder {
    .placeholder {
        line-height: 110%;
    }
}
