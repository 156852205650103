body.login {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    .wrap {
        flex: 1;
    }
    #footer {
        width: 100%;
    }
}

body.mypage.login {
    .loginform_wrap {
        flex: 1;
    }
}

body.top.login {
    .loginform_wrap {
        flex: 1;
    }
}

.grid-staff {
    display: grid;
    grid-template-columns: 20% 22% 22% 1fr;
    .inner {
        display: block;
        width: 100%;
    }
    .title {
        grid-column: 1/2;
    }
    .regular {
        grid-column: 2/3;
    }
    .parttime {
        grid-column: 3/4;
    }
    .age {
        grid-column: 4/5;
        input {
            width: 60%;
        }
    }
}

.grid-confirm-employ {
    display: grid;
    grid-template-columns: 15% 13% 13%;
    @media screen and (max-width: $sp) {
        grid-template-columns: 30% 25% 25%;
    }
    dt {
        grid-column: 1 / 2;
        margin-bottom: 0.5em;
        &:before {
            content: "●";
        }
        & + dd {
            grid-column: 2 / 3;
        }
        & + dd + dd {
            grid-column: 3 / 4;
        }
    }
}

.grid-admin-employ {
    display: grid;
    grid-template-columns: 20% 15% 1fr;
    @media screen and (max-width: $sp) {
        grid-template-columns: 30% 25% 1fr;
    }
    dt {
        grid-column: 1 / 2;
        margin-bottom: 0.5em;
        &:before {
            content: "●";
        }
        & + dd {
            grid-column: 2 / 3;
        }
        & + dd + dd {
            grid-column: 3 / 4;
        }
    }
}

.grid-confirm-staff {
    display: grid;
    grid-template-columns: 18% 1fr;
    @media screen and (max-width: $sp) {
        grid-template-columns: 30% 1fr;
    }
    dt {
        grid-column: 1 / 2;
    }

    dd {
        grid-column: 2 / 3;
    }
}

.mypage {
    .grid-wrap {
        td {
            &:last-of-type {
                @media screen and (max-width: $sp) {
                    width: 100%;
                }
            }
            .grid-mypage-favorite-employ {
                display: grid;
                dt {
                    grid-column: 1 / 2;
                    & + dd {
                        grid-column: 2 / 3;
                    }
                    & + dd + dd {
                        grid-column: 3 / 4;
                    }
                }
                dd {
                    margin-top: 0;
                }
            }
        }
    }
}

.column-address-grid {
    display: grid !important;
    grid-template-columns: 200px 20px 1fr;
    grid-template-rows: 60px 10px 20px 10px;

    @media screen and (max-width: $sp) {
        grid-template-columns: 100px 20px 1fr;
    }
    select {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
    input {
        grid-column: 3 / 4;
        grid-row: 1 / 2;
    }

    .alert-pref {
        grid-column: 1 / 2;
        grid-row: 5 / 6;
    }

    .alert-addr {
        grid-column: 3 / 4;
        grid-row: 5 / 6;
    }

    .caption {
        margin-top: 10px;
        grid-column: 1 / 4;
        grid-row: 3 / 4;
    }
}

.grid-recruitment {
    p {
        display: grid;
        grid-template-columns: 130px 1fr;
        @media screen and (max-width: $sp) {
            grid-template-columns: 110px 1fr;
        }
        .grid-title {
            grid-column: 1 /2;
            display: flex;
            align-items: center;
        }
        .grid-content {
            grid-column: 2 /3;
            .checkbox-normal {
                margin-left: 10px;
            }
        }
    }
}

.grid-educational-school {
    display: grid;
    grid-template-columns: 200px 1fr;
    .grid-full {
        grid-column: 1 / 3;
    }
    .grid-1 {
        grid-column: 1 / 2;
        select {
            width: 170px;
        }
    }
    .grid-2 {
        grid-column: 2 /3;
        select {
            width: 100px;
        }
    }
}
