.mean-nav #header-wrap {
}

.mean-nav #dropmenu {
    padding: 0px;
    border-bottom: none;
}
.mean-nav #dropmenu li a {
    padding: 10px 20px 10px 50px;
    box-sizing: border-box;
    border-top: none;
}
.mean-nav #dropmenu li {
    width: 100% !important;
    box-sizing: border-box;
    border-left: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.mean-nav #dropmenu li ul {
    padding-top: 0px;
}
a.meanmenu-reveal {
    display: none;
    position: relative;
}
.mean-container .mean-bar {
    position: fixed;
    padding: 4px 0;
    min-height: 42px;
    z-index: 100;
}
.mean-container a.meanmenu-reveal {
    position: fixed;
    background: #ff8a91;
    width: 30px;
    height: 30px;
    padding: 14px 17px 23px 17px;
    font-size: 11px !important;
    top: 0;
    right: 0;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    text-indent: -9999em;
    line-height: 22px;
    display: block;
}
.mean-container a.meanmenu-reveal.meanclose {
    padding: 17px 14px 20px 20px;
}
.mean-container a {
    transition: 0s;
}
_.mean-container a.meanmenu-reveal span {
    display: block;
    background: #fff;
    height: 3px;
    margin-top: 8px;
}
.mean-container a.meanmenu-reveal span {
    display: block;
    background: #fff;
    width: 30px;
    height: 3px;
    right: 35px;
}
.mean-container a.meanmenu-reveal span:first-child {
    position: absolute;
    top: 33px;
}
.mean-container a.meanmenu-reveal span:nth-child(2) {
    position: absolute;
    top: 43px;
}
.mean-container a.meanmenu-reveal span:nth-child(3) {
    position: absolute;
    top: 53px;
}
.mean-container a.meanmenu-reveal.meanclose span:first-child {
    transform: skewY(-45deg);
    width: 28px;
    height: 4px;
    margin-top: 15px;
    position: absolute;
    top: 26px;
    right: 36px;
}
.mean-container a.meanmenu-reveal.meanclose span:nth-child(2) {
    transform: skewY(45deg);
    width: 28px;
    height: 4px;
    margin-top: -4px;
    position: absolute;
    top: 45px;
    right: 36px;
}
.mean-container .mean-nav {
    float: left;
    width: 100%;
    background-color: #ff8a91;
    margin-top: 47px;
}
.mean-container .mean-nav ul {
    padding: 0;
    margin: 0;
    width: 100%;
    list-style-type: none;
}
.mean-container .mean-nav ul li {
    position: relative;
    float: left;
    width: 100%;
}
.mean-container .mean-nav ul li a {
    display: block;
    padding: 1em 5%;
    margin: 0;
    text-align: left;
    color: #fff;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    text-decoration: none;
    text-transform: uppercase;
}
.mean-container .mean-nav ul li li a {
    padding: 1em 7%;
    border-top: 1px solid #f1f1f1;
    text-shadow: none !important;
    visibility: visible;
    margin-top: -1px;
    　font-size: 13px;
}
.mean-container .mean-nav ul li li:first-child a {
    margin-top: 0px;
}
.mean-container .mean-nav ul li a.mean-expand {
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    background: rgba(255, 255, 255, 0.1);
    border: none !important;
    border-left: 1px solid rgba(255, 255, 255, 0.4) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
    height: 43px;
    width: 45px;
    padding: 8px 0px 12px 0px !important;
}
.mean-container .mean-nav ul li a.mean-expand:hover {
    background: rgba(0, 0, 0, 0.9);
}
.mean-container .mean-push {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    clear: both;
}
.mean-nav .wrapper {
    width: 100%;
    padding: 0;
    margin: 0;
}
.mean-container .mean-bar,
.mean-container .mean-bar * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.mean-remove {
    display: none !important;
}
.meanmenu-reveal:after {
    color: #fff;
    /*font-size: 0.6em;*/
    /*margin-left: -6px;*/
    white-space: nowrap;
}
.meanmenu-reveal.meanclose:after {
    display: block;
    /*margin-left: -2px;*/
    margin-top: -4px;
}
.mean-container a.meanmenu-reveal span:last-of-type {
}

/* 医院掲載登録 -------------------------------------------------------------- */
.green-head.mean-container a.meanmenu-reveal {
    background: #5c8260;
}
.green-head.mean-container .mean-nav {
    background-color: #5c8260;
}

/* 管理者ページ -------------------------------------------------------------- */
.blue-head.mean-container a.meanmenu-reveal {
    background: #8bc3d8;
}
.blue-head.mean-container .mean-nav {
    background-color: #8bc3d8;
}

/* タブレット -------------------------------------------------------------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .mean-container a.meanmenu-reveal {
        padding: 24px 35px 33px 35px;
        font-size: initial !important;
    }
    .mean-container .mean-nav {
        margin-top: 64px;
    }
    .mean-container a.meanmenu-reveal.meanclose {
        padding: 24px 33px 33px 37px;
    }
}

/* タブレット・スマホ-------------------------------------------------------------- */
@media print, screen and (max-width: 1024px) {
    .mean-nav #dropmenu li li a {
        padding: 8px 20px;
    }
}

/*　スマホ　*/
@media screen and (max-width: 767px) {
    .mean-container a.meanmenu-reveal span {
        right: 17px;
    }
    .mean-container a.meanmenu-reveal span:first-child {
        position: absolute;
        top: 23px;
    }
    .mean-container a.meanmenu-reveal span:nth-child(2) {
        position: absolute;
        top: 33px;
    }
    .mean-container a.meanmenu-reveal span:nth-child(3) {
        position: absolute;
        top: 43px;
    }
    .mean-container a.meanmenu-reveal.meanclose span:first-child {
        transform: skewY(-45deg);
        width: 28px;
        height: 4px;
        margin-top: 15px;
        position: absolute;
        top: 18px;
        right: 18px;
    }
    .mean-container a.meanmenu-reveal.meanclose span:nth-child(2) {
        transform: skewY(45deg);
        width: 28px;
        height: 4px;
        margin-top: -4px;
        position: absolute;
        top: 37px;
        right: 18px;
    }
}
