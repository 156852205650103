@charset "utf-8";
//gnav
.h-btn {
    a {
        position: relative;
        .badge-count {
            display: block;
            width: 20px;
            height: 20px;
            padding: 2px;
            background-color: $red;
            color: $white;
            border-radius: 50%;
            text-align: center;
            line-height: 20px;
            position: absolute;
            bottom: 3em;
            right: 1em;
            @media screen and (min-width: 913px) and (max-width: $pc) {
                right: 38%;
                bottom: 4rem;
            }
            @media screen and (min-width: 768px) and (max-width: $tb) {
                right: 30%;
                bottom: 4rem;
            }
            @media screen and (max-width: $sp) {
                right: 30%;
                width: 15px;
                height: 15px;
                line-height: 15px;
                bottom: 4rem;
            }
        }
        .badge-count.hide {
            display: none;
        }
    }
}

.header-job-entry {
    .h-btn {
        div.logout-btn {
            a {
                @media screen and (max-width: $sp_s) {
                    height: 60px !important;
                    padding-top: 45px;
                }
            }
        }
    }
}
