%border {
    border: 1px solid $alert-red !important;
}

.ampm.error {
    input[type="time"] {
        @extend %border;
    }
}

.recruitment.error {
    input,
    label {
        @extend %border;
    }
}

.alert-input {
    @extend %border;
}
