.flatpickr.input-group {
    position: relative;
    input {
        width: 28% !important;
    }
    .clear-button {
        position: absolute;
        top: -15px;
        right: 2%;
        cursor: pointer;
        opacity: 0.4;
        @media screen and (max-width: $tb) {
            top: 2px;
        }
    }
    input:placeholder-shown + .clear-button {
        display: none;
    }
}

.flatpickr-calendar {
    width: 200px;
    @media screen and (min-width: $pc) {
        width: 130px;
    }
}
