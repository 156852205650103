@charset "utf-8";

body.top {
    .main-wrapper {
        .main {
            .main-search {
                .submit-btn {
                    &:before {
                        content: "";
                        display: none;
                    }
                    button.submit {
                        padding: 20px 0;
                        text-align: center;
                        margin: 0;
                        width: 100%;
                        background: $green;
                        border: none;
                        border-radius: 5px;
                        color: $white;
                        cursor: pointer;
                        font-size: 100%;
                    }
                }
                .search4 {
                    input {
                        padding: 17px 10px;
                    }
                }
            }
            .list-conditions {
                @media screen and (max-width: $tb) {
                    overflow-y: scroll;
                }
                @media screen and (min-width: $pc) {
                    height: auto;
                }
                &:after {
                    @media screen and (min-width: $pc) {
                        background-color: transparent;
                    }
                }
            }
        }
    }
    .no-jobs {
        margin-bottom: 0;
    }
    .archive-job {
        padding-top: 0;
        .image-object-fit {
            padding: 0 !important;
            margin-bottom: 20px;
            overflow: hidden;
            img {
                height: 240px;
                object-fit: cover;
                width: 100%;
            }
        }
        .news-block {
            flex-wrap: wrap;
            justify-content: space-between;
            .news-inner {
                margin-bottom: 30px;
            }
        }
        .adjust {
            &::after {
                content: "";
                display: block;
                width: 30%;
            }
        }
    }

    .relation-job {
        .news-inner {
            .image-object-fit {
                padding: 0 !important;
                margin-bottom: 20px;
                overflow: hidden;
                img {
                    height: 200px;
                    object-fit: cover;
                    width: 100%;
                }
            }
        }
    }

    .archive-search-job {
        .main-search {
            .submit-btn {
                &:before {
                    content: "";
                    display: none;
                }
                button.submit {
                    padding: 20px 0;
                    text-align: center;
                    margin: 0;
                    width: 100%;
                    background: $green;
                    border: none;
                    border-radius: 5px;
                    color: $white;
                    cursor: pointer;
                    font-size: 100%;
                }
            }
        }
        .list-active {
            height: auto;
        }
        .image-object-fit {
            overflow: hidden;
            img {
                width: 500px;
                object-fit: cover;
                height: 100%;
                max-height: 200px;
            }
        }
        table.job-detail {
            td {
                &:last-of-type {
                    position: relative;
                    &:before {
                        content: "：";
                        position: absolute;
                        top: 0;
                        left: -15px;
                    }
                }
            }
        }
    }

    .main-archive {
        .main-wrapper {
            background-image: none;
            .main {
                padding-top: 60px;
            }
        }
    }

    .single-job {
        .gallery {
            .slick-list {
                .slick-track {
                    .slick-current {
                        li {
                            img {
                                width: 80%;
                                @media screen and (min-width: 768px) {
                                    width: 620px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .filter-box {
            .btn-orange {
                top: 53%;
                &.login {
                    top: 43%;
                    background-image: url(../images/common/icon-login.png);
                }
            }
        }
        .table {
            @media screen and (min-width: 760px) {
                &.work-time {
                    .tr {
                        display: grid;
                        grid-template-columns: 12em 0.5em 1fr;
                        grid-template-rows: 25px;
                    }
                    .td {
                        &:first-of-type {
                            grid-column: 1 / 2;
                            grid-row: 1;
                        }
                        &:last-of-type {
                            grid-column: 3 / 4;
                            grid-row: 1;
                        }
                    }
                    .title {
                        line-height: 100%;
                        padding-bottom: 5px;
                        &:not(:first-of-type) {
                            margin-top: 10px;
                        }
                        &:last-of-type {
                            padding-top: 5px;
                        }
                    }
                }
            }
        }
        .detail-info-2 {
            margin-bottom: 3.5em;
        }
        .btn-wrap {
            text-align: center;
            .btn-back {
                display: inline-block;
                background-color: $pink;
                color: $white;
                margin: 40px auto 40px;
                padding: 25px 15px;
                border-radius: 12px;
                font-size: 2rem;
                width: 300px;
            }
        }
    }

    .jobdetail.third {
        table {
            tr {
                td {
                    .fileup {
                        .file_label {
                            height: 35px;
                        }
                    }
                }
            }
        }
    }

    .regist-entrysheet.first {
        table {
            tr {
                td {
                    .inner {
                        p {
                            .checkbox-normal {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
}
