@charset "urf-8";
.commitment-search {
    .commitment-search-inner {
        @media screen and (max-width: $sp) {
            flex-wrap: wrap;
            justify-content: center;
        }
        .submit-btn {
            &:before {
                background-image: none;
                display: none;
            }
            @media screen and (max-width: $sp) {
                margin-top: 20px;
                width: auto;
            }
            .search {
                padding: 17px 0;
                text-align: center;
                background: $green;
                border: none;
                border-radius: 5px;
                color: $white;
                cursor: pointer;
                font-size: 100%;
                width: 100%;
                @media screen and (max-width: $sp) {
                    display: block;
                    width: 290px;
                }
            }
        }
        .search4 {
            @media screen and (max-width: $sp) {
                width: 100%;
            }
            input[type="text"] {
                width: 49%;
            }
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}
