@charset "utf-8";
.popup_overlay {
    display: flex;
    justify-content: center;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    transition: opacity 0.5s, transform 0s 0.5s;
    transform: scale(1);
    .popup_content {
        .template-input {
            padding: 10px;
            background-color: #ddd;
            margin-bottom: 10px;
            input {
                display: block;
                margin-bottom: 10px;
            }
            textarea {
                padding-top: 5px;
                padding-bottom: 5px;
                height: 100px;
            }
        }
        .template-input.original {
            margin-top: -20px;
        }
        .btn_popup_orig_template {
            position: relative;
            background: #fff;
            color: #222;
            display: block;
            padding: 20px 0 20px 0;
            border-radius: 5px;
            margin-bottom: 20px;
            text-align: center;
            cursor: pointer;
            p {
                margin: 0;
                padding: 0;
            }
            .title {
            }
            .icon {
                width: 15%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                top: 52.5%;
                right: -25px;
                span {
                    font-size: 1.7rem;
                    width: calc(100% / 3 - 10px);
                    margin-bottom: 0;
                    display: inline-block;
                }
            }
        }
    }
}

.pagenavi {
    display: grid;
    flex-wrap: nowrap;
    justify-content: normal;
    grid-template-columns: 1fr 50vw 1fr;
    .page-display {
        grid-column: 1 / 2;
        grid-row: 1;
        display: flex;
        align-items: center;
    }
    .page-number {
        grid-column: 2 / 3;
        grid-row: 1;
        display: flex;
        justify-content: center;
        span {
            padding: 0;
            a {
                display: block;
                padding: 5px 10px;
            }
        }
    }
    .page-next-link {
        grid-column: 3 / 4;
        grid-row: 1;
        display: flex;
        justify-content: end;
        a {
            display: flex;
            align-items: center;
            &:last-of-type {
                margin-left: 10px;
            }
        }
    }
}

body.admin {
    .h-btn {
        .applicant2-info {
            a {
                background-color: #8bc3d8;
            }
        }
        .select_gnav {
            a {
                background-color: #79a3b2;
            }
        }
        .logout-btn {
            a {
                line-height: 2.4em;
            }
        }
    }
    .wrap1 {
        .tk-search {
            .search_form {
                display: flex;

                input {
                    width: 80%;
                }
                .search_btn {
                    display: block;
                    background-color: $dark-gray;
                    padding: 5px 10px;
                    text-align: center;
                    cursor: pointer;
                    color: $white;
                }
            }
        }
        .tk-list {
            .tk-one {
                &.tk_bg_clinic {
                    background-color: $chat-title-bg-clinic;
                }
                &.tk_bg_hygienist {
                    background-color: $chat-title-bg-hygienist;
                }
                &.tk_bg_clinic.select,
                &.tk_bg_hygienist.select {
                    background-color: $chat-title-bg-select;
                }
            }
        }
    }
    //連絡チャット
    .wrap2 {
        .line__container {
            .line__contents.scroll {
                height: 65vh;
            }
            .post-message {
                .txt-holder {
                    textarea {
                        height: 80px;
                        line-height: 130%;
                    }
                }
                .btn-wrap {
                    width: 20%;
                    display: flex;
                    flex-wrap: wrap;
                    padding-right: 10px;
                    .btn-template,
                    .btn-submit {
                        width: calc(100% / 2);
                    }
                    .btn-introduce {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 55px;
                        font-size: 1.8em;
                        color: $light-gray;
                        cursor: pointer;
                    }
                    .txt-submit {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 55px;
                    }
                    &.is-clinic {
                        width: 25%;
                        .btn-introduce,
                        .btn-template,
                        .btn-submit {
                            width: calc(100% / 3);
                        }
                    }
                }
            }
        }
    }
    .wrap3 {
        .table3 {
            tbody {
                td {
                    .new-entry {
                        color: $pink;
                    }
                }
                .wrap-date {
                    .new {
                        color: $red;
                        display: block;
                        text-align: center;
                    }
                }
                .name-wrap {
                    .aplc-furi {
                        margin-bottom: 0;
                    }
                    .aplc-serial {
                        display: block;
                        color: $light-gray;
                        margin-bottom: 5px;
                    }
                    .aplc-name {
                        margin-bottom: 0;
                    }
                    .nickname {
                        color: $light-gray;
                        margin-left: 0.2em;
                    }
                }
            }
        }
        .table3.user {
            thead {
                th {
                    text-align: center;
                    &:nth-of-type(3),
                    &:nth-last-of-type(3) {
                        width: 11%;
                    }
                    &:nth-of-type(2),
                    &:nth-last-of-type(2) {
                        padding-left: 10px;
                        padding-right: 10px;
                        width: 9%;
                    }
                    &:nth-of-type(4) {
                        width: 12%;
                    }
                    &:first-of-type,
                    &:last-of-type {
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                    &:first-of-type {
                        width: 8%;
                    }
                    &:last-of-type {
                        width: 6%;
                    }
                }
            }
        }
        .table3.clinic {
            thead {
                th {
                    &:nth-of-type(3) {
                        width: 11%;
                    }
                    &:first-of-type,
                    &:nth-of-type(2),
                    &:nth-of-type(4),
                    &:last-of-type {
                        padding-left: 5px;
                        padding-right: 5px;
                        width: 10%;
                    }
                }
            }
        }
    }
    .wrap4 {
        .wrap-name {
            .aplc-d-top {
                line-height: 140%;
                small {
                    position: relative;
                    top: -3px;
                    &:before {
                        content: "";
                        display: block;
                        height: 1px;
                    }
                }
                .new-entry {
                    color: $pink;
                    a {
                        color: $pink;
                    }
                }
            }
        }
        .wrap-name.sp {
            .aplc-d-top {
                display: grid;
                grid-template-columns: 1fr 92vw 1fr;
                line-height: 140%;
                .aplc-d-top-l {
                    grid-column: 1 / 2;
                }
                .aplc-d-top-c {
                    grid-column: 2 / 3;
                    display: grid;
                    grid-template-columns: 25% 1fr;
                    .aplc-d-name {
                        grid-column: 1 / 2;
                        p {
                            padding-bottom: 0;
                            line-height: 140%;
                        }
                        p.name-kana {
                            font-size: 0.8rem;
                        }
                        p.caption {
                            position: relative;
                            margin-top: 5px;
                            margin-bottom: 22px;
                            padding-top: 0.4rem;
                            &:before {
                                content: "";
                                font-size: 0.8rem;
                                position: absolute;
                                top: -0.8rem;
                            }
                        }
                        p.caption.nickname {
                            font-size: 1.1rem;
                            &::before {
                                content: "ニックネーム";
                            }
                        }
                        p.caption.serial {
                            font-size: 1rem;
                            &::before {
                                content: "シリアルNo";
                            }
                        }
                    }
                    .aplc-d-date {
                        grid-column: 2 / 3;
                        p {
                            padding-bottom: 0;
                            line-height: 140%;
                        }
                        .grid {
                            display: grid;
                            grid-template-columns: 66px 1fr;
                            .grid-title {
                                grid-column: 1 / 2;
                            }
                            .grid-content {
                                grid-column: 2 / 3;
                            }
                        }
                    }
                }
                .aplc-d-top-c.clinic {
                    display: flex;
                }
                .aplc-d-top-c.user {
                    grid-template-columns: 16% 1fr;
                    @media screen and (max-width: 520px) {
                        grid-template-columns: 20% 1fr;
                    }
                    @media screen and (max-width: 400px) {
                        grid-template-columns: 25% 1fr;
                    }
                    .aplc-d-name {
                        .caption {
                            margin-bottom: 0;
                        }
                    }
                }
                .aplc-d-top-r {
                    grid-column: 3 / 4;
                }
            }
        }
    }
    .wrap4-r {
        .wrap-btnstatus {
            .wrap-btnstatus-inner {
                a {
                    font-size: 1.6rem;
                    padding: 0.7em 0.5em;
                }
            }
            .wrap-btnstatus-inner.check-resume {
                width: 100%;
            }
        }
    }
    .wrap4-l {
        @media print, screen and (max-width: $pc) {
            width: 96%;
            margin-left: auto;
            margin-right: auto;
        }
        .wrap-name.pc {
            .aplc-d-top {
                display: grid;
                grid-template-columns: 1fr 343px;
                .aplc-d-top-l {
                    grid-column: 1 / 2;
                    justify-content: left;
                    .aplc-d-name {
                        p {
                            padding-bottom: 0;
                            line-height: 140%;
                        }
                        p.name {
                            font-size: 1.8rem;
                        }
                        p.name-kana {
                            font-size: 0.8rem;
                        }
                        p.caption {
                            position: relative;
                            margin-top: 5px;
                            font-size: 1.3rem;
                            padding-top: 0.8rem;
                            &:before {
                                content: "";
                                font-size: 0.8rem;
                                position: absolute;
                                top: -0.8rem;
                            }
                            &:not(:last-of-type) {
                                margin-bottom: 20px;
                            }
                        }
                        p.caption.nickname {
                            &::before {
                                content: "ニックネーム";
                            }
                        }
                        p.caption.serial {
                            &::before {
                                content: "シリアルNo";
                            }
                        }
                    }
                    .aplc-d-date {
                        margin-left: 20px;
                        p {
                            padding-bottom: 0;
                            line-height: 140%;
                        }
                    }
                }
                .aplc-d-top-r {
                    grid-column: 2 / 3;
                    .btn-download.clinic {
                        br {
                            display: none;
                            @media screen and (max-width: 1390px) {
                                display: inline;
                            }
                        }
                        @media screen and (max-width: 1390px) {
                            line-height: 100%;
                            text-align: right;
                            a {
                                background: none;
                                padding: 0;
                                display: flex;
                                &:after {
                                    content: "";
                                    display: block;
                                    width: 2em;
                                    height: 2em;
                                    background: url("../images/common/icon-download.png")
                                        center right no-repeat;
                                }
                            }
                        }
                    }
                    .btn-download.user {
                        margin-bottom: 10px;
                        br {
                            display: none;
                            @media screen and (max-width: 1500px) {
                                display: inline;
                            }
                        }
                        @media screen and (max-width: 1500px) {
                            line-height: 100%;
                            text-align: right;
                            a {
                                background: none;
                                padding: 0;
                                display: flex;
                                justify-content: end;
                                &:after {
                                    content: "";
                                    display: block;
                                    width: 2em;
                                    height: 2em;
                                    background: url("../images/common/icon-download.png")
                                        center right no-repeat;
                                }
                            }
                        }
                    }
                }
                .aplc-d-top-r.download-wrap {
                    .btn-download {
                        text-align: right;
                    }
                }
            }
            .aplc-d-top.clinic {
                @media screen and (max-width: 1390px) {
                    grid-template-columns: 1fr 195px;
                }
            }
            .aplc-d-top.user {
                @media screen and (max-width: 1500px) {
                    grid-template-columns: 1fr 195px;
                }
                .aplc-d-date {
                    font-size: 1.4rem;
                    .grid {
                        display: grid;
                        grid-template-columns: 90px 1fr;
                        .grid-title {
                            grid-column: 1 / 2;
                        }
                        .grid-content {
                            grid-column: 2 / 3;
                        }
                    }
                }
            }
        }
    }
    table {
        tr {
            height: 100%;
            background-color: $white;
            border-bottom: 1px solid #cecece;
            th {
                &.entry_date {
                    cursor: pointer;
                }
            }
            td {
                height: 100%;
                padding: 10px 20px;
                border-bottom: none;
                .aplc-date {
                    white-space: pre-wrap;
                }
                .aplc-profile {
                }
                .grid2 {
                    display: grid;
                    grid-template-columns: 75px 1fr;
                    &.grid-time {
                        grid-template-columns: 130px 1fr;
                    }
                    .grid-title {
                        grid-column: 1/3;
                    }
                    .grid-row-title {
                        grid-column: 1/2;
                        padding-left: 1em;
                        line-height: 150%;
                    }
                    .option-row-subcontent {
                        grid-column: 2/3;
                    }
                    .gird-row-content {
                        grid-column: 2/3;
                        line-height: 150%;
                    }
                    p {
                        padding-bottom: 0;
                    }
                }
                .badge {
                    font-size: 1.2rem;
                    display: inline-block;
                    padding: 3px 10px;
                    border-radius: 20px;
                    text-align: center;
                    border-width: 1px;
                    border-style: solid;
                    line-height: 100%;
                }
                .badge.primary {
                    border-color: $pink;
                    color: $pink;
                }
                .badge.secondary {
                    border-color: $light-gray;
                    color: $light-gray;
                }
                .text-resume {
                    padding-bottom: 0;
                    line-height: 140%;
                }
            }
            td.chat {
                border-right: 1px solid #cecece;
                .btn-interrupt {
                    font-size: 1.6rem;
                    padding-left: 0.1em;
                    padding-right: 0.1em;
                }
            }
            td.offer {
                height: 100%;
                border-right: 1px solid #cecece;
                line-height: 100%;
                .btn-offer {
                    width: 100%;
                }
                .offer-header {
                    margin-bottom: 10px;
                }
                .offer-body {
                    font-size: 1.2rem;
                    p {
                        margin-bottom: 0;
                        padding-bottom: 0;
                    }
                    ul {
                        li {
                            list-style-type: none;
                        }
                    }
                }
                .edit-body {
                    font-size: 1.2rem;
                    text-align: right;
                }
            }
            td.memo {
                padding: 5px;
                height: 100%;
                min-height: 140px;
                border-left: none;
                display: flex;
                flex-wrap: wrap;
                align-items: stretch;
                .wrap-memo {
                    height: 80%;
                    textarea {
                        padding: 10px;
                        font-size: 1.4rem;
                        line-height: 140%;
                        min-height: 90px;
                        height: 100%;
                        @extend %maru-gothic;
                    }
                    p {
                        padding: 10px;
                        font-size: 1.4rem;
                        line-height: 140%;
                        white-space: pre-wrap;
                        max-height: 90px;
                        overflow-y: scroll;
                    }
                }
                .link-memo {
                    padding: 5px 10px 10px 10px;
                }
            }
            td.entry-clinic {
                font-size: 1.4rem;
            }
        }
        tr.select {
            background-color: red;
        }
    }
    //ダイレクトチャット
    .talkwrapper {
        .wrap2 {
            .line__container {
                .line__left {
                    max-width: 35%;
                    @media print, screen and (max-width: $pc) {
                        max-width: 50%;
                    }
                    .line__left-text {
                        .text.partner.clinic {
                            background-color: $clinic-light-green;
                            &::after {
                                border-right: 20px solid $clinic-light-green;
                            }
                        }
                    }
                }
                .line__center__days {
                    clear: both;
                    position: relative;
                    top: 20px;
                }
                .line__right {
                    clear: both;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    max-width: 75%;
                    min-height: 60px;
                    position: relative;
                    .edit {
                        position: absolute;
                        right: 6px;
                        top: 5px;
                        font-size: 80%;
                        cursor: pointer;
                        color: $white;
                    }
                    .self {
                        white-space: pre-wrap;
                    }
                }
            }
        }
    }

    //割り込みチャット
    .talkwrapper.interrupt {
        .line__right {
            .name {
                text-align: right;
                padding-right: 10px;
                font-size: 80%;
                color: $light-gray;
            }
            .text.self {
                &::after {
                    top: 30px;
                }
            }
        }
        .line__left {
            position: relative;
            .edit {
                position: absolute;
                right: 6px;
                top: 25px;
                font-size: 80%;
                cursor: pointer;
                color: $white;
            }
        }
    }

    //履歴書
    .resume {
        .btn_wrap {
            a {
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }
    &.job-entry-detail {
        .wrap-name.pc {
            @media screen and (min-width: 768px) and (max-width: 1024px) {
                display: block;
            }
            .download-wrap {
                display: flex;
                align-items: baseline;
            }
        }
        .wrap-name.sp {
            .aplc-d-top-c.user {
                width: 100%;
                grid-template-columns: 20% 1fr !important;
                .btn-resume {
                    margin-top: 5px;
                    font-size: 1rem;
                }
            }
        }
    }
}

body.admin-clinic-detail {
}
