/*/////////////////////////////////////////////////
//既存css変更 211214
/////////////////////////////////////////////////*/
.clinic-info .clinic-info-inner table tr {
    display: grid;
    grid-template-columns: 30% 70% !important;
}
.clinic-info-inner table td:first-child {
    width: 100%;
    vertical-align: baseline;
}
.clinic-info-inner table td:nth-child(2) {
    width: 100%;
    vertical-align: baseline;
}
.article-like + .clinic-name {
    padding-right: 75px;
}

/*/////////////////////////////////////////////////
//既存css変更 220121
/////////////////////////////////////////////////*/
.table1 th {
    background-color: #ff8a91;
    border-left: solid 1px #ff8a91;
}
h1.title2 {
    border-bottom: solid 1px #f1a6a6;
}

/*/////////////////////////////////////////////////
//新規css追加 220121
/////////////////////////////////////////////////*/
.green-head .table1 th,
.table1.table-green th {
    background-color: #7db182;
    border-left: solid 1px #7db182;
}
.pink_btn input[type="submit"],
.pink_btn input[type="button"] {
    background: #ff8a91;
}
.green-head h1.title2 {
    border-bottom: solid 1px #7db182;
}
.pink-box {
    border: solid 1px #ff8a91;
    padding: 30px 30px 0 30px;
}
h1.title2.title-green {
    border-bottom: solid 1px #7db182;
}
/* 09歯科衛生士新規会員登録 -----------------------------------------------------------------------------------------*/
.hygienist h1 {
    background-color: #ff8a91;
    background-image: url("../images/common/icon-pen.png");
    background-repeat: no-repeat;
    background-size: 36px;
    background-position: calc(50% - 90px) 50%;
    padding: 15px 0 20px;
    color: #ffffff;
    margin: 0;
}
.registration.hygienist {
    border-top: none;
    border-right: solid 13px #ff8a91;
    border-bottom: solid 13px #ff8a91;
    border-left: solid 13px #ff8a91;
    padding: 0;
    border-radius: 6px;
}
.registration-inner {
    padding: 10%;
}
.registration.hygienist input[type="submit"],
.registration.hygienist input[type="button"] {
    background: #ff8a91;
}
.registration.hygienist .title5,
.registration.hygienist .alert {
    text-align: center;
}
/* 13歯科衛生士ログイン -----------------------------------------------------------------------------------------*/
.registration.mypagelogin input[type="submit"],
.registration.mypagelogin input[type="button"] {
    background: #ff7540;
}

/* 16・26・37連絡用チャット -----------------------------------------------------------------------------------------*/
/*---- 歯科衛生士吹き出し（ピンク） ----*/
.orange-head .text.self {
    background-color: #fdeeef !important;
}
.orange-head .text.self::after {
    border-left: 20px solid #fdeeef !important;
}
/*---- 歯科医院吹き出し（グリーン） ----*/
.orange-head .text.partner,
.green-head .text.self {
    background-color: #d1e8d3 !important;
}
.orange-head .text.partner::after {
    border-right: 20px solid #d1e8d3 !important;
}
.green-head .text.self::after {
    border-left: 20px solid #d1e8d3 !important;
}

/*/////////////////////////////////////////////////
//新規css追加 220124
/////////////////////////////////////////////////*/
/* 03・28よくある質問 -----------------------------------------------------------------------------------------*/
.faq-a li {
    margin-left: 23px;
}

/*/////////////////////////////////////////////////
//既存css変更 220125
/////////////////////////////////////////////////*/
/* フッター -----------------------------------------------------------------------------------------*/
.footer-menu {
    height: 220px;
}
.footer-menu li:nth-child(5n) a {
    margin-bottom: 0;
}

/*/////////////////////////////////////////////////
//既存css変更 220201
/////////////////////////////////////////////////*/
p {
    line-height: 180%;
}
/* スマホチャット画面 -----------------------------------------------------------------------------------------*/
.blue-head .txt-input {
    display: flex;
    flex-wrap: nowrap;
    width: 96%;
    padding: 2%;
}

.line__container .scroll {
    /*height: 500px;*/
    height: auto;
    max-height: 70vh;
    overflow-y: scroll;
    padding-bottom: 100px;
}
/* top -----------------------------------------------------------------------------------------*/

.submit-btn::before {
    width: 40px;
}
.search3 a {
    padding: 12.5px 10px;
}

input[type="submit"],
input[type="button"] {
    background: #ff8a91;
}
.main-btn-position {
    position: relative;
}

.balloon-position {
    position: absolute;
    top: -72%;
    left: 53%;
}
.balloon {
    position: relative;
    padding: 8px;
    border-radius: 50px;
    color: #ffab6d;
    border: solid 2px #ffab6d;
    background-color: #fff;
    width: 150px;
    font-size: 15px;
    text-align: center;
}
.balloon .icon {
    position: absolute;
    left: -110px;
    top: 0;
}
.balloon::after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    left: 37px;
    top: 46px;
    border-top: 15px solid #ffffff;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
}
.balloon::before {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    left: 37px;
    top: 50px;
    border-top: 15px solid #ffab6d;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
}
.register-new {
    display: none;
}
.site-text {
    margin: 5% 0;
}
h3.feature {
    margin-bottom: 6%;
}
.features-inner {
    padding: 50px 60px 30px;
}

@media screen and (max-width: 767px) {
    .btn-pink {
        background-color: #ffab6d;
        background-position: calc(30% - 44px) center;
    }
    .btn-green {
        background-color: #7db182;
    }
    .balloon-osition {
        position: absolute;
        top: -72%;
        left: 23%;
    }
    .register-new {
        display: block !important;
    }
}
/* トップページユーザーガイド ----------------------------------------------------------------------------------------- */
#userguide .inner {
    padding: 45px 30px;
}
h3.title3 {
    margin-bottom: 25px;
}
.userguide-img {
    margin-bottom: 25px;
}
/* メニュー -----------------------------------------------------------------------------------------*/
#dropmenu .register-new li:nth-child(1) a {
    background-image: url("../images/common/icon-signup2.png");
    background-position: left 22px;
    background-repeat: no-repeat;
}
#dropmenu .register-new li:nth-child(2) a {
    background-image: url("../images/common/icon-job2.png");
    background-position: left 22px;
    background-repeat: no-repeat;
}
/* 歯科衛生士管理画面 -------------------------------------------------------------- */
.orange-head .h-btn div a {
    background-color: #ff8a91;
}
/* 運営者管理画面 -------------------------------------------------------------- */
.blue-head .h-btn div a {
    background-color: #283ec3;
}
.h-btn div.applicant2-info a {
    background-color: #12238a;
}
.line__right.bk-line-blue .text.self {
    background-color: #d7e2ff;
}
.line__container .line__right.bk-line-blue .text.self::after {
    border-left: 20px solid #d7e2ff;
}
.blue-head .tk-one .tk-one-r .notice {
    background-color: #364cd1;
}
.blue-head.mean-container a.meanmenu-reveal {
    background: #283ec3;
}
.blue-head.mean-container .mean-nav {
    background-color: #283ec3;
}
a.btn-blue,
input[type="button"].btn-blue {
    background: #283ec3;
}
a.btn-darkblue,
input[type="button"].btn-darkblue {
    background: #12238a;
}
a.btn-white,
input[type="button"].btn-white {
    color: #283ec3;
    border: 1px solid #283ec3;
}
input[type="button"].aplc-delete {
    background-color: #545c8a;
}

/*パスワード表示切替*/

.pw-form {
    margin: auto;
}
.pw-form-header,
.pw-form p {
    margin-top: 0;
    margin-bottom: 0;
}

.pw-form input {
    box-sizing: border-box;
    display: block;
    width: 100%;
    appearance: none;
    background-color: #ffffff;
    font-size: 100%;
    border: solid 1px #d6d6d6;
    padding: 20px 10px;
    width: 100%;
    border-radius: 5px;
}
.pw-form input[type="email"]:focus,
.pw-form input[type="text"]:focus,
.pw-form input[type="password"]:focus {
    border-color: #888;
}

.pw-form input[type="submit"] {
    background: #28d;
    border-color: transparent;
    color: #fff;
    cursor: pointer;
}

.pw-form input[type="submit"]:hover {
    background: #17c;
}

.pw-form input[type="submit"]:focus {
    border-color: #05a;
}

.field-icon {
    color: #555;
    float: right;
    margin-right: 20px;
    margin-top: -46px;
    position: relative;
    z-index: 2;
}

/*/////////////////////////////////////////////////
//既存css変更 220205
/////////////////////////////////////////////////*/
body.top .main-wrapper .main .main-search .submit-btn button.submit,
body.top .archive-search-job .main-search .submit-btn button.submit,
.commitment-search .commitment-search-inner .submit-btn .search {
    background: #ff8a91;
}

/*/////////////////////////////////////////////////
//新規css追加 220205
/////////////////////////////////////////////////*/
/* メイン -------------------------------------------------------------- */

.btn-orange-1 {
    font-size: 2.2rem;
    background-color: #ffab6d;
    background-image: url("../images/common/icon-signup2.png");
    background-position: calc(50% - 48px) center;
    background-repeat: no-repeat;
    background-size: 23px;
    border-radius: 6px;
    padding: 40px 0px 40px 0px;
    color: #ffffff;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    position: relative;
    display: block;
    width: 100%;
    box-sizing: border-box;
}
.btn-orange-2 {
    font-size: 2.2rem;
    background-color: #ff7540;
    background-image: url("../images/common/icon-login.png");
    background-position: calc(50% - 48px) center;
    background-repeat: no-repeat;
    background-size: 23px;
    border-radius: 6px;
    padding: 40px 0px 40px 0px;
    color: #ffffff;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    position: relative;
    display: block;
    width: 100%;
    box-sizing: border-box;
}
.btn-orange-1 span,
.btn-orange-2 span {
    position: absolute;
    top: 50%;
    left: 56%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    width: 80%;
    text-align: center;
}

/* 利用規約 -------------------------------------------------------------- */
.service-list h2 {
    margin: 25px 0;
}

@media screen and (max-width: 767px) {
    /* メイン -------------------------------------------------------------- */
    .btn-orange-1 {
        font-size: 1.6rem;
        background-color: #ffab6d;
        background-position: calc(50% - 44px) center;
        background-size: 23px;
        padding: 10px 0px 10px 20px;
        width: 48%;
        height: 80px;
        box-sizing: border-box;
    }
    .btn-orange-2 {
        font-size: 1.6rem;
        background-color: #ff7540;
        background-position: calc(50% - 44px) center;
        background-size: 23px;
        padding: 10px 0px 10px 20px;
        width: 48%;
        height: 80px;
        box-sizing: border-box;
    }
    .balloon-position {
        left: 23%;
        position: absolute;
        top: -72%;
    }
}

/*/////////////////////////////////////////////////
//新規css追加 220207
/////////////////////////////////////////////////*/
/* 退会ボタン -------------------------------------------------------------- */
.withdrawal {
    margin-bottom: 20px;
}
.withdrawal a {
    font-size: 1.4rem;
    background-color: #d6d6d6;
    padding: 10px 10px;
    border-radius: 6px;
    left: 0;
    top: 16px;
}
.registration h1.bottom20 {
    margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
    /* 退会ボタン -------------------------------------------------------------- */
    .withdrawal {
        margin-top: 60px;
    }
}

/*/////////////////////////////////////////////////
//既存css変更 220208
/////////////////////////////////////////////////*/
/* トップページ絞り込み検索 -------------------------------------------------------------- */
.list-conditions {
    top: 275px;
}
/* トップページ新着情報 -------------------------------------------------------------- */
.news-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

/*/////////////////////////////////////////////////
//新規css追加 220208
/////////////////////////////////////////////////*/
/* トップページ新着情報 -------------------------------------------------------------- */
.news-block:after {
    content: "";
    display: block;
    width: 30%;
}

/*/////////////////////////////////////////////////
//既存css変更 220316
/////////////////////////////////////////////////*/
body {
    font-family: "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "メイリオ",
        Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", "Osaka", sans-serif;
}
/* メールフォーム -------------------------------------------------------------- */
/*//// 下記既に井村さんの方でapp.cssに追加して頂いている為、実際には追加は不要ですが、念のために記載しております
   table input[type="password"] {
	   padding: 15px 10px;
   }
   input[type="password"] {
	   appearance: none;
	   background-color: #FFFFFF;
	   border: solid 1px #D6D6D6;
	   box-sizing: border-box;
	   font-size: 100%;
   }
	///*/

/* チェックボックス ----------------------------------------------------------------------------------------- */
input[type="checkbox"] + label {
    line-height: 1.5;
}
.checkbox-label,
.checkbox-label2 {
    padding: 15px 40px 16px 15px;
}
.checkbox-normal {
    display: block;
}
.checkbox-normal input[type="checkbox"] {
    display: inline-block;
}
.checkbox-normal input[type="checkbox"] + label {
    border: none;
    cursor: pointer;
}
.checkbox-normal input[type="checkbox"]:checked + label {
    border: none;
    color: #ff848b;
}
/* パスワード表示切替 ----------------------------------------------------------------------------------------- */
.password-block .field-icon {
    margin-right: 32%;
}

/* トークリスト ----------------------------------------------------------------------------------------- */
.tk-one .tk-one-r .minute {
    color: #b9b9b9;
    padding-bottom: 0;
    white-space: nowrap;
    font-size: 1.2rem;
}
.tk-one .tk-one-r {
    text-align: center;
    position: relative;
    width: 80px;
}
.tk-one .tk-one-l {
    width: calc(100% - 80px);
}

/*/////////////////////////////////////////////////
   //新規css追加  220316
   /////////////////////////////////////////////////*/
/* 基本 -------------------------------------------------------------- */
button,
input,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
}
.table1 select,
.search1 select,
.search2 select {
    color: #222222;
}

/* トークリスト ----------------------------------------------------------------------------------------- */
.image-icon-round.talk-icon {
    width: 80px;
}
.tk-one .tk-one-l .per-name {
    width: calc(100% - 80px);
    box-sizing: border-box;
}

/*/////////////////////////////////////////////////
   //医院掲載登録
   /////////////////////////////////////////////////*/
/* メールフォーム -------------------------------------------------------------- */
.green-head input[type="submit"],
.green-head input[type="button"] {
    background: #7db182;
}
.green-head input[type="button"].btn_gray,
.confirm input[type="button"] {
    background: #959595;
}

/* PC ----------------------------------------------------------------------------------------- */
@media print, screen and (min-width: 1025px) {
    /* column特定箇所 -------------------------------------------------------------- */
    .column3.flex-justify-content1.block-syokusyu .inner:nth-child(5) {
        width: calc(64% + 11px);
        margin-right: 0;
    }
}

/* タブレット・スマホ ----------------------------------------------------------------------------------------- */
@media print, screen and (max-width: 1024px) {
    /* パスワード表示切替 -------------------------------------------------------------- */
    .password-block .field-icon {
        margin-right: 32vw;
    }
    /* メールフォーム -------------------------------------------------------------- */
    input.field-w100-sp,
    select.field-w100-sp {
        width: 100%;
    }
}

/* タブレット ----------------------------------------------------------------------------------------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    /* column特定箇所 -------------------------------------------------------------- */
    .column3.flex-justify-content1.block-syokusyu .inner {
        width: calc(50% - 8px);
    }
    .column3.flex-justify-content1.block-syokusyu .inner:nth-child(even) {
        margin-right: 0;
    }
    .column3.flex-justify-content1.block-syokusyu .inner:nth-child(5) {
        width: 100%;
        margin-right: 0;
    }
}

/* スマホ -------------------------------------------------------------- */
@media screen and (max-width: 767px) {
    /* column特定箇所 -------------------------------------------------------------- */
    .column3.flex-justify-content1.block-syokusyu .inner {
        width: 100%;
        margin-right: 0;
    }
    .copy2 {
        text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
            1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff,
            1px 0 0 #fff;
    }
    /* トークリスト -----------------------------------------------------------------------------------------*/
    .image-icon-round.talk-icon {
        width: 60px;
    }
    .tk-one .tk-one-l .per-name {
        width: calc(100% - 60px);
    }
    .per-name .tk-li-name {
        font-size: 0.9em;
    }
    .image-icon-round.talk-icon img {
        height: 60px;
        width: 60px;
    }
}

/* スマホ(小) -------------------------------------------------------------- */
@media screen and (max-width: 360px) {
    /* パスワード表示切替 -------------------------------------------------------------- */
    .password-block .field-icon {
        margin-top: -40px;
    }
}

/*/////////////////////////////////////////////////
//既存css削除
/////////////////////////////////////////////////*/
#dropmenu li:first-child a {
    background-image: url("../images/common/icon-features.png");
    background-position: left 22px;
    background-repeat: no-repeat;
}
/*/////////////////////////////////////////////////
   //既存css変更
   /////////////////////////////////////////////////*/
/* ナビゲーション -------------------------------------------------------------- */
#dropmenu li:nth-child(2) a {
    background-image: url("../images/common/icon-features.png");
    background-position: left 22px;
    background-repeat: no-repeat;
}
#dropmenu li:nth-child(3) a {
    background-image: url("../images/common/icon-interview.png");
    background-position: left 22px;
    background-repeat: no-repeat;
}
#dropmenu li:nth-child(4) a {
    background-image: url("../images/common/icon-qa.png");
    background-position: left 22px;
    background-repeat: no-repeat;
}
#dropmenu li:nth-child(5) a {
    background-image: url("../images/common/icon-experiences.png");
    background-position: left 22px;
    background-repeat: no-repeat;
}
#dropmenu li:nth-child(6) a {
    background-image: url("../images/common/icon-ad.png");
    background-position: left 22px;
    background-repeat: no-repeat;
}
#dropmenu li:nth-child(7) a {
    background-image: url("../images/common/icon-contact.png");
    background-position: left 22px;
    background-repeat: no-repeat;
}
/* トップページ絞り込み検索 -------------------------------------------------------------- */
.list-conditions {
    display: none;
    position: absolute;
    top: 740px;
    left: 0;
    background-color: #ffffff;
    border-radius: 6px;
    border: solid 1px #000000;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 230px;
    position: absolute;
}
.list-conditions.list-active {
    display: flex;
    flex-wrap: wrap;
    width: 96%;
    z-index: 1000000;
    margin: 0 2%;
}

/* スマホ -------------------------------------------------------------- */
@media screen and (max-width: 767px) {
    .main-search input[type="text"] {
        padding: 22px 10px 23px;
    }
}

/*/////////////////////////////////////////////////
   // override11
   //新規css追加
   /////////////////////////////////////////////////*/
/* ヘッダーリンクボタン -------------------------------------------------------------- */
.h-btn .job a {
    padding: 45px 6px 8px 6px;
}
.h-btn div.job a {
    line-height: 1.2;
    font-size: 1.4rem;
    text-align: center;
}

/* トップ絞り込み検索 -------------------------------------------------------------- */
.list-conditions::after {
    display: block;
    content: " ";
    width: 6px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 2px;
    background-color: #cccccc;
    border-radius: 6px;
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
}

@media screen and (min-width: 1045px) and (max-width: 1162px) {
    /* トップ絞り込み検索 -------------------------------------------------------------- */
    .list-conditions {
        top: 780px;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1044px) {
    /* トップ絞り込み検索 -------------------------------------------------------------- */
    .list-conditions {
        top: 810px;
    }
}

/* タブレット・スマホ ----------------------------------------------------------------------------------------- */
@media print, screen and (max-width: 1024px) {
    /* ナビゲーション -------------------------------------------------------------- */
    .register-new {
        display: block !important;
    }
    /* トップ絞り込み検索 -------------------------------------------------------------- */
    .list-conditions {
        top: 810px;
    }
}

@media screen and (max-width: 789px) {
    /* トップ絞り込み検索 -------------------------------------------------------------- */
    .list-conditions {
        top: 838px;
    }
}

/* スマホ -------------------------------------------------------------- */
@media screen and (max-width: 767px) {
    /* トップ絞り込み検索 -------------------------------------------------------------- */
    .list-conditions.list-active {
        height: 170px;
        overflow-y: scroll;
        top: 790px;
        line-height: 1.2;
    }
    .list-conditions.list-active div {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 789px) {
    /* トップ絞り込み検索 -------------------------------------------------------------- */
    .list-conditions {
        top: 878px;
    }
}

@media screen and (max-width: 713px) {
    /* トップ絞り込み検索 -------------------------------------------------------------- */
    .list-conditions.list-active {
        top: 820px;
    }
}

@media screen and (max-width: 640px) {
    /* トップ絞り込み検索 -------------------------------------------------------------- */
    .list-conditions.list-active {
        top: 855px;
    }
}

@media screen and (max-width: 481px) {
    /* トップ絞り込み検索 -------------------------------------------------------------- */
    .list-conditions.list-active {
        top: 890px;
    }
}

@media screen and (max-width: 391px) {
    /* トップ絞り込み検索 -------------------------------------------------------------- */
    .list-conditions.list-active {
        top: 920px;
    }
}

@media screen and (max-width: 359px) {
    /* トップ絞り込み検索 -------------------------------------------------------------- */
    .list-conditions.list-active {
        top: 960px;
    }
}

@media screen and (max-width: 320px) {
    .main-search a,
    .main-search select {
        font-size: 1.2rem;
    }
    /* トップ絞り込み検索 -------------------------------------------------------------- */
    .list-conditions.list-active {
        top: 980px;
    }
}

/*/////////////////////////////////////////////////
//override12 新規css追加
/////////////////////////////////////////////////*/
/* 連絡用チャット -------------------------------------------------------------- */
.line__container {
    overflow-wrap: break-word;
    word-wrap: break-word;
}

/*/////////////////////////////////////////////////
// slick-override1 既存css変更
/////////////////////////////////////////////////*/
.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
}

/*/////////////////////////////////////////////////
//override13 既存css変更
/////////////////////////////////////////////////*/
/* トップページ絞り込み検索 -------------------------------------------------------------- */
.search3 a {
    height: 50px;
}
.main-search input[type="text"] {
    height: 50px;
}
.main-search select {
    line-height: 200%;
    padding: 13px 10px;
    height: 50px;
}

/* 登録フォーム -------------------------------------------------------------- */
table .password-block input[type="password"],
.pw-form-container input {
    padding-right: 50px;
    box-sizing: border-box;
}
input[type="submit"],
input[type="button"] {
    -webkit-appearance: none;
    border-radius: 5px;
}
.pink_btn input[type="submit"].btn_gray,
.pink_btn input[type="button"].btn_gray {
    background: #959595;
}

/* 新着情報 ----------------------------------------------------------------------------------------- */
.news-inner {
    display: flex !important;
    flex-direction: column !important;
    align-items: stretch !important;
    @media screen and (min-width: 1024px) {
        min-height: 1000px;
    }
    .news-text {
        height: 50px;
    }
}
.news-inner a {
    margin-top: auto !important;
}

/* タブレット・スマホ ----------------------------------------------------------------------------------------- */
/* 新着情報 ----------------------------------------------------------------------------------------- */
@media print, screen and (max-width: 1024px) {
    .slick-track {
        display: flex !important;
    }
    .slick-slide {
        height: auto !important;
        border: solid 1px #dcd7d7;
    }
    .news-inner {
        border: none;
    }
    .slick-slide > div {
        display: flex;
        align-items: stretch !important;
        height: 100%;
    }
}
/*/////////////////////////////////////////////////
//override14 既存css変更
/////////////////////////////////////////////////*/
/* トップページ絞り込み検索 -------------------------------------------------------------- */
.search3 a {
    height: 67px;
}
.main-search input[type="text"] {
    height: 67px;
}
.main-search select {
    height: 67px;
}

/*パスワード表示切替*/
.pw-form input {
    padding: 10px 10px;
    min-height: 67px; /* 新規追加 */
}

/*/////////////////////////////////////////////////
//新規css追加
/////////////////////////////////////////////////*/
/* エントリーシート登録23 ----------------------------------------------------------------------------------------- */
.ampm span {
    width: 40%;
}
.ampm span:first-child {
    margin-right: 2%;
}
.ampm span input.field-w10 {
    width: 32%;
}

/* タブレット・スマホ ----------------------------------------------------------------------------------------- */
@media print, screen and (max-width: 1024px) {
    /* エントリーシート登録23 ----------------------------------------------------------------------------------------- */
    .ampm span {
        width: 100%;
    }
    .ampm span:first-child {
        margin-right: 0;
        padding-bottom: 15px;
    }
}

/*/////////////////////////////////////////////////
//override15 既存css変更
/////////////////////////////////////////////////*/
/* エントリーシート登録23 ----------------------------------------------------------------------------------------- */
.ampm {
    margin-bottom: 15px;
}
/*/////////////////////////////////////////////////
////override16 新規css追加
/////////////////////////////////////////////////*/
/* エントリーシート登録23 ----------------------------------------------------------------------------------------- */
.ampm {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.ampm span input.field-w10 {
    margin-left: 5px;
}

/*/////////////////////////////////////////////////
//override17
/////////////////////////////////////////////////*/
/* 絞り込み条件 -------------------------------------------------------------- */
.list-conditions {
    overflow-y: scroll;
}
/* 絞り込み条件(トップページ) -------------------------------------------------------------- */
@media screen and (min-width: 1007px) and (max-width: 1024px) {
    #top .list-conditions {
        top: 865px !important;
    }
}
@media screen and (min-width: 913px) and (max-width: 1006px) {
    #top .list-conditions {
        top: 900px !important;
    }
}
@media screen and (min-width: 790px) and (max-width: 912px) {
    #top .list-conditions {
        top: 865px !important;
    }
}
@media screen and (min-width: 768px) and (max-width: 789px) {
    #top .list-conditions {
        top: 900px !important;
    }
}
@media screen and (min-width: 482px) and (max-width: 767px) {
    #top .list-conditions {
        top: 1035px !important;
    }
}
@media screen and (min-width: 392px) and (max-width: 481px) {
    #top .list-conditions {
        top: 1070px !important;
    }
}
@media screen and (min-width: 360px) and (max-width: 391px) {
    #top .list-conditions {
        top: 1100px !important;
    }
}
@media screen and (min-width: 321px) and (max-width: 359px) {
    #top .list-conditions {
        top: 1140px !important;
    }
}
@media print, screen and (max-width: 320px) {
    #top .list-conditions {
        top: 1170px !important;
    }
}
/* 絞り込み条件(下層ページ) -------------------------------------------------------------- */
@media screen and (min-width: 503px) and (max-width: 767px) {
    #second .list-conditions {
        top: 560px !important;
    }
}
@media screen and (min-width: 341px) and (max-width: 502px) {
    #second .list-conditions {
        top: 590px !important;
    }
}
@media print, screen and (max-width: 340px) {
    #second .list-conditions {
        top: 620px !important;
    }
}
/*/////////////////////////////////////////////////
//override18
/////////////////////////////////////////////////*/
/* 絞り込み条件 -------------------------------------------------------------- */
.list-conditions {
    height: initial !important;
}
.list-conditions::after {
    content: none !important;
}
/*/////////////////////////////////////////////////
//override20
/////////////////////////////////////////////////*/
/* 運営者管理ページ　連絡用チャット -------------------------------------------------------------- */
.line__attention {
    bottom: 8.55em;
}
.blue-head .txt-input {
    padding: 1%;
    width: 98%;
}

/* タブレット・スマホ ----------------------------------------------------------------------------------------- */
@media print, screen and (max-width: 1024px) {
    .line__container .scroll {
        padding-bottom: 200px;
    }
}

/* スマホ -------------------------------------------------------------- */
@media screen and (max-width: 767px) {
    .line__container .scroll {
        padding-bottom: 150px;
    }
}
/*/////////////////////////////////////////////////
//override21
/////////////////////////////////////////////////*/
/* 絞り込み条件 -------------------------------------------------------------- */
#second .search3 a.on {
    height: initial;
}
.main-search select,
.search3 a {
    font-size: 100%;
}
.main-search div.search3 {
    position: relative;
}
.main-search div.search3 a.on {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    padding: 2px 10px;
    line-height: 1.6;
}

/* タブレット -------------------------------------------------------------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    #second .search3 a.on {
        padding: 4px 10px;
        line-height: 2;
    }
}
/* スマホ -------------------------------------------------------------- */
@media screen and (max-width: 767px) {
    #second .search3 a.on {
        padding: 1px 10px;
        line-height: 2;
    }
    .main-search select,
    .search3 a {
        background-position: center bottom 5px;
    }
}
/* 絞り込み条件(トップページ) -------------------------------------------------------------- */
@media screen and (min-width: 482px) and (max-width: 767px) {
    #top .list-conditions {
        top: 1035px !important;
    }
}
/*/////////////////////////////////////////////////
//override22
/////////////////////////////////////////////////*/
/* 絞り込み条件 -------------------------------------------------------------- */
.main-search select,
.search3 a {
    transition: initial;
}
@media print, screen and (min-width: 1025px) and (max-width: 1299px) {
    .main-search select,
    .search3 a {
        font-size: 100% !important;
    }
}

/* 運営者管理ページ　連絡用チャット -------------------------------------------------------------- */
.line__attention {
    bottom: 8.55em !important;
}
/* スマホ -------------------------------------------------------------- */
@media screen and (max-width: 767px) {
    .line__attention {
        bottom: 7.55em !important;
    }
}

/*/////////////////////////////////////////////////
//override24
/////////////////////////////////////////////////*/
/* トップページ -------------------------------------------------------------- */
.copy1.font-m {
    font-size: 4.8rem;
    text-shadow: 2px 2px 0 #fff, -2px -2px 0 #fff, 5px 5px 0 #ffdbdb,
        -5px -5px 0 #ffdbdb, -2px 2px 0 #fff, 2px -2px 0 #fff,
        -5px 5px 0 #ffdbdb, 5px -5px 0 #ffdbdb, 0px 2px 0 #fff, 0-2px 0 #fff,
        0px 5px 0 #ffdbdb, 0-5px 0 #ffdbdb, -2px 0 0 #fff, 2px 0 0 #fff,
        -5px 0 0 #ffdbdb, 5px 0 0 #ffdbdb;
}
.main {
    padding: 50px 20px 50px 0;
}
.main-btn {
    margin: 0 0 55px auto;
}
.stepbarwrap {
    margin: 0 auto;
    display: block;
    text-align: center;
    margin-bottom: 30px;
}
.stepbarwrap .steptitle {
    display: inline-flex;
    align-items: center;
    margin: 0 auto;
}
.stepbarwrap .steptitle .stepcircle {
    display: inline-block;
    width: 3em;
    height: 3em;
    content: "";
    border-radius: 50%;
    background-color: #ff848b;
    color: #fff;
    text-align: center;
}
.stepbarwrap .steptitle .stepcircle span {
    display: inline-block;
    line-height: 1.2em;
    font-size: 0.8em;
    font-weight: bold;
    position: relative;
    top: 0.9em;
}
.stepbarwrap .steptitle .title {
    margin: 0.5em;
    font-size: 2.4rem;
    color: #ff848b;
}

/* タブレット・スマホ ----------------------------------------------------------------------------------------- */
@media print, screen and (max-width: 1024px) {
    #userguide .column {
        flex-direction: column;
    }
    #userguide .inner {
        width: 90%;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
    }
}

/* スマホ -------------------------------------------------------------- */
@media screen and (max-width: 767px) {
    body.top .main-wrapper .main {
        padding-top: 50px;
    }
    .copy1.font-m {
        font-size: 7.4vw;
    }
}

/*/////////////////////////////////////////////////
//override23
/////////////////////////////////////////////////*/
/* 27掲載内容編集(募集条件) -------------------------------------------------------------- */
.flex-recruitment {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.flex-recruitment-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 50%;
    margin-bottom: 20px;
}
.flex-recruitment-inner span:nth-child(odd) {
    width: calc(100% - 145px);
}
.flex-recruitment-inner span:nth-child(even) {
    width: 100px;
    margin-left: 15px;
}
.flex-recruitment-inner input[type="text"],
.flex-recruitment-inner input[type="number"] {
    width: 70px;
}
.flex-recruitment:last-child .flex-recruitment-inner {
    margin-bottom: 0;
}

/* スマホ -------------------------------------------------------------- */
@media screen and (max-width: 767px) {
    /*/////////////////////////////////////////////////
//歯科医院登録
/////////////////////////////////////////////////*/
    /* 27掲載内容編集(募集条件) -------------------------------------------------------------- */
    .flex-recruitment {
        display: block;
    }
    .flex-recruitment-inner {
        width: 100%;
    }
    .flex-recruitment-inner span:nth-child(odd) {
        width: calc(100% - 125px);
    }
}
/*/////////////////////////////////////////////////
//override26追加
/////////////////////////////////////////////////*/
/* トップページメイン -------------------------------------------------------------- */
body.top .main-wrapper .main {
    padding-top: 50px;
}
/* placeholder -------------------------------------------------------------- */
.form_value {
    position: relative;
}
.form_value .placeholder {
    position: absolute;
    top: 10px;
    left: 10px;
    pointer-events: none;
    color: #757575;
}
@media screen and (max-width: 1024px) {
    .form_value .placeholder {
        font-size: 1.4rem;
    }
    input[type="text"],
    textarea {
        font-size: 1.4rem !important;
    }
}
/* 23エントリーシート登録(募集条件)●福利厚生 -------------------------------------------------------------- */
.welfare02 .inner:first-child {
    width: 24%;
}
.welfare02 .inner:nth-child(2) {
    width: 71%;
}
@media screen and (max-width: 767px) {
    .welfare02 .inner:first-child {
        width: 100%;
        margin-right: 0;
    }
    .welfare02 .inner:nth-child(2),
    .welfare02 .inner:nth-child(2) input {
        width: 100%;
    }
}
/* 23エントリーシート登録(募集条件)●有給休暇 -------------------------------------------------------------- */
.paidholiday .inner:nth-child(5) input {
    width: 90%;
}
@media print, screen and (max-width: 1024px) {
    .paidholiday .inner:nth-child(5) input {
        width: 100%;
    }
}

/*/////////////////////////////////////////////////
//override 27
/////////////////////////////////////////////////*/
body.top .main-wrapper .main {
    padding-top: 50px;
}
@media screen and (max-width: 767px) {
    body.top .main-wrapper .main {
        padding-top: 100px;
    }
    .mean-container .mean-bar {
        z-index: 99999999;
    }
}

/*/////////////////////////////////////////////////
//override 29
/////////////////////////////////////////////////*/
.unloggedin .h-btn div.job a {
    background-color: #7db182;
}
@media screen and (max-width: 767px) {
    .unloggedin #header .h-btn {
        right: initial;
        top: 67px;
        width: 100%;
        justify-content: flex-start;
    }
    .unloggedin #header .h-btn div {
        width: calc(100% / 3);
    }
    .unloggedin #header .h-btn div a {
        text-align: center;
        line-height: 1.2;
        padding: 38px 10px 10px 10px;
    }
    .unloggedin #header .h-btn div.job a {
        font-size: 1.2rem;
        padding: 33px 6px 1px 6px;
        background: url(../images/common/icon-job.png) center 6px no-repeat;
        background-color: #7db182;
        background-size: 30px;
    }
}
/*/////////////////////////////////////////////////
//override 30
/////////////////////////////////////////////////*/
.unloggedin .h-btn div.job a {
    background-color: #a1d350;
}
.signature {
    text-align: right;
    display: block;
}
.font-ss-pc {
    font-size: 1.2rem;
}
.font-ss-sp {
    font-size: 1.4rem;
}
.h-btn div.job a:hover {
    background-color: #c27654;
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
}
.otherinsurance4 {
    width: 90% !important;
}
@media screen and (max-width: 767px) {
    .unloggedin #header .h-btn div.job a {
        background-color: #a1d350;
    }
    .signature {
        text-align: center;
    }
    .font-ss-pc {
        font-size: 1.4rem;
    }
    .font-ss-sp {
        font-size: 1.2rem;
    }
}
/*/////////////////////////////////////////////////
//asdstle 31
/////////////////////////////////////////////////*/
.catchcopy {
    padding-top: 120px;
}
.main img {
    position: absolute;
    top: 50px;
    left: 68%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
}
@media screen and (max-width: 767px) {
    .catchcopy {
        padding-top: 100px;
    }
    .main img {
        position: absolute;
        top: 105px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        max-width: 432px;
        width: 86%;
    }
}

/*/////////////////////////////////////////////////
//新規css追加
/////////////////////////////////////////////////*/
.signature{
    margin-top: 20px;
}
.copy0 {
	color: #FF8A91;
	font-size: 2.2rem;
	font-weight: bold;
	margin-bottom: 30px;
}
.license {
    text-align: right;
    display: block;
	font-size: 1.2rem;
	line-height: 1.2;
}
@media screen and (max-width: 767px) {
.copy0 {
	font-size: 1.8rem;
	line-height: 1.6;
}
.license {
    text-align: center;
    display: block;
	font-size: 1.2rem;
}
}