@charset "utf-8";
.reception_group {
    .inner-reception-day {
        width: 80%;
        margin-left: 10px;
        margin-top: 10px;
        input[type="number"] {
            padding: 10px;
        }
    }
}

//remind clinic 共通
.registration.cliniclogin {
    border-bottom: solid 13px $green;
    border-left: solid 13px $green;
    border-radius: 6px;
    border-right: solid 13px $green;
    border-top: none;
    padding: 0;
    h1 {
        background-color: $green;
        color: #ffffff;
        margin: 0;
        padding: 15px 0;
    }
}
