@charset "utf-8";

//site error page
.wrap.error {
    .registration.hygienist {
        background-color: $white;
        h1 {
            background-image: none;
        }
    }
}
