@charset "utf-8";

body.mypage {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    .h-btn {
        .select_gnav {
            a {
                background-color: $dark-pink;
            }
        }
    }
    .step-flow {
        &.step-tree.flow-pink {
            .step-flow-inner {
                width: calc(100% / 3 - 3%);
                &:after {
                    right: -14%;
                    width: 14%;
                }
            }
        }
    }

    .btn-primary {
        display: inline-block;
        background-color: $pink;
        padding: 0em 0.6em;
        font-size: 1.2rem;
        color: $white;
        @extend %radius;
        cursor: pointer;
    }
    .header-job-entry {
        .h-btn {
            div,
            div.logout-btn {
                a {
                    height: 90px;
                }
            }
        }
    }

    .archive-favorite {
        flex: 1;
        .wrap {
            padding-top: 0;
            .good-inner {
                margin: 2em 0;
                .search-box {
                    @media screen and (max-width: $sp) {
                        padding-left: 1em !important;
                        padding-right: 1em !important;
                    }
                    .clinic-name {
                        @media screen and (max-width: $tb) {
                            margin-bottom: 0;
                        }
                    }
                }
                .clinic-info-inner {
                    @media screen and (max-width: $tb) {
                        display: block;
                    }
                    .image_wrap {
                        &.no-image {
                            @media screen and (max-width: $tb) {
                                display: none;
                            }
                        }
                        @media screen and (max-width: $tb) {
                            display: flex;
                            gap: 20px;
                            max-width: 800px;
                            overflow-x: auto;
                            scroll-snap-type: x mandatory;
                            -webkit-overflow-scrolling: touch;
                            scroll-padding: 1rem;
                            padding: 1rem;
                            margin: 2rem auto;
                            border-radius: 5px;
                            .image-object-fit {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex: 0 0 500px;
                                width: 30px;
                                height: 320px;
                                scroll-snap-align: start;
                                font-size: 1.5rem;
                                @media screen and (max-width: $sp) {
                                    flex: 0 0 300px;
                                    height: 200px;
                                }
                            }
                        }
                    }
                    table {
                        @media screen and (max-width: $tb) {
                            margin-left: 0;
                        }
                        tr {
                            grid-template-columns: 32% 68% !important;
                            & .line_height_narrow {
                                margin-top: 0.5em;
                                line-height: 150%;
                            }
                            @media screen and (max-width: $tb) {
                                grid-template-columns: 21% 75% !important;
                            }
                            @media screen and (max-width: $sp) {
                                display: flex;
                                flex-wrap: wrap;
                            }
                            td {
                                &:first-of-type {
                                    &:before {
                                        content: "●";
                                        display: inline-block;
                                        color: $pink;
                                    }
                                    @media screen and (max-width: $sp) {
                                        width: 100%;
                                    }
                                }
                                &:nth-of-type(2) {
                                    @media screen and (max-width: $sp) {
                                        padding-left: 1em;
                                    }
                                }
                                &.line-two {
                                    line-height: 130%;
                                }
                                .time_wrap {
                                    dd {
                                        span {
                                            &:first-of-type {
                                                margin-right: 0.8em;
                                            }
                                        }
                                    }
                                }
                                p {
                                    padding-bottom: 0;
                                }
                                dl {
                                    margin-bottom: 0.5em;
                                    dt {
                                        font-weight: bold;
                                    }
                                }
                                dl.h_list {
                                    display: flex;
                                    flex-wrap: wrap;
                                    margin-bottom: 0.8em;
                                    dt,
                                    dd {
                                        margin-right: 0.9em;
                                    }
                                    dt {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                    .btn-detail-wrap {
                        padding: 1em 0;
                        text-align: center;
                        .btn-detail {
                            display: inline-block;
                            padding: 0.3em 2em;
                            color: $pink;
                            border: 1px solid $pink;
                            cursor: pointer;
                            @extend %radius;
                            &:hover {
                                background-color: $pink;
                                color: $white;
                            }
                        }
                    }
                }
                .detail-btn {
                    .btn-clinic-detail {
                        @media screen and (max-width: $tb) {
                            margin-top: 40px !important;
                        }
                    }
                }
            }
            .image-object-fit {
                overflow: hidden;
                img {
                    width: 500px;
                    object-fit: cover;
                    height: 100%;
                    max-height: 400px;
                    margin-bottom: 10px;
                }
            }
            .btn-clinic-detail {
                cursor: pointer;
            }
            .btn-favorite-delete {
                background-color: #ddd;
                display: block;
                border-radius: 12px;
                color: #ffffff;
                font-size: 1.6rem;
                line-height: 1.5;
                margin: 20px auto 0;
                padding: 20px 15px;
                text-align: center;
                width: 150px;
                cursor: pointer;
            }
            .news-block {
                .news-inner {
                    .image-object-fit {
                        padding: 0;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    .info-edit {
        table {
            tbody {
                tr {
                    td.edit {
                        .btn {
                            display: inline-block;
                            padding: 2px 5px;
                            color: $pink;
                            border: 1px solid $pink;
                            margin-left: 10px;
                            cursor: pointer;
                            border-radius: 3px;
                        }
                    }
                }
            }
        }
    }
    .job-chat {
        .wrap1 {
            .tk-list {
                @media print, screen and (max-width: $pc) {
                    height: 40vh;
                }
                .tk-one {
                    cursor: pointer;
                    .tk-one-l {
                        .talk-icon {
                            img {
                                width: auto;
                            }
                        }
                        .per-name {
                            padding-top: 0;
                        }
                    }
                    .image-object-fit {
                        overflow: hidden;
                        img {
                            object-fit: cover;
                            width: 100px;
                            height: 100%;
                            max-height: 100px;
                        }
                    }
                }
            }
        }
        .wrap2 {
            .ch-top-ic {
                padding: 8px 0 8px 0.5em;
                align-items: center;
                display: grid;
                grid-template-columns: 50px 1fr 110px;
                @media screen and (max-width: $sp) {
                    grid-template-columns: 90px 1fr;
                }

                .talk-icon {
                    text-align: center;
                    width: auto;
                    grid-column: 1/2;
                    &.small {
                        img {
                            @media screen and (max-width: $sp) {
                                width: 70px;
                                height: 70px;
                            }
                        }
                    }
                }
                .ch-name-wrap {
                    display: flex;
                    flex-wrap: wrap;
                    grid-column: 2/3;
                    .ch-name {
                        margin: 0 0.6em 0 0;
                        padding-left: 0.6rem;
                        @media screen and (max-width: $sp) {
                            width: 100%;
                            margin-bottom: 0.1rem;
                        }
                    }
                    .btn-job-detail {
                        @media screen and (max-width: $sp) {
                            width: 63%;
                            font-size: 1.7rem;
                            padding-top: 0.2em;
                            padding-bottom: 0.2em;
                        }
                    }
                }
                .ch-report-wrap {
                    grid-column: 3/4;
                    @media screen and (max-width: $sp) {
                        grid-column: 2/3;
                        display: flex;
                        justify-content: right;
                    }
                    .ch-report {
                        position: static;
                        font-size: 1.2rem;
                        margin-right: 1em;
                        padding-left: 5px;
                        padding-right: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @media screen and (max-width: $sp) {
                            width: 30%;
                            padding-top: 0.2em;
                            padding-bottom: 0.2em;
                            margin-top: -40px;
                        }
                        .ch-report-icon {
                            width: 20px;
                        }
                    }
                }
            }
        }
        .line__container {
            .line__right {
                margin-top: 10px;
                margin-bottom: 10px;
                .edit {
                    position: absolute;
                    right: 6px;
                    top: 5px;
                    font-size: 80%;
                    cursor: pointer;
                    color: $white;
                }
                .self {
                    white-space: pre-wrap;
                }
            }
        }
        .pc,
        .tablet {
            @media screen and (max-width: $pc) {
                display: block;
            }
        }
        .wrap1 {
            .tk-list {
                .tk-one {
                    .tk-one-l {
                        .per-name {
                            .tk-li-name {
                                width: 10em;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                @media screen and (max-width: $sp) {
                                    width: 113px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .wrap1.no-entry {
            width: 100%;
            height: calc(100vh - 410px);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .direct-chat {
        .wrap1 {
            .tk-list {
                @media print, screen and (max-width: $pc) {
                    height: 90px;
                }
                .tk-one {
                    padding-bottom: 20px;
                    .tk-one-l {
                        img {
                            width: 60px;
                        }
                    }
                }
            }
        }
        .wrap2 {
            .line__container {
                .line__right {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    .edit {
                        position: absolute;
                        right: 6px;
                        top: 5px;
                        font-size: 80%;
                        cursor: pointer;
                        color: $white;
                    }
                    .self {
                        white-space: pre-wrap;
                    }
                }
                .scroll {
                    @media print, screen and (max-width: $pc) {
                        height: 50vh;
                    }
                }
            }
        }
    }
    .resume {
        .wrap {
            table {
                tr {
                    td.fileup {
                        display: block;
                        margin-bottom: 0;
                        .image_wrap {
                            position: relative;
                            img.profile {
                                width: 180px;
                            }
                            img.license {
                                width: 300px;
                            }
                            .icon_delete_btn {
                                cursor: pointer;
                                position: absolute;
                                font-size: 1.7rem;
                                top: 5px;
                                left: 160px;
                            }
                            .icon_delete_btn.license {
                                left: 280px;
                            }
                        }
                        .file_label {
                            margin-left: 0;
                        }
                    }
                    td {
                        .list {
                            display: grid;
                            grid-template-columns: 11rem 8rem 1fr;
                            margin-bottom: 10px;
                            @media screen and (max-width: $sp) {
                                grid-template-columns: 11rem 5rem 1fr;
                                grid-auto-rows: 1fr 1fr;
                            }
                            p {
                                padding-bottom: 0;
                            }
                            .year {
                                grid-column: 1 / 2;
                                @media screen and (max-width: $sp) {
                                    grid-row: 1 / 2;
                                    margin-bottom: 1rem;
                                }
                            }
                            .month {
                                grid-column: 2/3;
                                @media screen and (max-width: $sp) {
                                    grid-column: 2/3;
                                    grid-row: 1 / 2;
                                    margin-bottom: 1rem;
                                }
                            }
                            .content {
                                grid-column: 3/4;
                                @media screen and (max-width: $sp) {
                                    grid-column: 1 / 4;
                                    grid-row: 2 / 3;
                                }
                            }
                            .year.input,
                            .month.input {
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                input {
                                    width: 80%;
                                }
                                span {
                                    width: 15%;
                                    text-align: center;
                                }
                            }
                            .content {
                                padding-left: 10px;
                                @media screen and (max-width: $sp) {
                                    padding-left: 0;
                                }
                            }
                        }
                        .list.title {
                            @extend %bg-pink;
                            margin-bottom: 20px;
                            p {
                                text-align: center;
                                color: $white;
                                padding: 15px;
                            }
                            @media screen and (max-width: $sp) {
                                grid-template-columns: 10rem 4rem 1fr;
                                grid-template-rows: 1fr;
                                .year {
                                    grid-column: 1 / 2;
                                    grid-row: 1 / 2;
                                }
                                .month {
                                    grid-column: 2 / 3;
                                    grid-row: 1 / 2;
                                }
                                .content {
                                    grid-column: 3 /4;
                                    grid-row: 1 / 2;
                                }
                            }
                        }
                        .control {
                            display: flex;
                            justify-content: end;
                            span {
                                font-size: 1.8rem;
                                margin: 5px;
                                cursor: pointer;
                                @media screen and (max-width: $sp) {
                                    font-size: 3.2rem;
                                    margin: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .wrap:nth-of-type(2) {
            padding-top: 0;
        }
    }
    .resume.confirm {
        img.profile {
            width: 180px;
        }
        img.license {
            width: 330px;
        }
    }
    .regist-entrysheet.first {
        table {
            tr {
                td {
                    .inner {
                        p {
                            .checkbox-normal {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
    #footer {
        width: 100%;
        @media screen and (max-width: $tb) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
