@charset 'UTF-8';
/* Arrows */
.slick-prev,
.slick-next{
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus{
    color: transparent;
    outline: none;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before,
.slick-disabled:before,
.slick-disabled:before{
    opacity: .25;
}
.slick-prev:before,
.slick-next:before{
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev{
    /*left: -25px;*/
    left: 0;
}
[dir='rtl'] .slick-prev{
    right: -25px;
    left: auto;
}
.slick-prev:before{
}
[dir='rtl'] .slick-prev:before{
}

.slick-next{
    /*right: -25px;*/
				right: -0;
}
[dir='rtl'] .slick-next{
    right: auto;
    left: -25px;
}
.slick-next:before{
}
[dir='rtl'] .slick-next:before{
}
.slick-next:after{
left: 3px;
width: 3px;
height: 3px;
border-top: 1px solid #fff;
border-right: 1px solid #fff;
-webkit-transform: rotate(45deg);
transform: rotate(45deg);
}
.slick-prev:after{
left: 3px;
width: 3px;
height: 3px;
border-top: 1px solid #fff;
border-right: 1px solid #fff;
-webkit-transform: rotate(-45deg);
transform: rotate(-45deg);
}
/* Dots */
.slick-dotted.slick-slider{
    margin-bottom: 30px;
}
.slick-dots{
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li{
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before{
    opacity: 1;
}
.slick-dots li button:before{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before{
    opacity: .75;
    color: black;
}
/* Slider */
.slick-slider{
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list{
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus{
    outline: none;
}
.slick-list.dragging{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track{
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after{
    display: table;
    content: '';
}
.slick-track:after{
    clear: both;
}
.slick-loading .slick-track{
    visibility: hidden;
}
.slick-slide{
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide{
    float: right;
}
.slick-slide.slick-loading img{
    display: none;
}
.slick-slide.dragging img{
    pointer-events: none;
}
.slick-initialized .slick-slide{
    display: block;
}
.slick-loading .slick-slide{
    visibility: hidden;
}
.slick-vertical .slick-slide{
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
.s-slider{
 width: 96%;
 margin: 0 auto;
}

.slick-slide .facilities-img{
 width:100%;
 margin-bottom: 16px;
}
.slick-slide .facilities-img img{
 width:100%;
 height: auto;
}
.slick-slide .facilities-btn{
 display: block;
 color: #FFF;
 background: #1C81C9;
 padding: 0.75em 1em;
 color: #FFF;
border-radius: 24px;
 text-align: center;
 font-size:120%;
}
.slick-slide .facilities-btn:hover{
 background: #2CA5FB;
}
.slick-arrow:before{
 background: #1C81C9;
 border: #F0F3F5 solid 1px;
 padding: 1em;
}
button{
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.slide-arrow{
  position: absolute;
  top: 50%;
  margin-top: -15px;
}
.slick-prev{
  left: 0;
  width: 39px;
  height: 39px;
 background: url("../images/common/arrow-left.png") center center no-repeat;
z-index: 2;
 cursor: pointer;
}
.slick-next{
  right: 0;
  width: 39px;
  height: 39px;
 background: url("../images/common/arrow-right.png") center center no-repeat;
z-index: 2;
 cursor: pointer;
}
.similar-slider .slick-prev{
  left: 5px;
  width: 44px;
  height: 56px;
 background: url("../images/common/arrow-left2.png") center center no-repeat;
}
.similar-slider .slick-next{
  right: 5px;
  width: 44px;
  height: 56px;
 background: url("../images/common/arrow-right2.png") center center no-repeat;
}
@media print, screen and  (max-width: 1020px) { /* スマホ -------------------------------------------------------------- */
.s-slider{
 width: 100%;
 margin: 0 auto;
}
.prev-arrow{
  left: 0;
}
.next-arrow{
  right: 0;
}
.sp-slider {
_display: flex;
}
.sp-slider::after{
  content:"";
  display: block;
  width:48%;
}
.similar-slider .slick-prev{
  left: 3px;
  width: 26px;
  height: 32px;
	background-size: 26px 32px;
}
.similar-slider .slick-next{
  right: 3px;
  width: 26px;
  height: 32px;
	background-size: 26px 32px;
}
}
