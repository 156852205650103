@charset "utf-8";

body.clinic-admin {
    &.has_modal {
        #logo {
            margin-top: 25px;
            @media screen and (min-width: 440px) {
                margin-top: 24px;
            }
            @media screen and (min-width: 540px) {
                margin-top: 23px;
            }
            @media screen and (min-width: 600px) {
                margin-top: 22px;
            }
            @media screen and (min-width: 658px) {
                margin-top: 21px;
            }
            @media screen and (min-width: 710px) {
                margin-top: 19px;
            }
            @media screen and (min-width: 768px) {
                margin-top: 19px;
            }
        }
    }
    .h-btn {
        .select_gnav {
            a {
                background-color: #528357;
            }
        }
        div {
            a {
                height: auto;
            }
            &.logout-btn {
                a {
                    padding-bottom: 20px;
                }
            }
        }
    }
    .header-direct-chat {
        .h-btn {
            div.logout-btn {
                a {
                }
            }
        }
    }

    .wrap {
        table {
            tbody {
                tr {
                    td.edit {
                        .btn {
                            display: inline-block;
                            padding: 2px 5px;
                            color: $pink;
                            border: 1px solid $pink;
                            margin-left: 10px;
                            cursor: pointer;
                            border-radius: 3px;
                        }
                    }
                }
            }
        }
    }
    .wrap.confirm {
        table {
            tbody {
                tr {
                    th {
                        color: $white;
                    }
                }
            }
        }
    }
    .wrap1 {
        .tk-search.no-entry-message {
            padding-top: 16px;
            padding-bottom: 16px;
            p {
                text-align: center;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
        .tk-list {
            .tk-one {
                .tk-one-l {
                    .per-name {
                        padding-top: 0;
                        padding-bottom: 0;
                        .tk-li-name {
                            span {
                                display: block;
                            }
                            .name {
                                width: 10em;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                @media screen and (max-width: $sp) {
                                    width: 113px;
                                }
                            }
                            .serial {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .wrap1.no-entry {
        width: 100%;
        height: calc(100vh - 410px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .wrap5 {
        .wrap5-l {
            .list-applicants {
                .applicants-name {
                    span {
                        color: $black;
                    }
                    span.status {
                        color: $red;
                    }
                }
            }
        }
        .wrap5-r {
            table.not-publish {
                tbody {
                    tr:not(:first-of-type) {
                        td {
                            color: transparent;
                            text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
                        }
                    }
                }
            }
        }
    }
    .job-chat {
        .tk-list {
            @media print, screen and (max-width: $pc) {
                height: 40vh;
            }
            .tk-one {
                cursor: pointer;
                .image-object-fit {
                    overflow: hidden;
                    img {
                        object-fit: cover;
                        width: 100px;
                        height: 100%;
                        max-height: 100px;
                    }
                }
            }
        }
        .line__container {
            .line__right {
                clear: both;
                margin-top: 10px;
                margin-bottom: 10px;
                max-width: 75%;
                min-height: 60px;
                position: relative;
                .edit {
                    position: absolute;
                    right: 6px;
                    top: 5px;
                    font-size: 80%;
                    cursor: pointer;
                    color: $white;
                }
                .self {
                    white-space: pre-wrap;
                }
            }
        }
        .pc,
        .tablet {
            @media screen and (max-width: $pc) {
                display: block;
            }
        }
    }
    .direct-chat {
        .wrap1 {
            .tk-list {
                @media print, screen and (max-width: $pc) {
                    height: 90px;
                }
                .tk-one {
                    padding-bottom: 45px;
                    .tk-one-l {
                        img {
                            width: 60px;
                        }
                    }
                }
            }
        }
        .wrap2 {
            padding-top: 0;
            .line__container {
                .line__right {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    .edit {
                        position: absolute;
                        right: 6px;
                        top: 5px;
                        font-size: 80%;
                        cursor: pointer;
                        color: $white;
                    }
                    .self {
                        white-space: pre-wrap;
                    }
                }
                .scroll {
                    @media print, screen and (max-width: $pc) {
                        height: 50vh;
                    }
                }
                .txt-input {
                    .btn-post {
                        color: $white;
                    }
                }
            }
        }
    }
}
