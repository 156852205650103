@charset "utf-8";

//swal2
.swal2-popup {
    input[type="password"],
    input[type="text"],
    input[type="email"] {
        width: auto;
    }
}
