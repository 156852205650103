/* 基本 -------------------------------------------------------------- */
* {
    margin: 0px;
    padding: 0px;
}
.font-ebg {
    font-family: "EB Garamond", serif;
}
body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
blockquote,
th,
td {
    padding: 0;
    margin: 0;
}
img,
embed {
    max-width: 100%;
    height: auto;
}
object {
    max-width: 100%;
}
img {
    -ms-interpolation-mode: bicubic;
    border: 0px;
    vertical-align: middle;
    image-rendering: -webkit-optimize-contrast;
}
body {
    _font-family: "游明朝", YuMincho, "Hiragino Mincho ProN W3",
        "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝",
        "ＭＳ 明朝", serif;
    font-family: "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "メイリオ",
        Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", "Osaka", sans-serif;
    letter-spacing: 0.05em;
    line-height: 200%;
    color: #444444;
    overflow-x: hidden;
    font-size: 1.7rem;
}
html {
    font-size: 62.5%;
    height: 100%;
}
p {
    padding-bottom: 15px;
    line-height: 200%;
}
img {
    border: 0px;
}
.cf:before,
.cf:after {
    content: "";
    display: table;
}
.cf:after {
    clear: both;
}
.cf {
    zoom: 1;
}
.cl {
    clear: both;
}
a {
    color: #222222;
    text-decoration: none;
    transition: 0.3s;
}
a:hover,
a img:hover {
    filter: alpha(opacity=70);
    -moz-opacity: 0.7;
    opacity: 0.7;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.far {
    margin-right: 5px;
}
ol li {
    list-style-position: outside;
    margin: 0 0 10px 30px;
}
/* Images -------------------------------------------------------------- */
.alignleft,
img.alignleft {
    float: left;
    margin-right: 4%;
    margin-bottom: 20px;
    margin-top: 9px;
}
.alignright,
img.alignright {
    float: right;
    margin-left: 4%;
    margin-bottom: 20px;
    margin-top: 9px;
}
.aligncenter,
img.aligncenter {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: block;
    margin-bottom: 20px;
}
/* フォント -------------------------------------------------------------- */
.font-l {
    font-size: 1.9rem;
}
.font-m {
    font-size: 1.6rem;
}
.font-mm {
    font-size: 1.4rem;
}
.font-s {
    font-size: 1.3rem;
    line-height: 120% !important;
}
.font-ss {
    font-size: 1.2rem;
}
.font-s .maru {
    font-size: 1.6rem;
}
.font-nomal {
    font-weight: normal;
}
sup {
    font-size: 50%;
}
.font-blue {
    color: #1c81c9;
    font-weight: bold;
}
.font-green {
    color: #00a56f;
}
.font-white {
    color: white;
}
.font-red,
a.font-red {
    color: #ff1c1d !important;
}
.font-brown {
    color: #9d7c5c;
}
.kome {
    margin-left: 1em;
    text-indent: -1em;
}
.italic {
    font-style: italic;
}
.break-word {
    word-wrap: break-word;
}
.under-line {
    background: linear-gradient(transparent 60%, #fffca7 60%);
}
/* 余白 -------------------------------------------------------------- */
.margin-b15,
.marginbottom15 {
    margin-bottom: 15px;
}
.marginbottom5 {
    margin-bottom: 10px;
}
.margin-19 {
    margin-right: 1.9%;
}
.margin-r2 {
    margin-right: 2%;
}
.margin-b20 {
    margin-bottom: 20px;
}
.margin-b50 {
    margin-bottom: 50px;
}

/* column -------------------------------------------------------------- */
.column {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.column-salary {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 21px;
}
.column4-ver2 {
    align-items: center;
}
.column .inner {
    list-style: none;
    display: inline-block;
    box-sizing: border-box;
    letter-spacing: 0px;
}
.column .inner {
    _font-size: 90%;
}
.column2 .inner {
    width: 48%;
}
.column3 .inner {
    width: 32%;
    margin-bottom: 15px;
}
.column3 .inner-w {
    width: 50% !important;
}
.column4 .inner,
.column4-ver2 .inner {
    width: 24%;
    margin-bottom: 15px;
}
.column5 .inner {
    width: 18.5%;
}
.column5 .inner:nth-child(5n) {
    margin-right: 0;
}
.column8 .inner {
    width: 12%;
    margin-bottom: 15px;
}
.column .inner img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.inner-reception {
    width: 18.5%;
}
.inner-reception-day {
    width: 59.5%;
}
.flex-justify-content1 {
    justify-content: flex-start;
}
.flex-justify-content1 .inner {
    margin-right: 15px;
    box-sizing: border-box;
}
.column3.flex-justify-content1 .inner {
    width: calc(32% - 2px);
}
.column3.flex-justify-content1 .inner.beside2 {
    width: calc(55% - 2px);
}
.align-items1 {
    align-items: center;
}
.flex-justify-content1-2 {
    justify-content: flex-start;
}
.flex-justify-content1-2 .inner {
    margin-right: 1.7%;
}
/* column特定個所 -------------------------------------------------------------- */
.column-salary {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 21px;
}
.column-salary .inner {
    width: 30%;
    margin-bottom: 15px;
}
.column-salary2 .inner:nth-child(n + 3) {
    margin-left: 15%;
    width: 85%;
}
.column-salary .inner:first-child {
    width: 15%;
}
.column-salary .inner:nth-child(2) {
    width: 85%;
}
.column-salary .inner div.title-sougaku {
    display: inline-block;
    margin-right: 18px;
}
.column-salary .inner div.title-allowances {
    display: inline-block;
    margin-right: 10px;
}
.column-salary3 .inner {
    width: 15%;
}
.column-salary3 .inner:nth-child(even) {
    width: 65%;
    margin-left: 9.4%;
}
.paidholiday .inner:first-child {
    width: 24%;
    margin-bottom: 30px;
}
.paidholiday .inner:nth-child(2),
.paidholiday .inner:nth-child(5) {
    width: 71%;
}
.paidholiday .inner:nth-child(3) {
    width: 24%;
    margin-right: 76%;
    margin-bottom: 30px;
}
.paidholiday .inner:nth-child(4) {
    width: 24%;
}
.paidholiday .inner:nth-child(5) input {
    width: 82%;
    margin-right: 0;
}
/* 囲み -------------------------------------------------------------- */
.wrap {
    max-width: 1200px;
    width: 96%;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 60px;
    _overflow: hidden;
}
#contents-bk {
    padding: 20px 0 50px;
}
.contents {
    max-width: 1400px;
    width: 96%;
    margin: 0 auto;
}
.bg-orange {
    background-color: #faf4ed;
}
.bg-orange2 {
    background-color: #ffe2cc;
}
.bg-pink {
    background-color: #fdf5f5;
}
.green-box {
    border: solid 1px #7db182;
    padding: 20px;
}
/* 基本情報登録 -------------------------------------------------------------- */
.green-box.form-caution {
    padding-bottom: 0;
    margin-bottom: 80px;
}
.form-caution {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.icon-right {
    width: calc(100% - 75px);
    box-sizing: border-box;
    line-height: 1.5;
    margin-bottom: 25px;
}
.icon-left {
    width: 55px;
    margin-bottom: 25px;
    margin-right: 20px;
    box-sizing: border-box;
}
.icon-left span {
    padding: 0 5px;
    text-align: center;
    width: 100%;
    display: block;
    box-sizing: border-box;
    font-size: 1.4rem;
}
.icon-hissu {
    border: solid 1px #ff0000;
    background-color: #ff0000;
    border-radius: 6px;
    color: #ffffff;
}
.icon-nini {
    border: solid 1px #959595;
    background-color: #ffffff;
    border-radius: 6px;
    color: #ff0000;
}
.icon-koukai {
    background-color: #8bc3d8;
    border: solid 1px #8bc3d8;
    border-radius: 6px;
    color: #ffffff;
}
.icon-hikoukai {
    background-color: #959595;
    border-radius: 6px;
    color: #ffffff;
}
.icon {
    display: flex;
    flex-direction: column;
    width: 50px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    right: 5px;
}
.icon span {
    padding: 0;
    text-align: center;
    display: inline-block;
    box-sizing: border-box;
    font-size: 1.2rem;
    line-height: 2.4;
}
.icon span:first-child {
    margin-bottom: 10px;
}
.icon-position th {
    position: relative;
}
/* table -------------------------------------------------------------- */
table.table1 {
    margin-bottom: 80px;
    border-collapse: collapse;
    width: 100%;
    border-top: solid 1px #b7b7b7;
    border-right: solid 1px #b7b7b7;
}
.table1 th {
    background-color: #7db182;
    color: #ffffff;
    padding: 20px;
    border-left: solid 1px #7db182;
    border-bottom: solid 1px #b7b7b7;
    text-align: left;
    box-sizing: border-box;
}
.table1 td {
    padding: 30px 20px;
    border-bottom: solid 1px #b7b7b7;
    text-align: left;
    box-sizing: border-box;
}
.table1-2 th {
    background-color: #eff4f6;
}
.table1-2 td {
    border-left: solid 1px #a3d5f1;
}
.confirm .table1 th {
    background-color: #eeeeee;
    color: initial;
    border-left: solid 1px #b7b7b7;
}
table.table2 {
    margin-bottom: 30px;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    border-top: solid 1px #a3d5f1;
    border-right: solid 1px #a3d5f1;
}
.table2 th,
.table2 td {
    padding: 30px;
    border-left: solid 1px #a3d5f1;
    border-bottom: solid 1px #a3d5f1;
}
.table2 td {
    text-align: left;
    padding-left: 70px;
    padding-right: 70px;
}
/* ヘッダーリンクボタン -------------------------------------------------------------- */
.h-btn {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: flex-end;
}
.h-btn a {
    display: block;
    padding: 45px 15px 8px 15px;
    color: #ffffff;
}
.h-btn div.signup a {
    background: url("../images/common/icon-signup.png") center 15px no-repeat;
    background-color: #ffab6d;
    background-size: 30px;
}
.h-btn div.login a {
    background: url("../images/common/icon-login.png") center 15px no-repeat;
    background-color: #ff7540;
    background-size: 30px;
}
.h-btn div.job a {
    background: url("../images/common/icon-job.png") center 15px no-repeat;
    background-color: #ff8a91;
    background-size: 30px;
    line-height: 1;
}
.h-btn div.signup a:hover,
.h-btn div.login a:hover,
.h-btn div.job a:hover {
    background-color: #c27654;
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
}
#logo {
    margin-top: 19px;
    margin-bottom: 19px;
    margin-left: 2%;
    max-width: 300px;
    width: 100%;
}
/* ナビゲーション -------------------------------------------------------------- */
nav {
    background: #fff;
    width: 100%;
    font-size: 1.8rem;
    background-color: #ff848b;
}
#dropmenu {
    display: flex;
    justify-content: space-around;
    z-index: 9999;
    max-width: 1200px;
    width: 100%;
    letter-spacing: 0px;
    box-sizing: border-box;
    text-align: center;
    list-style-type: none;
    margin: 0 auto;
}
#dropmenu li {
    margin: 0;
    text-align: center;
    list-style: none;
    display: inline-block;
    box-sizing: border-box;
    transition: 0.3s;
}
#dropmenu li a {
    color: #ffffff;
    display: block;
    width: 100%;
    padding: 1.3em 0 1.2em 40px;
    line-height: 140%;
    background-size: 25px;
    box-sizing: border-box;
    background-repeat: no-repeat;
}
#dropmenu li:first-child a {
    background-image: url("../images/common/icon-features.png");
    background-position: left 22px;
    background-repeat: no-repeat;
}
#dropmenu li:nth-child(2) a {
    background-image: url("../images/common/icon-interview.png");
    background-position: left 22px;
    background-repeat: no-repeat;
}
#dropmenu li:nth-child(3) a {
    background-image: url("../images/common/icon-qa.png");
    background-position: left 22px;
    background-repeat: no-repeat;
}
#dropmenu li:nth-child(4) a {
    background-image: url("../images/common/icon-experiences.png");
    background-position: left 22px;
    background-repeat: no-repeat;
}
#dropmenu li:nth-child(5) a {
    background-image: url("../images/common/icon-ad.png");
    background-position: left 22px;
    background-repeat: no-repeat;
}
#dropmenu li:nth-child(6) a {
    background-image: url("../images/common/icon-contact.png");
    background-position: left 22px;
    background-repeat: no-repeat;
}
#dropmenu li ul {
    font-size: 1.8rem;
    list-style: none;
    position: absolute;
    z-index: 100;
    top: 100%;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 10px 0 0 0;
}
#dropmenu li ul li {
    overflow: hidden;
    width: 100%;
    height: 0;
    color: #003b95;
    border: none;
    transition: 0.3s;
}
#dropmenu li ul li a {
    padding: 10px;
    background: #fff;
    text-align: left;
    font-weight: blod;
    color: #222222;
    border-left: solid 1px #dddddd;
    border-right: solid 1px #dddddd;
    border-bottom: solid 1px #dddddd;
    font-size: 1.8rem;
}
#dropmenu > li:hover > a,
#dropmenu > li > a.active {
    color: #ffc7ca;
    border-radius: 6px;
}
#dropmenu > li.home:hover a.active {
    color: #9d7c5c;
}
#dropmenu > li.home a.active {
    color: #222222;
}
#dropmenu > li:hover li:hover > a {
    background: #33cc9a;
    color: #ffffff;
}
#dropmenu li:hover > ul > li {
    overflow: visible;
    height: auto;
}
#dropmenu li ul li ul {
    top: 0;
    left: 100%;
}
#dropmenu li:last-child ul li ul {
    left: -100%;
    width: 100%;
}
#dropmenu li ul li ul li a {
    background: #eed10b;
    color: #222222;
}
#dropmenu li:hover ul li ul li:hover > a {
    background: #d8be0a;
}
#dropmenu li ul li ul:before {
    position: absolute;
    content: "";
    top: 8px;
    left: -14px;
    width: 0;
    height: 0;
    border: 5px solid transparent;
}
#dropmenu li:last-child ul li ul:before {
    position: absolute;
    content: "";
    top: 13px;
    left: 200%;
    margin-left: -20px;
    border: 5px solid transparent;
}
#dropmenu li.current {
    position: relative;
}
#dropmenu li.current::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    border-bottom: #ffbc03 3px solid;
    color: #e6b422;
    width: 100%;
}
.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}
/* メイン -------------------------------------------------------------- */
.main-bgcolor {
    background: linear-gradient(#f4f6f5, #e9eded);
}
.main-wrapper {
    background-image: url("../images/common/main.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}
.main {
    position: relative;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 200px 20px 50px 0;
    box-sizing: border-box;
}
.catchcopy {
    width: 60%;
    margin: 0 0 0 auto;
}
.copy1 {
    color: #ff8a91;
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 30px;
    line-height: 1.2;
}
.copy2 {
    color: #8d8d8d;
    font-size: initial;
    font-weight: 600;
    margin-bottom: 80px;
}
.main-btn {
    width: 60%;
    margin: 0 0 80px auto;
    display: flex;
    align-items: center;
}
.main-btn a:first-child {
    margin-right: 60px;
}
.btn-orange {
    font-size: 2.2rem;
    background-color: #ffab6d;
    background-image: url("../images/common/icon-signup2.png");
    background-position: calc(50% - 48px) center;
    background-repeat: no-repeat;
    background-size: 23px;
    border-radius: 6px;
    padding: 40px 0px 40px 0px;
    color: #ffffff;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    position: relative;
    display: block;
    width: 100%;
    box-sizing: border-box;
}
.btn-pink {
    font-size: 2.2rem;
    background-color: #ff8a91;
    background-image: url("../images/common/icon-job2.png");
    background-position: calc(50% - 48px) center;
    background-repeat: no-repeat;
    background-size: 23px;
    border-radius: 6px;
    padding: 40px 0px 40px 0px;
    color: #ffffff;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    position: relative;
    display: block;
    width: 100%;
    box-sizing: border-box;
}
.place-search .btn-pink2 {
    margin: 50px auto 0;
    margin-left: initial;
    margin-right: 3%;
    width: 31.333%;
    box-sizing: border-box;
}
.place-search .btn-pink2:nth-child(-n + 3) {
    margin-top: 0;
}
.place-search .btn-pink2:nth-child(3n) {
    margin-right: 0;
}
.btn-orange span,
.btn-pink span {
    position: absolute;
    top: 50%;
    left: 56%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
}
/* メインボタンエフェクト -------------------------------------------------------------- */
.effect1 {
    overflow: hidden;
}
.effect1:hover {
    opacity: 1;
}
.effect1 {
    position: relative;
    z-index: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.anim {
    -moz-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
}
.anim:before {
    position: relative;
    content: "";
    display: block;
    margin-top: 100%;
}
.anim:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
}
.effect1:hover > .anim {
    -moz-animation: anim-out 0.75s;
    -webkit-animation: anim-out 0.75s;
    animation: anim-out 0.75s;
}
.effect1:hover > .anim:after {
    -moz-animation: anim-out-pseudo 0.75s;
    -webkit-animation: anim-out-pseudo 0.75s;
    animation: anim-out-pseudo 0.75s;
}
@-webkit-keyframes anim-out {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
@-moz-keyframes anim-out {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
@-ms-keyframes anim-out {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
@keyframes anim-out {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
@-webkit-keyframes anim-out-pseudo {
    0% {
        background: rgba(252, 252, 252, 0.65);
    }
    100% {
        background: transparent;
    }
}
@-moz-keyframes anim-out-pseudo {
    0% {
        background: rgba(252, 252, 252, 0.65);
    }
    100% {
        background: transparent;
    }
}
@-ms-keyframes anim-out-pseudo {
    0% {
        background: rgba(252, 252, 252, 0.65);
    }
    100% {
        background: transparent;
    }
}
@keyframes anim-out-pseudo {
    0% {
        background: rgba(252, 252, 252, 0.65);
    }
    100% {
        background: transparent;
    }
}
.effect2:hover {
    opacity: 0.85;
    border-radius: 40px;
}
/* トップページ絞り込み検索 -------------------------------------------------------------- */
.list-conditions {
    display: none;
    position: absolute;
    top: 330px;
    left: 0;
    background-color: #ffffff;
    border-radius: 6px;
    border: solid 1px #000000;
    padding: 20px;
    box-sizing: border-box;
}
.list-conditions.list-active {
    display: flex;
    flex-wrap: wrap;
    _position: absolute;
    width: 100%;
    z-index: 1000000;
}
.list-conditions-inner {
    width: 30%;
    font-size: 1.2rem;
}
.search3 a {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 12px;
    border: solid 5px #d6d6d6;
    background-color: #ffffff;
    background-image: url("../images/common/arrow.svg");
    background-repeat: no-repeat;
    background-size: 29px 10px;
    background-position: right 10px center;
    _font-size: 1.2rem;
    cursor: pointer;
    box-sizing: border-box;
    padding: 10px 10px;
    width: 100%;
    display: block;
}
.main-bgcolor-active .main {
    position: relative;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0px 20px 50px 0;
    box-sizing: border-box;
}
/* メールフォーム -------------------------------------------------------------- */
.main-search {
    display: flex;
    background-color: #ffffff;
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    opacity: 0.8;
    padding: 30px;
    border-radius: 12px;
}
.main-search div {
    width: 19%;
    margin-right: 1%;
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
}
.main-search div:nth-child(5) {
    margin-right: 0;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 12px;
    border: solid 5px #d6d6d6;
    background-color: #ffffff;
    background-image: url("../images/common/arrow.svg");
    background-repeat: no-repeat;
    background-size: 29px 10px;
    background-position: right 10px center;
    font-size: 100%;
    cursor: pointer;
    box-sizing: border-box;
    padding: 20px 10px;
}
input[type="text"],
input[type="password"],
input[type="number"],
input[type="tel"],
input[type="email"],
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #ffffff;
    font-size: 100%;
    box-sizing: border-box;
    border: solid 1px #d6d6d6;
    padding: 20px 10px;
    width: 100%;
}
.main-search input[type="text"],
.commitment-search input[type="text"] {
    border-radius: 12px;
    border: solid 5px #d6d6d6;
    padding: 20px 10px;
}
.main-search select,
.main-search input[type="text"],
.main-search input[type="submit"] {
    width: 100%;
    margin: 0;
}
.main-search input[type="submit"] {
    padding: 20px 50px 20px 72px;
}
.submit-btn {
    position: relative;
    box-sizing: border-box;
}
.submit-btn::before {
    position: absolute;
    left: 42px;
    top: 17px;
    top: 45%;
    left: 38%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    display: inline-block;
    content: "";
    width: 30px;
    height: 30px;
    background-image: url("../images/common/icon-search.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
}
a.btn {
    display: inline-block;
    padding: 0;
    line-height: 150%;
}

input[type="submit"],
input[type="button"],
a.btn {
    font-size: 100%;
    padding: 20px 0 20px 0;
    color: #fff;
    background: #7db182;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    width: 200px;
}
input[type="submit"]:hover,
a.btn:hover {
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    opacity: 0.8;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.loginform_wrap label {
    color: #707070;
}
.loginform_wrap input[type="button"],
.loginform_wrap input[type="submit"] {
    width: 200px;
}
.loginform_wrap input[type="text"],
.loginform_wrap input[type="password"] {
    border-radius: 6px;
}
.password-input {
    margin-bottom: 0px;
}
.user_btn_wrap {
    text-align: center;
}
.user_btn_wrap input[type="submit"] {
    margin-bottom: 40px;
}
.user_btn_wrap input[type="button"] {
    margin-top: 20px;
}
.confirm input[type="button"],
input[type="button"].btn_gray,
input[type="submit"].btn_gray,
a.btt2,
a.btn.btn_gray {
    background: #959595;
}
input.field-w90,
select.field-w90 {
    width: 90%;
}
input.field-w80,
select.field-w80 {
    width: 80%;
}
input.field-w70,
select.field-w70 {
    width: 70%;
}
input.field-w48,
select.field-w48 {
    width: 48%;
}
input.field-w30,
select.field-w30,
label.field-w30 {
    width: 30%;
}
input.field-w20,
select.field-w20 {
    width: 20%;
}
input.field-w10,
select.field-w10 {
    width: 10%;
}

input.field-w12,
select.field-w12 {
    width: 12%;
}

input.field-w9,
select.field-w9 {
    width: 8%;
}
input.field-w5,
select.field-w5 {
    width: 5%;
}
table input[type="text"],
table input[type="number"],
table input[type="password"] {
    padding: 15px 10px;
}
table input[type="time"] {
    padding: 13px 0 13px 1px;
}
table select {
    background-image: url("../images/common/arrow2.svg");
    background-size: 40px 20px;
    border-radius: 0px;
    border: solid 1px #d6d6d6;
    padding: 15px 10px;
    margin: 0;
}
.form-box {
    margin-bottom: 20px;
}
.alert {
    color: #ff0000;
}
input.alert-input,
#inquiry input.inner.alert-input,
#inquiry textarea.alert-input {
    border: solid 2px #ff848b;
    padding: 19px 10px;
}
select.alert-select {
    border: solid 2px #ff848b;
}
/* 市町村やこだわりで検索 -------------------------------------------------------------- */
.commitment-search-inner {
    display: flex;
    background-color: #d5d0d0;
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    opacity: 0.8;
    padding: 15px;
    border-radius: 12px;
}
.commitment-search-inner div {
    width: 19%;
    margin-right: 1%;
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
}
.commitment-search select,
.commitment-search input[type="text"],
.commitment-search input[type="submit"] {
    width: 100%;
    margin: 0;
}
.commitment-search .search4 {
    box-sizing: border-box;
    width: 75%;
}
.commitment-search input[type="text"] {
    border: none;
}
.commitment-search .submit-btn {
    box-sizing: border-box;
    width: 25%;
}
.commitment-search input[type="submit"] {
    padding: 17px 50px 17px 72px;
}
/* チェックボックス ----------------------------------------------------------------------------------------- */
.list-conditions input[type="checkbox"] {
    display: inline-block;
}
.list-conditions input[type="checkbox"] + label {
    border: none;
    box-sizing: border-box;
    margin-left: 5px;
}
.list-conditions input[type="checkbox"]:checked + label {
    border: none;
    color: #ff848b;
}
.checkbox-agree {
    display: block;
    text-align: center;
    margin: 0 auto 40px;
}
.checkbox-agree input[type="checkbox"] {
    display: inline-block;
    text-align: center;
}
.checkbox-agree input[type="checkbox"] + label {
    border: none;
    cursor: pointer;
}
.checkbox-agree input[type="checkbox"]:checked + label {
    border: none;
    color: #ff848b;
}
input[type="checkbox"] {
    display: none;
}
input[type="checkbox"] + label {
    border: solid 1px #d6d6d6;
    box-sizing: border-box;
}
.checkbox-label,
.checkbox-label2 {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 8px 40px 8px 15px;
    position: relative;
    box-sizing: border-box;
    _display: block;
    width: 100%;
    font-size: 1.4rem;
}
.checkbox-label:nth-child(4n),
.checkbox-label2:nth-child(4n) {
    margin-right: 0;
}
.checkbox-label::before,
.checkbox-label2::before {
    background-color: #ababab;
    border-radius: 24px;
    content: "";
    display: block;
    height: 22px;
    right: 15px;
    margin-top: -8px;
    position: absolute;
    top: 43%;
    width: 22px;
}
.checkbox-label::after,
.checkbox-label2::after {
    border-right: 5px solid #ffffff;
    border-bottom: 4px solid #ffffff;
    content: "";
    display: block;
    height: 15px;
    right: 16px;
    margin-top: -16px;
    position: absolute;
    top: 56%;
    transform: rotate(45deg) translate3d(0, 4px, 0) scale3d(0.7, 0.7, 1);
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    width: 8px;
}
.checkbox-label2 {
    position: relative;
    padding: 25px 0;
}
.checkbox-label2 span {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    _width: calc(100% - 15px);
    margin-right: 45px;
    line-height: 1.4;
}
/* チェックボックスONエフェクト */
input[type="checkbox"]:checked + label {
    border: solid 1px #ffc1c5;
    color: #ff848b;
}
input[type="checkbox"]:checked + .checkbox-label::before {
    background-color: #ff8a91;
    border-color: #666;
}
input[type="checkbox"]:checked + .checkbox-label::after {
}
/* ラジオボタン ----------------------------------------------------------------------------------------- */
input[type="radio"] + label {
    margin-right: 10px;
}
input[type="radio"] + label:before {
    background-color: #cccccc;
}
/* デフォルトのチェックボックス・ラジオボタンを非表示 */
input[type="radio"] {
    display: none;
}
/* 疑似要素でチェックボックス・ラジオボタンを作成 */
input[type="radio"] + label {
    position: relative;
    padding: 0 0 0 25px;
    cursor: pointer;
    line-height: 1.5;
}
input[type="radio"] + label:before {
    content: "";
    position: absolute;
    top: 1px;
    left: 0;
    width: 15px;
    height: 15px;
    border: 1px solid #707070;
    border-radius: 50%;
}
/* チェックボックス・ラジオボタンをクリックしたときのスタイル */
input[type="radio"]:checked + label:before {
    background: #ff8a91;
}
/* 見出し -------------------------------------------------------------- */
h1.title1,
#top h2 {
    position: relative;
    text-align: center;
    margin-bottom: 100px;
    font-size: 3rem;
}
h1.title1:after,
#top h2:after {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    content: "";
    display: block;
    background-color: #ffbfc3;
    height: 8px;
    width: 78px;
}
h1.title2 {
    font-size: 3rem;
    font-weight: 400;
    border-bottom: solid 1px #7db182;
    padding-bottom: 20px;
    margin-bottom: 80px;
}
h1.title3 {
    font-size: 3rem;
    font-weight: 400;
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 80px;
}
h1.title4 {
    font-size: 2.4rem;
    font-weight: 400;
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 80px;
}
h1.title5 {
    font-size: 2rem;
    font-weight: 400;
    background-color: #a7a7a7;
    color: #ffffff;
    padding: 10px 10px 10px 60px;
}
h2.title1 {
    color: #ff848b;
    margin-bottom: 50px;
    font-size: 2.4rem;
}
h2.title2 {
    color: #5d5b5b;
    margin-top: 60px;
    margin-bottom: 30px;
    font-size: 2.6rem;
    font-weight: bold;
}
h2.title3 {
    position: relative;
    margin-top: 60px;
    padding-right: 60px;
    margin-bottom: 30px;
    font-size: 2.4rem;
    font-weight: 400;
}
h2.title3::after {
    content: "";
    position: absolute;
    background-color: #ff848b;
    width: 65%;
    height: 1px;
    top: 50%;
    right: 0;
    display: block;
}
h2.title4 {
    border-left: solid 10px #3faf4a;
    color: #0f0f0f;
    padding-left: 20px;
    margin-top: 60px;
    margin-bottom: 30px;
    font-size: 2.6rem;
}
.wrap h2.title2:first-child {
    margin-top: 0;
}
h3.title1 {
    background-color: #d4d4d4;
    border-radius: 12px;
    color: #ffffff;
    padding: 10px 10px;
    margin-bottom: 50px;
    font-size: 2.6rem;
}
h3.title2 {
    /*position: relative;*/
    color: #ff848b;
    /* text-align: center; */
    margin-left: 50px;
    margin-bottom: 20px;
    font-size: 2.6rem;
}
h3.title2 span {
    position: relative;
}
h3.title2-2 {
    color: #ff848b;
    margin-bottom: 50px;
    font-size: 2.6rem;
}
h3.title3 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.6rem;
}
h3.title4 {
    color: #ff848b;
    margin-bottom: 10px;
    font-size: 2.2rem;
}
/* トップページ新着情報 -------------------------------------------------------------- */
#news h4 {
    font-size: 2.4rem;
    font-weight: normal;
    margin-bottom: 5px;
}
.news-text {
    color: #ff848b;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 20px;
}
.news-block {
    display: flex;
    justify-content: space-between;
}
.news-inner {
    border: solid 1px #dcd7d7;
    box-sizing: border-box;
    width: 30%;
    padding-bottom: 20px;
}
.news-info-inner {
    display: flex;
    align-items: center;
}
.news-info-left {
    font-size: 1.2rem;
    text-align: center;
    margin-right: 30px;
    max-width: 60px;
    width: 100%;
    box-sizing: border-box;
}
.news-info-right {
    width: 85%;
}
.news-inner > img {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-bottom: solid 1px #dcd7d7;
}
.news-inner > div,
.news-inner h4 {
    padding: 0 20px;
}
.btn-orange2 {
    padding: 15px 20px;
    margin: 20px auto 0;
    display: block;
    text-align: center;
    width: 200px;
    background-color: #ffab6d;
    color: #ffffff;
    border-radius: 12px;
}
.btn-pink2 {
    padding: 25px 15px 25px 0;
    margin: 50px auto 50px;
    display: block;
    text-align: center;
    width: 300px;
    background-color: #ff8a91;
    color: #ffffff;
    border-radius: 12px;
    vertical-align: middle;
    position: relative;
    font-size: 2.6rem;
}
.btn-pink2::before,
.btn-pink2::after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    content: "";
    vertical-align: middle;
}
.btn-pink2::before {
    right: 40px;
    width: 16px;
    height: 16px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
/* トップページデンタルリンクの特徴 ----------------------------------------------------------------------------------------- */
.features-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.features-inner {
    width: 100%;
    background-color: #ffffff;
    padding: 60px;
    margin: 0 0px 8%;
    box-sizing: border-box;
    box-shadow: 0px 10px 10px 0px rgb(0 0 0 / 16%);
    border-radius: 12px;
}
.features-inner:nth-child(3),
.features-inner:nth-child(4) {
    margin: 0 0 50px;
}
.features-inner:nth-child(4) {
    border: 1px solid rgb(255 117 64 / 60%);
    background-color: rgb(255 255 255 / 60%);
}
.features-img {
    float: right;
    width: 35%;
    text-align: center;
}
.features-text {
    float: left;
    width: 65%;
}
.features-inner:nth-child(4) h3.title2 {
    color: #ff7540;
}
.features-inner:nth-child(4) .features-img {
    float: right;
    width: 45%;
    text-align: center;
}
.features-inner:nth-child(4) .features-text {
    float: left;
    width: 50%;
    margin-top: 2%;
}
h3.title2 img {
    position: absolute;
    top: -90px;
    right: -135px;
}
.site-text {
    text-align: center;
    margin: 10% 0;
}
.site-text h3 {
    margin-bottom: 10px;
}
.pink {
    color: #ff9ba1;
}
h3.feature {
    text-align: center;
    margin-bottom: 8%;
    font-size: 1.4em;
    font-weight: 500;
}
/* トップページユーザーガイド ----------------------------------------------------------------------------------------- */
#userguide .inner {
    background-color: #ffffff;
    padding: 60px 30px;
    margin: 0 10px 60px;
    box-sizing: border-box;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 16%);
    border-radius: 12px;
    width: 29%;
}
.userguide-img {
    width: auto;
    height: 139px;
    margin-bottom: 30px;
    position: relative;
}
.userguide-img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}
/* トップページ体験談 ----------------------------------------------------------------------------------------- */
#experiences .inner {
    background-color: #ffffff;
    border: solid 1px #dcd7d7;
    padding: 40px 40px;
    margin-bottom: 90px;
    box-sizing: border-box;
    width: 46%;
}
.experiences-img {
    float: left;
    width: 45%;
    text-align: center;
}
.experiences-text {
    float: right;
    width: 50%;
}
/* フッターバナー ----------------------------------------------------------------------------------------- */
.wrap.banner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 100px 0 80px;
}
.banner li {
    margin-right: 3.5%;
    margin-bottom: 20px;
    width: 31%;
    box-sizing: border-box;
    text-align: center;
}
.banner li:nth-child(3n) {
    margin-right: 0;
}
.banner li img {
    max-width: 300px;
    width: 100%;
}
/* フッター ----------------------------------------------------------------------------------------- */
#footer {
    max-width: 100%;
    margin: 0 auto;
    padding: 30px;
    clear: both;
    background-color: #d6d6d6;
}
footer {
    max-width: 1400px;
    margin: 0 auto;
    clear: both;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.footer-left {
    position: relative;
    width: 50%;
}
.footer-left-inner {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}
.footer-logo {
    margin-bottom: 10px;
    max-width: 300px;
    width: 100%;
}
.copy {
    font-size: 1.2rem;
}
.footer-right {
    width: 40%;
}
.footer-menu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 166px;
    list-style: none;
}
.footer-menu li a {
    position: relative;
    display: block;
    margin-bottom: 10px;
    box-sizing: border-box;
}
.footer-menu a:before {
    position: absolute;
    top: -2px;
    left: -20px;
    display: block;
    content: "-";
}
#backtop {
    position: fixed;
    display: none;
    right: 20px;
    bottom: 20px;
    margin: 0;
    z-index: 1000;
}
#backtop a {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 65px;
    background: rgb(255 138 145);
    color: #fff;
    font-size: 32px;
    border-radius: 100%;
    vertical-align: middle;
    text-decoration: none;
}
#backtop a::before,
#backtop a::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: "";
    vertical-align: middle;
}
#backtop a::before {
    left: 20px;
    top: 10px;
    width: 20px;
    height: 20px;
    border-top: 3px solid #ffffff;
    border-right: 3px solid #ffffff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
/* breadcrumb ----------------------------------------------------------------------------------------- */
.breadcrumb-wrap {
    background-color: #dcd7d7;
    padding: 5px;
}
.breadcrumb {
    max-width: 1200px;
    width: 96%;
    margin: 0 auto;
    color: #707070;
}
.breadcrumb a {
    color: #707070;
}
/* 面接サポートとは ----------------------------------------------------------------------------------------- */
.border-b-pink {
    border-bottom: solid 1px #ff848b;
}
.border-t-pink {
    border-top: solid 1px #ff848b;
}
.support-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
}
.support-photo {
    max-width: 451px;
    width: 43%;
}
.support-text {
    width: 52%;
}
/* よく似た求人 ----------------------------------------------------------------------------------------- */
.similar-slider .slick-slide {
    margin: 0 0.3%;
    // margin: 0 0.5%;
}
.place-search {
    display: flex;
    flex-wrap: wrap;
}
/*Q&A------------------------------------------------------------------------------------------------------*/
.title-qa-1 {
    margin: 2em 0;
    font-weight: 500;
}
.title-qa-2 {
    margin: 2em 0;
    font-weight: 500;
    padding-left: 2em;
    border-left: 7px solid #ff848b;
}
.btn-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.btn-pink3 {
    padding: 1%;
    display: block;
    width: 29%;
    margin-bottom: 3%;
    text-align: center;
    background-color: #ff8a91;
    color: #ffffff;
    border-radius: 0px;
    vertical-align: middle;
    position: relative;
    font-size: 1.6rem;
}
.btn-pink3:nth-child(3) {
    line-height: 1.2;
}
dl.toggle dt {
    margin-top: 25px;
    cursor: pointer;
    padding: 1.5em 2em 1.5em 5em;
    position: relative;
    background: #fefdfc;
    color: #222222;
    font-size: 1.2em;
    text-align: left;
    position: relative;
    border-bottom: 0px solid #dadadb;
    font-weight: 500;
}
dl.toggle dt::before,
dl.toggle dt::after {
    position: absolute;
    top: 23px;
    right: 10px;
    content: "";
    display: inline-block;
    width: 13px;
    height: 13px;
    border-top: 0px solid #96979b;
    transform: translateX(-50%);
}
dl.toggle dt::after {
    position: absolute;
    top: 40px;
    right: 34px;
    width: 10px;
    height: 10px;
    border-top: 2px solid #ff848b;
    border-right: 2px solid #ff848b;
    -webkit-transform: rotate(45deg);
    transform: rotate(135deg);
}
dl.toggle dt.active::after {
    position: absolute;
    top: 34px;
    right: 34px;
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #ff848b;
    border-right: 2px solid #ff848b;
    transform: translateX(-50%);
    transform: rotate(-45deg);
}
dl.toggle dd {
    display: none;
    background: #fefdfc;
    text-align: left;
    padding: 1em 6em 1em 6em;
}
.faq-q {
    /* padding-left: 3em; */
    position: relative;
}
.faq-q img {
    position: absolute;
    left: -50px;
    top: -5px;
}
.faq-a {
    position: relative;
    padding: 1.5em 2em 1.5em 4.5em;
    background-color: #f2f2f2;
    line-height: 1.5;
}
.qa-list {
    text-align: right;
    margin: 2em 0;
    padding-right: 30px;
    letter-spacing: 0px;
}
.qa-list a {
    color: #ff848b;
}
/*体験談-------------------------------------------------------------------------------------------------------------------*/
.inner-experiences-text {
    text-align: center;
    margin-bottom: 3em;
}
#inner-experiences .inner {
    position: relative;
    background-color: #ffffff;
    border: solid 1px #dcd7d7;
    padding: 40px 40px;
    margin-bottom: 90px;
    box-sizing: border-box;
    width: 80%;
    float: left;
}
#inner-experiences .inner:nth-child(2n) {
    float: right;
}
.experiences-number {
    position: absolute;
    top: -60px;
    left: 0px;
}
#inner-experiences .inner:nth-child(2n) .experiences-number {
    left: initial;
    right: 0px;
}
#inner-experiences .experiences-img {
    float: left;
    width: 30%;
    text-align: center;
}
#inner-experiences .experiences-img img {
    margin-bottom: 2em;
}
#inner-experiences .experiences-text {
    float: right;
    width: 65%;
}
.experiences-day {
    font-size: 0.8em;
    text-align: right;
}
/*広告募集----------------------------------------------------------------------------------------------------------------*/
#koukoku h2.title3::after {
    background-color: rgb(255 132 139 / 60%);
    height: 2px;
}
.koukoku-text-box {
    padding: 0 2em 2em;
    border-bottom: 2px solid rgb(255 132 139 / 60%);
}
.title-ex {
    font-weight: 500;
    margin-top: 5em;
    margin-bottom: 1em;
}
.koukoku-ex {
    padding: 1em;
}
.koukoku-ex-inner {
    border: 1px solid #ffd2d5;
    padding: 3em;
    margin: 2em;
}
.koukoku-ex-inner p {
    color: #ff848b;
}
.text-center {
    text-align: center;
}
.btn-inquiry {
    padding: 20px 15px;
    margin: 50px auto 50px;
    display: block;
    text-align: center;
    width: 300px;
    background-color: #ff7540;
    color: #ffffff;
    border-radius: 12px;
    vertical-align: middle;
    position: relative;
    font-size: 2rem;
}
.eg-pc {
    display: block;
}
.eg-sp {
    display: none;
}
/*お問い合わせ-------------------------------------------------------------------------------------------------------------*/
.inquiry-box {
    margin-bottom: 1em;
}
.inquiry-box p {
    padding-bottom: 0;
    margin-top: 1em;
    font-size: 0.8em;
}
#inquiry .inner {
    border: none;
}
#clinic-page .inner {
    border: none;
}
.inner-textarea {
    height: 15em;
}
input[type="submit"].btn-inquiry-pink,
input[type="button"].btn-inquiry-pink {
    font-size: 100%;
    padding: 20px 0 20px 0;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    width: 200px;
    background-color: #ff848b;
}
input[type="button"].btn-inquiry-gray,
input[type="submit"].btn-inquiry-gray {
    font-size: 100%;
    padding: 20px 0 20px 0;
    color: #fff;
    background: #7b7b7b;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    width: 200px;
}

/* 07医院検索結果 ----------------------------------------------------------------------------------------- */
.conditions-second-wrap {
    position: relative;
}
.conditions-second-wrap .article-like {
    right: 20px;
    top: 20px;
}
.conditions-second-wrap .list-conditions {
    top: 400px;
    left: 0;
}

/*検索結果---------------------------------------------------------------------------------------------------------------*/
#search-result .main-search {
    background-color: rgb(255 238 238 / 80%);
    margin: 3em 0;
}
.title-search-1 {
    margin: 2em 0 0;
    font-weight: 500;
    padding-left: 1em;
    border-left: 7px solid #ff848b;
}
.result {
    padding-left: 1em;
    color: #a3a1a1;
}
.search-box {
    position: relative;
    border: 1px solid #d6d6d6;
    padding: 1.5em;
}
.heart {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 20px;
    right: 30px;
}
.clinic-name {
    color: #5d5b5b;
    font-weight: 500;
    margin-bottom: 1.5em;
}
.btn-clinic-detail {
    padding: 10px 15px;
    margin: 150px auto 0;
    display: block;
    text-align: center;
    width: 150px;
    background-color: #ff7540;
    color: #ffffff;
    border-radius: 12px;
    font-size: 1.6rem;
    line-height: 1.5;
}
.clinic-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.clinic-info-inner {
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;
    width: 80%;
}
.clinic-info-inner img {
    max-width: 100%;
    max-height: 250px;
}
.clinic-info-inner table {
    width: 100%;
    margin-left: 5%;
}
.detail-btn {
    width: 20%;
    align-self: self-end;
}
.clinic-info-inner table td:first-child {
    width: 30%;
    vertical-align: baseline;
}
.clinic-info-inner table td:nth-child(2) {
    width: 70%;
    vertical-align: baseline;
}
.pagenavi {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 3%;
    margin-top: 5em;
    border: 1px solid #eaeaea;
}
.page-display {
    color: #707070;
}
.page-number span {
    padding: 5px 10px;
    margin: 0 5px;
    border: 1px solid #eaeaea;
    color: #707070;
}
.page-number span a {
    color: #707070;
}
.page-number span.current {
    background-color: #ff8a91;
    color: #fff;
}
.page-number span.current a {
    background-color: #ff8a91;
    color: #fff;
}
.next-10 {
    padding: 0px 10px;
    border: 1px solid #eaeaea;
    color: #707070;
}
/*検索結果(医院詳細)------------------------------------------------------------*/
#search-result-detail h4 {
    position: relative;
    font-size: 1.5em;
    font-weight: 500;
    color: #444343;
    margin-bottom: 5%;
}
#search-result-detail .heart {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0px;
    right: 0px;
}
.gallery {
    margin: 0 0 5px 0;
}
.gallery li {
    text-align: center;
    list-style: none;
}
#search-result-detail .slick-prev {
    left: calc(50% - 400px);
    height: 56px;
    background: url("../images/common/arrow-left2.png") center center no-repeat;
}
#search-result-detail .slick-next {
    right: calc(50% - 400px);
    width: 44px;
    height: 56px;
    background: url("../images/common/arrow-right2.png") center center no-repeat;
}
.choice-btn li {
    cursor: pointer;
    outline: none;
    /*background:#333;*/
    /*width:25%!important;*/
    list-style: none;
}
/*.choice-btn li img{
	   opacity: 0.4;/*選択されていないものは透過40%
   }*/
/*.choice-btn li.slick-current img{
	   opacity: 1;/*選択されているものは透過しない
   }*/
#search-result-detail .choice-btn .slick-track {
    width: 600px !important;
}
#search-result-detail .choice-btn .slick-slide {
    width: calc(100% / 3) !important;
}
.detail-info {
    margin-top: 5em;
    margin-bottom: 5em;
}
.detail-info table {
    width: 100%;
    border: 1px solid rgb(112 112 112 / 50%);
    border-collapse: collapse;
}
.detail-info tr {
    border: 1px solid rgb(112 112 112 / 50%);
}
.detail-info td:nth-child(1) {
    width: 25%;
    padding: 1em;
    background-color: #fdf5f5;
    text-align: center;
}
.detail-info td:nth-child(2) {
    padding: 1em;
    background-color: #fff;
}
.job-feature {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    list-style: none;
}
.job-feature li {
    width: 25%;
    margin: 1% 1%;
    font-size: 0.8em;
    color: #fff;
    background-color: #ff7540;
    text-align: center;
    border-radius: 5px;
}
.detail-info-2 {
    margin-top: 2em;
    margin-bottom: 10em;
}
.work-list {
    padding-left: 1.5em;
}
h3.essentials {
    font-size: 1em;
    font-weight: 500;
    padding: 5px 1em;
    background-color: #ff8a91;
    color: #fff;
}
/*
   .filter-orange {
	   background-color: #FFE4C5;
	   opacity: 0.98;
	   z-index: 3;
	   position: absolute;
	   width: 100%;
	   height: 100%;
   }*/
.filter-orange::after {
    content: "";
    width: 100%;
    height: 100%;
    background: #ffe4c5;
    opacity: 0.98;
    position: absolute;
    top: 0;
    left: 0;
}
.filter-box {
    position: relative;
    z-index: 1;
}
.filter-text {
    position: absolute;
    width: 100%;
    top: 30%;
    text-align: center;
    z-index: 3;
}
.filter-orange .btn-orange {
    position: absolute;
    z-index: 3;
    width: 200px;
    top: 43%;
    left: calc(50% - 100px);
}
/*08医院検索詳細網掛け-----------------------------------------*/
.article-like {
    color: #858181;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: -10px;
    text-align: center;
    line-height: 1.2;
    cursor: pointer;
}
.article-like::before {
    content: "";
    background-image: url(../images/common/heart.png);
    background-position: 50%;
    background-repeat: no-repeat;
    display: block;
    width: 100%;
    height: 25px;
}
.article-like.is-liked::before {
    background-image: url(../images/common/heart2.png);
}
/* 11本登録 ----------------------------------------------------------------------------------------- */
.step-flow.flow-pink .step-flow-inner {
    width: 20%;
}
.step-flow.flow-pink .step-flow-inner.thisnow {
    color: #ffffff;
    background-color: #ff8a91;
    border: solid 1px #ff8a91;
}
.step-flow.flow-pink .step-flow-inner:after {
    position: absolute;
    content: "";
    border-top: 1px solid #707070;
    width: 33%;
    height: 1px;
    z-index: -1;
    top: 50.09%;
    right: -33%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}
.terms {
    max-width: 700px;
    display: block;
    margin: 0 auto 50px;
}
.icon-tems {
    background: url(../images/common/icon-book2.png) left center no-repeat;
    background-size: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 35px;
}
.terms-content {
    background-color: #ffffff;
    border-top: solid 1px #707070;
    border-left: solid 1px #707070;
    border-right: solid 1px #707070;
    border-bottom: solid 1px #707070;
    border-radius: 6px;
    padding: 15px;
}
.terms-content-inner {
    height: 400px;
    overflow-y: scroll;
    padding: 0 10px 0 0;
}
.completion {
    max-width: 700px;
    background: #fff;
    margin: 0 auto 10% auto;
    padding: 50px 10px;
    box-sizing: border-box;
}
.btt3 {
    font-size: 100%;
    padding: 20px;
    color: #fff;
    background: #ff8a91;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    display: inline-block;
    width: 220px;
    margin: 0 10px;
}
.completion h1.bottom20 {
    margin-bottom: 20px;
}
.checkbox-3 {
    display: inline-block;
}

/* 12本登録エントリーシート記入 ----------------------------------------------------------------------------------------- */
.btt4 {
    font-size: 100%;
    padding: 20px;
    color: #fff;
    background: #ff7540;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    display: inline-block;
    width: 220px;
    margin: 0 10px;
}

/* 【医院掲載登録とマイページ】 ----------------------------------------------------------------------------------------- */
/* 掲載医院ログイン・会員登録21 ----------------------------------------------------------------------------------------- */
.loginform {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    border: solid 2px #dae9dc;
    border-radius: 6px;
    padding: 50px;
    box-sizing: border-box;
}
/* エントリーシート登録23 ----------------------------------------------------------------------------------------- */
.step-flow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 90px;
}
.step-flow-inner {
    color: #818181;
    width: 28%;
    border: solid 1px #707070;
    border-radius: 6px;
    text-align: center;
    padding: 20px 0;
    position: relative;
    line-height: 1.5;
}
.step-flow-inner.thisnow {
    color: #ffffff;
    background-color: #7db182;
    border: solid 1px #7db182;
}
.step-flow-inner:after {
    position: absolute;
    content: "";
    border-top: 1px solid #707070;
    width: 28%;
    height: 1px;
    z-index: -1;
    top: 50.09%;
    right: -28%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}
/*.step-flow-inner:nth-child(3):after{
	   display: none;
   }*/
.step-flow-inner:last-child:after {
    display: none;
}
.step-flow-inner:nth-child(4):after {
    display: none;
}
.step-flow-three .step-flow-inner:nth-child(3):after {
    display: none;
}
.step-flow-three .step-flow-inner {
    margin-right: 6.5%;
}
table p label {
    border: none;
}
/* 25応募者一覧 ----------------------------------------------------------------------------------------- */
.list-applicants {
    height: 500px;
    overflow-y: scroll;
}
.list-applicants-inner {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #cccccc;
    width: 100%;
}
.applicants-name {
    padding: 10px 10px 10px 60px;
    color: #737373;
}
.applicants-name.newname {
    color: #000000;
}
.applicants-name span {
    margin-left: 20px;
    color: #ff0004;
}
.applicants-day {
    padding: 10px 60px 10px 10px;
}
#talksearch-wrap {
    background-color: #e8e8e8;
    padding: 10px;
}
#talksearch-wrap input {
    border: none;
}
#talksearch-wrap input {
    background-image: url(../images/common/icon-search2.png);
    background-position: 50px center;
    background-repeat: no-repeat;
    background-size: 25px;
    z-index: 1;
    padding-left: 80px;
}
/* 26連絡用チャット ----------------------------------------------------------------------------------------- */
.talkwrapper {
    display: flex;
}
.wrap1 {
    width: 40%;
}
.wrap2 {
    width: 60%;
    position: relative;
}
/* トークリスト ----------------------------------------------------------------------------------------- */
.tk-search {
    background: #e8e8e8;
    padding: 12px;
}
.tk-search form {
    text-align: center;
}
.tk-search form input {
    font-family: FontAwesome;
    width: 100%;
    height: 39px;
    border: none;
    background-image: url(../images/common/icon-search2.png);
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: 20px;
    padding-left: 50px;
}
.tk-search form input::placeholder {
    color: #b9b9b9;
}
.tk-list {
    overflow-y: scroll;
    /*height: 575px;*/
    height: calc(70vh + 110px);
}
.tk-list::-webkit-scrollbar {
    width: 10px;
    _height: 1000px;
}
.tk-list::-webkit-scrollbar-thumb {
    background-color: #b9b9b9;
    height: 10px;
}
.select {
    background-color: #e5e5e5;
    border-top: 1px solid #fff;
}
.tk-one {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #a1a1a1;
    align-items: flex-start;
    max-height: 100px;
}
.tk-one:last-child {
    border-bottom: none;
}
.tk-one:first-child {
    border-bottom: 1px solid #a1a1a1;
}
.tk-one .tk-one-l {
    display: flex;
    /*align-items: end;*/
    align-items: flex-start;
}
.tk-one .tk-one-l img {
    margin-top: 6px;
}
.tk-one .tk-one-l .per-name {
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 20px;
}
.per-name .tk-li-name,
.per-name .excerpt {
    padding-bottom: 0;
    line-height: 1.5em;
    padding-bottom: 0;
    font-size: 1.1em;
}
.per-name .excerpt {
    font-size: 0.8em;
    color: #b9b9b9;
}
.tk-one .tk-one-r .minute {
    color: #b9b9b9;
    padding-bottom: 0;
    white-space: nowrap;
}
.tk-one .tk-one-r .notice {
    background-color: #7db182;
    color: #fff;
    border-radius: 66px;
    padding: 3px 14px;
    position: absolute;
    right: 15px;
}
.blue-head .tk-one .tk-one-r .notice {
    background-color: #8bc3d8;
}
.tk-one .tk-one-r {
    text-align: center;
    position: relative;
}
/* トーク画面 ----------------------------------------------------------------------------------------- */
.ch-top-ic {
    padding: 9px 0px 9px 30px;
    display: flex;
    background: #e5e5e5;
    align-items: center;
    font-size: 1.2em;
}
.ch-top-ic img {
    width: 45px;
}
.ch-name {
    padding-left: 20px;
    padding-bottom: 0;
}
.line__left .line__left-text .text.dental {
    background-color: #8bc3d8;
}
.line__container .line__left .text.dental::after {
    color: #8bc3d8;
}
.line__left .line__left-text .text.partner {
    background-color: #fdeeef;
}
.line__container .line__left .text.partner::after {
    border-right: 20px solid #fdeeef;
}
.line__right .text.self {
    background-color: #7db182;
}
.line__container .line__right .text.self::after {
    border-left: 20px solid #7db182;
}
.line__right.bk-line-blue .text.self {
    background-color: #c5e1eb;
}
.line__container .line__right.bk-line-blue .text.self::after {
    border-left: 20px solid #c5e1eb;
}
.txt-input {
    display: flex;
    width: 98%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    background: #eee;
    padding: 1%;
}
.txt-input .txt-holder {
    width: 93%;
}
.txt-input .txt-submit {
    width: 8%;
}
.txt-input .txt-holder input {
    border: none;
}
.txt-input .txt-submit input {
    color: #b9b9b9;
    background: #eee;
    padding: 21.15px;
    border-radius: 0;
    width: 100%;
    border-bottom: none;
}
/*/////////////////////////////////////////////////
   //LINE風チャット画面(会話方式)を記事に表示する方法
   /////////////////////////////////////////////////*/
.line__container {
    padding: 0;
    background: #fff;
    overflow: hidden;
    /* max-width: 400px;/*_210918　濱野変更*/
    /* margin: 20px auto; */
    /*_210918　濱野変更*/
    font-size: 80%;
}
/* タイトル部分 */
.line__container .line__title {
    background: #273246;
    padding: 10px;
    text-align: center;
    font-size: 150%;
    color: #ffffff;
}
/* 会話部分 */
.line__container .line__contents {
    padding: 10px;
    overflow: hidden;
    line-height: 135%;
    position: relative;
}
.line__container .scroll {
    /*height: 500px;*/
    height: 70vh;
    overflow-y: scroll;
    padding-bottom: 100px;
}
/*_210918　濱野変更 from*/
.line__container .scroll::-webkit-scrollbar-thumb {
    background-color: #b9b9b9;
    height: 10px;
}
.line__container .scroll::-webkit-scrollbar {
    width: 10px;
}
/*_210918　濱野変更 here*/
/* スタンプ画像最大幅 */
.line__container .stamp img {
    max-width: 150px;
}
/* 相手の会話 */
.line__container .line__left {
    width: 100%;
    position: relative;
    display: block;
    margin-bottom: 5px;
    max-width: 80%;
    clear: both;
}
/* アイコン画像 */
.line__container .line__left figure {
    width: 50px;
    position: absolute;
    top: 15px;
    left: 0;
    padding: 0;
    margin: 0;
}
/* 正方形を用意 */
.line__container .line__left figure img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
.line__container .line__left .line__left-text {
    margin-left: 10px;
}
.line__container .line__left .line__left-text .name {
    font-size: 80%;
    color: #8f8f8f;
    /*_210918　濱野変更*/
}
/* コメントエリア */
.line__container .line__left .text {
    margin: 0;
    position: relative;
    padding: 20px;
    border-radius: 20px;
    background-color: #8bc3d8;
}
/* 吹き出し */
.line__container .line__left .text::after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    left: -10px;
    top: 10px;
    border-right: 20px solid #8bc3d8;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
/* 自分の会話 */
.line__container .line__right {
    position: relative;
    display: block;
    margin: 5px 0;
    max-width: 75%;
    float: right;
    margin-right: 15px;
    clear: both;
}
/* コメントエリア */
.line__container .line__right .text {
    padding: 20px;
    border-radius: 20px;
    margin: 0;
    margin-left: 80px;
}
/* 吹き出し */
.line__container .line__right .text::after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    right: -10px;
    top: 10px;
    border-left: 20px solid #8de055;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
/* 自分がスタンプを送る時 */
.line__container .line__right .stamp {
    position: relative;
    margin-left: 80px;
}
/* 既読エリア */
.line__container .line__right .date {
    content: "";
    position: absolute;
    display: block;
    width: 100px;
    text-align: right;
    left: -30px;
    bottom: 0px;
    font-size: 80%;
    color: #818181;
}
.line__container .line__left .date {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    text-align: left;
    right: -50px;
    bottom: 0px;
    font-size: 80%;
    color: #818181;
}
.line__center__days {
    width: max-content;
    margin: 40px auto;
}
.line__center__days .attention {
    background: #e8e8e8;
    padding: 1em 3em;
    border-radius: 30px;
}
.line__attention {
    position: absolute;
    bottom: 8.75em;
    left: 0;
    margin-top: auto;
    background: #fff;
    width: calc(100% - 10px);
    padding: 1.5em 0 1.5em 0.5em;
    z-index: 0;
    line-height: 140%;
    margin-left: 1em;
    text-indent: -1em;
    font-size: 0.9rem;
}
/*28よくある質問-----------------------------------------------------------------------------------------*/
#clinic-page h1.title1:after {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    content: "";
    display: block;
    background-color: rgb(125 177 130 / 50%);
    height: 8px;
    width: 78px;
}
#clinic-page h2.title3::after {
    content: "";
    position: absolute;
    background-color: rgb(125 177 130 / 50%);
    width: 65%;
    height: 1px;
    top: 50%;
    right: 0;
    display: block;
}
#clinic-page .btn-pink3 {
    padding: 1%;
    display: block;
    width: 29%;
    margin-bottom: 3%;
    text-align: center;
    background-color: #46814b;
    color: #ffffff;
    border-radius: 0px;
    vertical-align: middle;
    position: relative;
    font-size: 1.6rem;
}
#clinic-page .title-qa-2 {
    margin: 2em 0;
    font-weight: 500;
    padding-left: 2em;
    border-left: 7px solid #46814b;
}
#clinic-page .qa-list a {
    color: #7db182;
}
#clinic-page dl.toggle dt::after {
    position: absolute;
    top: 40px;
    right: 34px;
    width: 10px;
    height: 10px;
    border-top: 2px solid #7db182;
    border-right: 2px solid #7db182;
    -webkit-transform: rotate(45deg);
    transform: rotate(135deg);
}
#clinic-page dl.toggle dt.active::after {
    position: absolute;
    top: 34px;
    right: 34px;
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #7db182;
    border-right: 2px solid #7db182;
    transform: translateX(-50%);
    transform: rotate(-45deg);
}
/*プレミアム求人29----------------------------------------------------------------------------*/
#clinic-page .koukoku-text-box {
    padding: 0 2em 1em;
    border-bottom: 1px solid rgb(125 177 130 / 60%);
}
#clinic-page input[type="submit"].btn-inquiry-pink,
input[type="button"].btn-inquiry-pink {
    font-size: 100%;
    padding: 20px 0 20px 0;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    width: 200px;
    background-color: #ff848b;
}
/*/////////////////////////////////////////////////
   //医院掲載登録
   /////////////////////////////////////////////////*/
/* ヘッダーリンクボタン -------------------------------------------------------------- */
.green-head .h-btn div a {
    background-color: #7db182;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center 15px;
    width: 100px;
    height: 34px;
    text-align: center;
    padding: 50px 0 3px 0;
    line-height: 1.2;
    font-size: 1.4rem;
    letter-spacing: 0.01px;
    border-right: solid 1px #afcfb2;
}
.green-head .h-btn div:last-child a {
    border-right: none;
}
.h-btn div.applicant-info a {
    background-image: url("../images/common/icon-login.png");
}
.h-btn div.scout-chat a {
    background-image: url("../images/common/icon-mail.png");
}
.h-btn div.contact-chat a {
    background-image: url("../images/common/icon-mail.png");
}
.h-btn div.content-edit a {
    background-image: url("../images/common/icon-book.png");
}
.h-btn div.qa-btn a {
    background-image: url("../images/common/icon-qa.png");
}
.h-btn div.premium-jobs a {
    background-image: url("../images/common/icon-login.png");
}
.h-btn div.basic-info a {
    background-image: url("../images/common/icon-configuration.png");
}
.h-btn div.logout-btn a {
    background-image: url("../images/common/icon-logout.png");
}

/*/////////////////////////////////////////////////
   //歯科衛生士登録
   /////////////////////////////////////////////////*/
/* ヘッダーリンクボタン -------------------------------------------------------------- */
.orange-head .h-btn div a {
    background-color: #ffab6d;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center 15px;
    width: 100px;
    height: 34px;
    text-align: center;
    padding: 50px 0 3px 0;
    line-height: 1.2;
    font-size: 1.4rem;
    letter-spacing: 0.01px;
    border-right: solid 1px #ffffff;
}
.orange-head .h-btn div:last-child a {
    border-right: none;
}
.orange-head .h-btn div.application-chat a {
    background-image: url("../images/common/icon-mail.png");
}
.orange-head .h-btn div.contact-chat a {
    background-image: url("../images/common/icon-mail.png");
}
.orange-head .h-btn div.favorite a {
    background-image: url("../images/common/icon-heart.png");
}
.orange-head .h-btn div.entry-sheet a {
    background-image: url("../images/common/icon-document.png");
}
.orange-head .h-btn div.basic-info a {
    background-image: url("../images/common/icon-configuration.png");
}
.orange-head .h-btn div.logout-btn a {
    background-image: url("../images/common/icon-logout.png");
}

/* ナビゲーション -------------------------------------------------------------- */
#dropmenu > li:hover > a,
#dropmenu > li > a.active {
    color: #ffffff;
}
.green-head #dropmenu li:first-child a {
    background-image: url("../images/common/icon-login.png");
}
.green-head #dropmenu li:nth-child(2) a {
    background-image: url("../images/common/icon-mail.png");
}
.green-head #dropmenu li:nth-child(3) a {
    background-image: url("../images/common/icon-mail.png");
}
.green-head #dropmenu li:nth-child(4) a {
    background-image: url("../images/common/icon-book.png");
}
.green-head #dropmenu li:nth-child(5) a {
    background-image: url("../images/common/icon-qa.png");
}
.green-head #dropmenu li:nth-child(6) a {
    background-image: url("../images/common/icon-login.png");
}
.green-head #dropmenu li:nth-child(7) a {
    background-image: url("../images/common/icon-configuration.png");
}
.green-head #dropmenu li:nth-child(8) a {
    background-image: url("../images/common/icon-logout.png");
}
/* 16連絡用チャット --------------------------------------------------------------------------------------*/
.ch-report {
    background-color: #959595;
    border-radius: 6px;
    color: #ffffff;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 6px;
    margin-right: 10px;
}
.ch-report .ch-report-icon {
    width: 15px;
    padding-right: 5px;
}
.ch-report {
    position: absolute;
    right: 15px;
    margin-right: 0px;
}
/* 21掲載医院ログイン・会員登録 ----------------------------------------------------------------------------------------- */
.loginform {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    border: solid 2px #dae9dc;
    border-radius: 6px;
    padding: 50px;
    box-sizing: border-box;
}
/* 22基本情報登録 -------------------------------------------------------------- */
.registration {
    max-width: 700px;
    margin: 0 auto 10% auto;
    border: solid 2px #dae9dc;
    border-radius: 6px;
    padding: 50px;
    box-sizing: border-box;
}
.btt {
    font-size: 100%;
    padding: 20px;
    color: #fff;
    background: #7db182;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    display: inline-block;
    width: 220px;
    margin: 0 10px;
}
/* 25応募者一覧 -------------------------------------------------------------- */
#talksearch-wrap {
    background-color: #e8e8e8;
    padding: 10px;
}
#talksearch-wrap input {
    border: none;
}
#talksearch-wrap input {
    background-image: url(../images/common/icon-search2.png);
    background-position: 50px center;
    background-repeat: no-repeat;
    background-size: 25px;
    z-index: 1;
    padding-left: 80px;
}
/*/////////////////////////////////////////////////
   //歯科衛生士登録とマイページ
   /////////////////////////////////////////////////*/
/*14いいねした医院------------------------------------------------------------------------------------*/
.good-inner {
    margin: 5em 0;
}
#good input[type="checkbox"] {
    display: inline;
}
#good .search-box {
    position: relative;
    border: 2px solid #d6d6d6;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    border-collapse: collapse;
    padding: 1.5em;
}
#good .wrap .search-box:last-child {
    border-bottom: 2px solid #d6d6d6;
}
#good .btn-clinic-detail {
    padding: 20px 15px;
    margin: 150px auto 0;
    display: block;
    text-align: center;
    width: 150px;
    background-color: #ff7540;
    color: #ffffff;
    border-radius: 12px;
    font-size: 1.6rem;
    line-height: 1.5;
}
#good .btn-clinic-detail-2 {
    padding: 25px 15px;
    margin: 0px auto 0;
    display: block;
    text-align: center;
    width: 200px;
    background-color: #ff7540;
    color: #ffffff;
    border-radius: 12px;
    font-size: 1.5rem;
    line-height: 1.5;
}
/* 元々のチェックボックス（非表示） */
#good input[type="checkbox"] {
    display: none;
}
/* チェックボックスの代わりを成すラベル */
#good input[type="checkbox"] + label {
    display: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding-left: 25px;
    padding-right: 10px;
}
/* ラベルの左に表示させる正方形のボックス□ */
#good input[type="checkbox"] + label::before {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    left: 0;
    top: 50%;
    border: 1px solid;
    border-color: #585753; /* 枠の色変更 お好きな色を */
    background-color: #fff; /* 背景の色変更 お好きな色を */
}
/* チェックが入った時のレ点 */
#good input[type="checkbox"]:checked + label::after {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 18px;
    height: 9px;
    margin-top: -9px;
    top: 50%;
    left: 3px;
    transform: rotate(-45deg);
    border-bottom: 3px solid;
    border-left: 3px solid;
    border-color: #585753; /* チェックの色変更 お好きな色を */
}
#good input[type="checkbox"] + label {
    border: 0;
    box-sizing: border-box;
}
#good input[type="checkbox"]:checked + label {
    border: 0;
    color: #3d3d3d;
}
#good h2 {
    position: relative;
    text-align: center;
    margin-top: 5em;
    margin-bottom: 100px;
    font-size: 3rem;
    font-weight: 500;
}
#good h2:after {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    content: "";
    display: block;
    background-color: rgb(255 117 64 / 50%);
    height: 8px;
    width: 78px;
}
#good .article-like {
    color: #858181;
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 15px;
    text-align: center;
    line-height: 1.2;
    cursor: pointer;
}
/*15医院詳細情報------------------------------------------------------------------------*/
.method-list {
    list-style: none;
}
.flex {
    display: flex;
    flex-wrap: wrap;
}
.flex-sp {
    display: block;
    flex-wrap: wrap;
}
.clinic-message {
    margin-bottom: 5em;
}
.clinic-message-box {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3em;
    border: 5px solid rgb(249 247 247);
    border-radius: 16px;
}
.clinic-message-box img {
    width: 42%;
    text-align: center;
    max-width: 350px;
    max-height: 270px;
}
.clinic-message-text {
    width: 54%;
    padding: 2%;
}
#search-result-detail .clinic-message h4 {
    position: relative;
    font-size: 1.2em;
    font-weight: 500;
    color: #f1a6a6;
    margin-bottom: 1%;
}
.clinic-detail-info .btn-clinic-detail-2 {
    padding: 25px 15px;
    margin: 0px auto 0;
    display: block;
    text-align: center;
    width: 200px;
    background-color: #ff7540;
    color: #ffffff;
    border-radius: 12px;
    font-size: 1.5rem;
    line-height: 1.5;
}
/* 13歯科衛生士ログイン -----------------------------------------------------------------------------------------*/
.mypagelogin h1 {
    background-color: #ff7540;
    padding: 15px 0 20px;
    color: #ffffff;
    margin: 0;
}
.registration.mypagelogin {
    border-top: none;
    border-right: solid 13px #ff7540;
    border-bottom: solid 13px #ff7540;
    border-left: solid 13px #ff7540;
    padding: 0;
    border-radius: 6px;
}
.registration-inner {
    padding: 10%;
}
.mypagelogin .den-login input[type="text"],
.mypagelogin .loginform input[type="password"],
.mypagelogin .registration input[type="text"],
.mypagelogin .registration input[type="password"] {
    background-color: rgba(238, 238, 238, 0.64);
}
.den-login {
    font-size: 100%;
    padding: 20px;
    _color: #fff;
    background: #ff7540;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    display: inline-block;
    width: 220px;
}
#registration-fin {
    max-width: 850px;
    border: solid 5px rgba(255, 117, 64, 0.64);
    padding: 0;
}
#registration-fin .registration-inner {
    width: 70%;
    margin: 0 auto;
}
table.table-entry {
    margin-bottom: 30px;
    border-collapse: collapse;
    width: 100%;
    border-top: solid 1px #d3d3d3;
}
.table-entry th,
.table-entry td {
    padding: 30px;
    border-bottom: solid 1px #d3d3d3;
}
.table-entry th {
    background: #eeeeee;
    text-align: left;
}
.table-entry td {
    background: #fff;
    text-align: left;
    padding: 30px;
}
table.table-entry table,
table.table-entry table th,
table.table-entry table td {
    border: none;
}
/*/////////////////////////////////////////////////
   //運営者管理ページTop
   /////////////////////////////////////////////////*/
/* ヘッダーリンクボタン -------------------------------------------------------------- */
.blue-head .h-btn div a {
    background-color: #8bc3d8;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center 15px;
    width: 100px;
    // height: 34px;
    text-align: center;
    padding: 50px 0 3px 0;
    line-height: 1.2;
    font-size: 1.4rem;
    letter-spacing: 0.01px;
    border-right: solid 1px #a6d1e1;
}
.blue-head .h-btn div:last-child a {
    border-right: none;
}
.h-btn div.applicant2-info a {
    background-color: #79a3b2;
    background-image: url("../images/common/icon-login.png");
}
.h-btn div.content2-edit a {
    background-image: url("../images/common/icon-book.png");
}
.h-btn div.contact2-chat a {
    background-image: url("../images/common/icon-mail.png");
}
.h-btn div.logout-btn a {
    background-image: url("../images/common/icon-logout.png");
}
/* ナビゲーション -------------------------------------------------------------- */
#dropmenu > li:hover > a,
#dropmenu > li > a.active {
    color: #ffffff;
}
.blue-head #dropmenu li:first-child a {
    background-image: url("../images/common/icon-login.png");
}
.blue-head #dropmenu li:nth-child(2) a {
    background-image: url("../images/common/icon-book.png");
}
.blue-head #dropmenu li:nth-child(3) a {
    background-image: url("../images/common/icon-mail.png");
}
.blue-head #dropmenu li:nth-child(4) a {
    background-image: url("../images/common/icon-logout.png");
}
/* 見出し -------------------------------------------------------------- */
h1.title5 {
    font-size: 2rem;
    font-weight: 400;
    background-color: #a7a7a7;
    color: #ffffff;
    padding: 10px 10px 10px 60px;
}
h1.title6 {
    font-size: 2rem;
    font-weight: 400;
    background-color: #ff7540;
    color: #ffffff;
    padding: 10px 10px 10px 60px;
}
h1.title7 {
    font-size: 2rem;
    font-weight: 400;
    background-color: #7db182;
    color: #ffffff;
    padding: 10px 10px 10px 60px;
}
/* 矢印 -------------------------------------------------------------- */
.aplc-d-arrow-l {
    background: url("../images/common/arrow-left3.png") center center no-repeat;
    padding: 1em;
}
.aplc-d-arrow-r {
    background: url("../images/common/arrow-right3.png") center center no-repeat;
    padding: 1em;
}
/* 25応募者一覧 -------------------------------------------------------------- */
#talksearch-wrap {
    background-color: #e8e8e8;
    padding: 10px;
}
#talksearch-wrap input {
    border: none;
}
#talksearch-wrap input {
    background-image: url(../images/common/icon-search2.png);
    background-position: 50px center;
    background-repeat: no-repeat;
    background-size: 25px;
    z-index: 1;
    padding-left: 80px;
}
/* 囲み -------------------------------------------------------------- */
.wrap3 {
    padding: 60px;
}
.wrap3.gray {
    background: #f8f8f8;
}
.wrap4 {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.wrap4 .wrap4-l {
    width: calc(100% - 480px);
    padding: 40px;
}
.wrap4 .wrap4-l.gray {
    background: #f8f8f8;
}
.wrap4 .wrap4-r {
    width: 400px;
    padding: 40px;
}
.wrap4 .wrap4-r.bk-rightside {
    background: #eeeeee;
}
.wrap5 {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.wrap5 .wrap5-l {
    width: 730px;
}
.wrap5 .wrap5-l.gray,
.wrap5 .wrap5-r.gray {
    background: #f8f8f8;
}
.wrap5 .wrap5-r {
    width: calc(100% - 800px);
    padding: 40px;
}
.wrap-white {
    background: white;
    padding: 30px;
    margin-bottom: 30px;
}
/* 25応募者一覧 -------------------------------------------*/
.table-pplicant {
    position: relative;
}
.table-pplicant-img {
    position: absolute;
    width: 20%;
    top: 0;
    right: 0;
}
.table-pplicant-img img {
    width: 100%;
    height: auto;
}
/* 33応募者一覧用table -------------------------------------------------------------- */
table.table3 {
    margin-bottom: 30px;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    border-top: solid 1px #cecece;
    border-right: solid 1px #cecece;
}
.table3 th,
.table3 td {
    padding: 30px;
    border-left: solid 1px #cecece;
    border-bottom: solid 1px #cecece;
    word-break: break-all;
}
.table3 thead tr {
    background: #f8f8f8;
}
.table3 td {
    text-align: left;
    background: white;
    line-height: 160%;
}
table.table4 {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    border-top: solid 1px #cecece;
    border-right: solid 1px #cecece;
    margin-bottom: 20px;
}
.table4 th,
.table4 td {
    padding: 20px 30px;
    border-left: solid 1px #cecece;
    border-bottom: solid 1px #cecece;
}
.table4 th {
    background: #eeeeee;
    text-align: left;
    width: 30%;
}
.table4 td {
    text-align: left;
    background: white;
    line-height: 160%;
}
.table4 td p:last-child {
    padding-bottom: 0;
}
.scroll-y {
    height: 80vh;
    overflow-y: scroll;
}
.scroll-y::-webkit-scrollbar {
    width: 0;
}
/* Tableのソート ----------------------------------------------------------------------------------------- */
.tablesorter-headerUnSorted {
    background-image: url("../images/common/black-unsorted.gif");
    background-repeat: no-repeat;
    background-position: 90% center;
}
.tablesorter-headerAsc {
    background-image: url("../images/common/black-asc.gif");
    background-repeat: no-repeat;
    background-position: 90% center;
    border-bottom: #000 2px solid;
}
.tablesorter-headerDesc {
    background-image: url("../images/common/black-desc.gif");
    background-repeat: no-repeat;
    background-position: 90% center;
    border-bottom: #000 2px solid;
}
.sorter-false {
    background-image: none;
}
/* 31連絡用チャットテンプレ ----------------------------------------------------------------------------------------- */
.blue-head .txt-input {
    display: flex;
    flex-wrap: nowrap;
    width: 98%;
    padding: 1%;
}
.blue-head .txt-input .txt-holder {
    width: 100%;
}
.blue-head .txt-input .btn-template {
    width: 8%;
}
.line__container .line__contents .popup_wrap .popup_template-ttl {
    text-align: center;
    margin-bottom: 1.4em;
    font-weight: 700;
    font-size: 1.8rem;
}
.line__container .line__contents .popup_wrap input.btn_popup_template1 {
    background: #fff;
    width: 100%;
    margin-bottom: 20px;
    color: #222;
    display: block;
}
.line__container .line__contents .popup_wrap input.btn_popup_template2 {
    background: #dbdbdb;
    width: 100%;
    margin-bottom: 20px;
    color: #222;
    display: block;
}
.line__container .line__contents .popup_template .btn_popup_template1:hover,
.line__container .line__contents .popup_template .btn_popup_template2:hover {
    opacity: 0.8;
}
.btn-template label {
    background: url("../images/common/icon-template.png") center center
        no-repeat;
    background-size: 22px;
}
/*ポップアップ*/
//    .popup_wrap input {
// 	display: none;
//    }
//    .popup_overlay {
// 	display: flex;
// 	justify-content: center;
// 	overflow: auto;
// 	position: fixed;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	z-index: 9999;
// 	width: 100%;
// 	height: 100%;
// 	background: rgba(0, 0, 0, 0);
// 	opacity: 1;
// 	// opacity: 0;
// 	transition: opacity 0.5s, transform 0s 0.5s;
// 	transform: scale(1);
//    }
.popup_trigger {
    position: absolute;
    width: 100%;
    height: 100%;
}
.popup_content {
    position: relative;
    align-self: center;
    width: 90%;
    max-width: 800px;
    padding: 30px 30px 15px;
    box-sizing: border-box;
    background: rgba(255, 221, 120, 0.9);
    line-height: 1.4em;
    transition: 0.5s;
    border-radius: 10px;
}
.close_btn {
    position: absolute;
    top: 14px;
    right: 16px;
    font-size: 30px;
    cursor: pointer;
}
//    .popup_wrap input:checked ~ .popup_overlay {
// 	opacity: 1;
// 	transform: scale(1);
// 	transition: opacity 0.5s;
//    }
.open_btn {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 27.15px;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease;
}
/* 33応募者一覧 ----------------------------------------------------------------------------------------- */
.aplc-date {
    display: inline-block;
    text-align: center;
    line-height: 140%;
    width: 100%;
}
.aplc-new .aplc-date::before {
    content: "NEW!";
    display: block;
    color: #ff0000;
    margin-bottom: 0.25em;
}
.aplc-status {
    display: inline-table;
    padding: 0.45em 0.5em 0.25em;
    width: 100%;
    background: #818181;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    line-height: 200%;
}
.aplc-status.active {
    background: #ff8a91;
    color: #fff;
}
.aplc-status:first-child,
table input[type="button"].aplc-status:first-child {
    margin-bottom: 1em;
}
.aplc-status2 {
    display: inline-table;
    padding: 0.45em 0.5em 0.25em;
    width: 100%;
    background: #818181;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    line-height: 200%;
}
.aplc-status2.active {
    background: #ff8a91;
    color: #fff;
}
.aplc-status2:first-child,
table input[type="button"].aplc-status2:first-child {
    margin-bottom: 1em;
}
a.btn-blue,
input[type="button"].btn-blue {
    text-align: center;
    background: #8bc3d8;
    display: block;
    color: #fff;
    border-radius: 6px;
    line-height: 140%;
    padding: 0.5em 1em;
    /*white-space: nowrap;*/
}
a.btn-darkblue,
input[type="button"].btn-darkblue {
    text-align: center;
    background: #79a3b2;
    display: block;
    color: #fff;
    border-radius: 6px;
    line-height: 140%;
    padding: 0.5em 1em;
    /*white-space: nowrap;*/
}
a.btn-white,
input[type="button"].btn-white {
    width: auto;
    text-align: center;
    background: #fff;
    display: block;
    color: #8bc3d8;
    border-radius: 6px;
    line-height: 140%;
    padding: 0.5em 1.5em;
    border: 1px solid #8bc3d8;
}
a.btn-dark,
input[type="button"].btn-dark {
    width: auto;
    text-align: center;
    background: #959595;
    display: block;
    color: #fff;
    border-radius: 6px;
    line-height: 140%;
    padding: 0.5em 1.5em;
}
a.btn-whit:hover,
input[type="button"].btn-white:hover {
    color: #d1e7f0;
    border: 1px solid #d1e7f0;
}
table a.btn-blue {
    margin-bottom: 1em;
}
.aplc-furi {
    color: #8f8f8f;
    display: block;
    margin-bottom: 0.25em;
}
.aplc-name {
    display: block;
    margin-bottom: 0.5em;
}
.aplc-name a {
    color: #000000;
    text-decoration: underline;
}
.aplc-new .aplc-name a {
    color: #ff8a91;
}
.aplc-profile {
    color: #8f8f8f;
    display: block;
}
.wrap-memo {
    display: block;
    width: 100%;
}
.link-memo {
    margin: auto 0 0;
    font-size: 1.2rem;
}
.link-memo a {
    text-decoration: underline;
}
input[type="button"].aplc-delete {
    background: #79a3b2;
    border-radius: 6px;
    width: auto;
    padding: 0.5em 1em;
    margin: 0 auto;
    display: block;
}
input[type="button"].aplc-delete:hover,
input[type="button"].aplc-status:hover,
input[type="button"].aplc-status2:hover {
    opacity: 0.8;
}
input[type="button"].aplc-status,
input[type="button"].aplc-status2 {
    display: inline-table;
    padding: 0.25em 0.5em 0.25em;
    width: 100%;
    background: #818181;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    line-height: 200%;
}
input[type="button"].aplc-status.active {
    background: #ff8a91;
    color: #fff;
}
input[type="button"].aplc-status2.active {
    background: #7db182;
    color: #fff;
}
input[type="button"].aplc-status:first-child,
input[type="button"].aplc-status2:first-child {
    margin-bottom: 0;
}
/* 34応募者一覧詳細 ----------------------------------------------------------------------------------------- */
.aplc-d-top {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.aplc-d-top-l {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.aplc-d-top-l .aplc-d-date {
    margin-left: 2em;
    color: #737373;
}
.btn-download a {
    background: url("../images/common/icon-download.png") center right no-repeat;
    padding-right: 2em;
}
.wrap-aplc-status {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.wrap-aplc-status .wrap-aplc-status-inner {
    width: 46%;
}
.wrap-aplc-pagenav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.wrap-aplc-pagenav .wrap-aplc-pagenav-inner {
    display: flex;
}
.wrap-aplc-pagenav2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.wrap-aplc-pagenav2 .wrap-aplc-pagenav-inner {
    display: flex;
    padding: 0 20px;
}
.wrap-aplc-pagenav2 .wrap-aplc-pagenav2-inner {
    display: flex;
    justify-content: flex-end;
}
.wrap-aplcstatus {
    margin-top: 40px;
}
.wrap-btnstatus {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 40px;
}
.wrap-btnstatus .wrap-btnstatus-inner {
    width: 46%;
    margin-bottom: 20px;
}
.wrap-btnstatus .wrap-btnstatus-inner:last-child {
    width: 100%;
}
.aplc-d-name {
}
.wrap-name {
    background: white;
    padding: 30px;
    margin-bottom: 30px;
}
/* 36掲載医院一覧詳細 ----------------------------------------------------------------------------------------- */
.wrap-pic-aplc {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.wrap-pic-aplc .wrap-pic-aplc-inner {
    width: 48%;
    margin-bottom: 4%;
}
.wrap-pic-aplc .wrap-pic-aplc-inner img {
    padding: 1%;
}
/* 31連絡用チャットテンプレ ----------------------------------------------------------------------------------------- */
.blue-head .txt-input {
    display: flex;
    flex-wrap: nowrap;
    width: 98%;
    padding: 1%;
}
.blue-head .txt-input .txt-holder {
    width: 100%;
}
.blue-head .txt-input .btn-template {
    width: 8%;
}
.line__container .line__contents .popup_wrap .popup_template-ttl {
    text-align: center;
    margin-bottom: 1.4em;
    font-weight: 700;
    font-size: 1.8rem;
}
.line__container .line__contents .popup_wrap input.btn_popup_template1 {
    background: #fff;
    width: 100%;
    margin-bottom: 20px;
    color: #222;
    display: block;
}
.line__container .line__contents .popup_wrap input.btn_popup_template2 {
    background: #dbdbdb;
    width: 100%;
    margin-bottom: 20px;
    color: #222;
    display: block;
}
.line__container .line__contents .popup_template .btn_popup_template1:hover,
.line__container .line__contents .popup_template .btn_popup_template2:hover {
    opacity: 0.8;
}
.btn-template label {
    background: url("../images/common/icon-template.png") center center
        no-repeat;
    background-size: 22px;
}
/*ポップアップ*/
.popup_wrap input {
    display: none;
}
.popup_overlay {
    display: flex;
    justify-content: center;
    overflow: auto;
    position: fixed;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    opacity: 1;
    transition: opacity 0.5s, transform 0s 0.5s;
    transform: scale(1);
}
.popup_trigger {
    position: absolute;
    width: 100%;
    height: 100%;
}
.popup_content {
    position: relative;
    align-self: center;
    width: 90%;
    max-width: 800px;
    padding: 30px 30px 15px;
    box-sizing: border-box;
    background: rgba(255, 221, 120, 0.9);
    line-height: 1.4em;
    transition: 0.5s;
    border-radius: 10px;
}
.close_btn {
    position: absolute;
    top: 14px;
    right: 16px;
    font-size: 30px;
    cursor: pointer;
}
//    .popup_wrap input:checked ~ .popup_overlay {
// 	opacity: 1;
// 	transform: scale(1);
// 	transition: opacity 0.5s;
//    }
.open_btn {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 27.15px;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease;
}
table.table-entry {
    margin-bottom: 30px;
    border-collapse: collapse;
    width: 100%;
    border-top: solid 1px #d3d3d3;
}
.table-entry th,
.table-entry td {
    padding: 30px;
    border-bottom: solid 1px #d3d3d3;
}
.table-entry th {
    background: #eeeeee;
    text-align: left;
}
.table-entry td {
    background: #fff;
    text-align: left;
    padding: 30px;
}
table.table-entry table,
table.table-entry table th,
table.table-entry table td {
    border: none;
}

@media print, screen and (min-width: 1300px) and (max-width: 1545px) {
    select {
        font-size: 100%;
    }
}
@media print, screen and (min-width: 1025px) and (max-width: 1299px) {
    select,
    .search3 a {
        font-size: 1.3vw;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1160px) {
    #logo {
        margin-top: 25px;
        margin-bottom: 25px;
        max-width: 190px;
    }
}

/* PC ----------------------------------------------------------------------------------------- */
@media print, screen and (min-width: 1025px) {
    .tablet,
    .sp {
        display: none;
    }
    #menu-sp,
    #nav-sp,
    #mail-sp,
    .sp,
    #menu-sp {
        display: none;
        text-indent: -9999px;
        line-height: 0%;
        margin: 0px;
        padding: 0px;
    }
    #top {
    }
    img,
    object,
    embed {
        max-width: 100%;
        height: auto;
    }
    img {
        -ms-interpolation-mode: bicubic;
    }
    .anchorlink {
        padding-top: 90px;
        margin-top: -90px;
    }
    .step-flow-three {
        justify-content: left;
    }
    /*広告募集---------------------------------------------------------------*/
    .eg-pc {
        display: block;
    }
    .eg-sp {
        display: none;
    }
    /* column -------------------------------------------------------------- */
    .flex-justify-content1 .inner:nth-child(3n) {
        margin-right: 0;
    }
    /* 4列、8列リストの一番左下にある要素 ------ */
    .column3 .inner:nth-child(3n+1):nth-last-child(-n+3), .column4 .inner:nth-child(4n+1):nth-last-child(-n+4), .column4-ver2 .inner:nth-child(4n+1):nth-last-child(-n+4), .column8 .inner:nth-child(8n+1):nth-last-child(-n+8), /* 4列、8列リストの一番左下にある要素以降にある.innerすべて ------ */ .column3 .inner:nth-child(3n+1):nth-last-child(-n+3) ~ .inner, .column4 .inner:nth-child(4n+1):nth-last-child(-n+4) ~ .inner, .column4-ver2 .inner:nth-child(4n+1):nth-last-child(-n+4) ~ .inner, .column8 .inner:nth-child(8n+1):nth-last-child(-n+8) ~ .inner {
        margin-bottom: 0;
    }
    /* column特定個所 -------------------------------------------------------------- */
    .paidholiday.flex-justify-content1 .inner:nth-child(3n) {
        margin-right: 76%;
    }
    .welfare .inner:nth-child(11) {
        width: 60%;
    }
    /* メールフォーム -------------------------------------------------------------- */
    input.field-w80-pc,
    select.field-w80-pc {
        width: 80%;
    }
    input.field-w48-pc,
    select.field-w48-pc {
        width: 48%;
    }
    input.field-w40-pc,
    select.field-w40-pc {
        width: 40%;
    }
    input.field-w30-pc,
    select.field-w30-pc {
        width: 30%;
    }
    input.field-w35-pc,
    select.field-w35-pc {
        width: 35%;
    }
    input.field-w26-pc,
    select.field-w26-pc {
        width: 26%;
    }
    input.field-w25-pc,
    select.field-w25-pc {
        width: 25%;
    }
    input.field-w20-pc,
    select.field-w20-pc {
        width: 20%;
    }
    input.field-w10-pc,
    select.field-w10-pc {
        width: 10%;
    }
    input.field-w5-pc,
    select.field-w5-pc {
        width: 5%;
    }
}

/* タブレット・スマホ ----------------------------------------------------------------------------------------- */
@media print, screen and (max-width: 1024px) {
    #dropmenu {
        display: block;
    }
    .step-flow-three {
        justify-content: center;
    }
    /* Images -------------------------------------------------------------- */
    .alignleft,
    img.alignleft,
    .alignright,
    img.alignright,
    .aligncenter,
    img.aligncenter {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        display: block;
        margin-bottom: 20px;
    }
    /* column -------------------------------------------------------------- */
    .column {
        display: flex !important;
    }
    .column4-ver2 {
        flex-direction: column;
        align-items: stretch;
    }
    .column2 .inner,
    .column3 .inner,
    .column3 .inner.w100-sp {
        width: 100%;
        margin-bottom: 15px;
    }
    .column3 .inner-w {
        width: 100%;
    }
    .column4 .inner {
        width: 49%;
    }
    .column4-ver2 .inner {
        width: 100%;
        margin-left: 17px;
        box-sizing: border-box;
    }
    .column4-ver2 .inner:nth-child(4n + 1) {
        margin-left: 0;
        font-size: 1.8rem;
    }
    .column5 .inner {
        width: 48%;
        margin-bottom: 15px;
    }
    .column8 .inner {
        width: 24.5%;
    }
    .column2 .inner:last-child,
    .column3 .inner:last-child,
    .column4 .inner:last-child {
        margin-bottom: 0;
    }
    /* .column4時、2列リスト、.column8時、4列リストの一番左下にある要素 ------ */
    .column4 .inner:nth-child(2n+1):nth-last-child(-n+2), .column8 .inner:nth-child(4n+1):nth-last-child(-n+4), /* .column4時、2列リスト、.column8時、4列リストの一番左下にある要素以降にある.innerすべて ------ */ .column4 .inner:nth-child(2n+1):nth-last-child(-n+2) ~ .inner, .column8 .inner:nth-child(4n+1):nth-last-child(-n+4) ~ .inner {
        margin-bottom: 0;
    }
    .column4-ver2 .innner:last-child {
        margin-bottom: 0;
    }
    /* column特定個所 -------------------------------------------------------------- */
    .column-salary .inner {
        width: 100% !important;
    }
    .column-salary2 .inner:nth-child(n + 3) {
        margin-left: 0;
        width: 50%;
    }
    .column-salary .inner div.title-allowances {
        display: block;
    }
    .column-salary .inner br.tablet + input {
        margin-left: 25px;
    }
    .column-salary.column-salary3 .inner {
        width: 73px !important;
    }
    .column-salary3 .inner:nth-child(even) {
        width: calc(100% - 73px) !important;
        margin-left: 0;
    }
    .attribute .inner:first-child {
        width: 40%;
        margin-right: 60%;
    }
    .attribute .inner:nth-child(2),
    .attribute .inner:nth-child(4),
    .attribute .inner:nth-child(5) {
        width: 40%;
    }
    .attribute .inner:nth-child(3) {
        width: 50%;
        font-size: 1.4rem;
    }
    .paidholiday .inner:first-child {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0;
    }
    .paidholiday .inner:nth-child(2) {
        font-size: 1.4rem;
    }
    .paidholiday .inner:nth-child(2),
    .paidholiday .inner:nth-child(5) {
        width: 100%;
    }
    .paidholiday .inner:nth-child(3) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0;
    }
    .paidholiday .inner:nth-child(4) {
        width: 100%;
        padding-top: 8px;
        margin-right: 0;
        margin-bottom: 10px;
    }
    .paidholiday .inner:nth-child(5) input {
        width: 80%;
    }
    .welfare .inner:nth-child(7) {
        margin-right: 32%;
    }
    .welfare .inner:nth-child(8) {
        width: 100% !important;
        margin-bottom: 0;
    }
    .welfare .inner:nth-child(9),
    .welfare .inner:nth-child(10),
    .welfare .inner:nth-child(11) {
        width: 100% !important;
    }
    .welfare.flex-justify-content1 .inner:nth-child(even) {
        margin-right: 0;
    }
    .column-reception {
        justify-content: flex-start;
    }
    .inner-reception {
        width: 48%;
        margin-bottom: 15px;
        margin-right: 1.7%;
    }
    .inner-reception-day {
        width: 80%;
    }
    /* メールフォーム -------------------------------------------------------------- */
    input.field-w80-sp,
    select.field-w80-sp {
        width: 80%;
    }
    input.field-w60-sp,
    select.field-w60-sp {
        width: 60%;
    }
    input.field-w50-sp,
    select.field-w50-sp {
        width: 50%;
    }
    input.field-w48-sp,
    select.field-w48-sp {
        width: 48%;
    }
    input.field-w30-sp,
    select.field-w30-sp {
        width: 30%;
    }
    input.field-w25-sp,
    select.field-w25-sp {
        width: 25%;
    }
    input.field-w20-sp,
    select.field-w20-sp {
        width: 20%;
    }
    input.field-w15-sp,
    select.field-w15-sp {
        width: 15%;
    }
    input.field-w9 {
        width: 10%;
    }
    /* フォント -------------------------------------------------------------- */
    .font-mm-sp {
        font-size: 1.4rem;
    }
    /* ヘッダーリンクボタン -------------------------------------------------------------- */
    #logo {
        position: relative;
        z-index: 110;
    }
    .h-btn {
        z-index: 10000000;
    }
    /* 囲み -------------------------------------------------------------- */
    .wrap {
        padding-top: 60px;
        padding-bottom: 20px;
    }
    /* 見出し -------------------------------------------------------------- */
    h1.title5 {
        padding: 10px 10px 10px 10px;
    }
    #top h2 {
        font-size: 2.4rem;
        margin-bottom: 80px;
    }
    h3.title1 {
        padding: 10px 20px;
        margin-bottom: 30px;
        font-size: 2rem;
    }
    h3.title2 {
        color: #ff848b;
        text-align: center;
        margin-bottom: 20px;
        font-size: 2rem;
    }
    h3.title3 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 2rem;
    }
    h3.title4 {
        color: #ff848b;
        color: #ff848b;
        margin-bottom: 10px;
        font-size: 1.6rem;
    }
    /* ナビゲーション -------------------------------------------------------------- */
    #dropmenu li a {
        background-position: 10px 10px !important;
    }
    /* デンタルリンクの特徴 -------------------------------------------------------------- */
    .features-block {
        width: 90%;
        margin: 0 auto;
    }
    .features-inner {
        width: 100%;
        padding: 60px 30px;
        margin: 0 0px 8%;
    }
    .features-img {
        float: none;
        display: block;
        margin: 0 auto 20px;
    }
    .features-text {
        float: none;
        width: 100%;
    }
    .features-inner:nth-child(4) .features-img {
        float: none;
        display: block;
        width: 100%;
        margin: 0 auto 20px;
    }
    .features-inner:nth-child(4) .features-text {
        float: none;
        width: 100%;
    }
    h3.title2 {
        color: #ff848b;
        text-align: center;
        margin-left: 0px;
        margin-bottom: 20px;
        font-size: 2rem;
    }
    .site-text {
        width: 90%;
        text-align: center;
        margin: 10% auto;
    }
    h3.feature {
        text-align: center;
        margin-bottom: 15%;
        font-size: 1.2em;
        letter-spacing: 0px;
        font-weight: 500;
    }
    /* よく似た求人 ----------------------------------------------------------------------------------------- */
    .similar-slider .slick-slide {
        margin: 0;
    }
    /* 場所を変えて検索 -------------------------------------------------------------- */
    .place-search {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .wrap .place-search .btn-pink2 {
        margin: 30px auto 0;
        max-width: 300px;
        width: 100%;
    }
    .wrap .place-search .btn-pink2:first-child {
        margin: 0 auto 0;
    }
    .place-search .btn-pink2:nth-child(3n) {
        margin-right: auto;
    }
    /* 市町村やこだわりで検索 -------------------------------------------------------------- */
    .commitment-search {
        background-color: #d5d0d0;
        padding: 15px;
        border-radius: 12px;
    }
    .commitment-search .commitment-search-inner {
        width: 100%;
        display: flex;
        background-color: #ffffff;
        box-sizing: border-box;
    }
    .commitment-search .commitment-search-inner .submit-btn {
        position: static;
    }
    .commitment-search .commitment-search-inner input[type="submit"] {
        padding: 17px 12px;
    }
    .commitment-search .commitment-search-inner .submit-btn:before {
        position: static;
        display: none;
    }
    /* table -------------------------------------------------------------- */
    .table1 th {
        border-left: solid 1px #7db182;
        border-bottom: solid 1px #b7b7b7;
        padding: 10px;
    }
    .table1 td {
        border-left: solid 1px #b7b7b7;
        border-bottom: solid 1px #b7b7b7;
        padding: 10px;
    }
    .block th,
    .block td {
        display: block;
        width: 100%;
    }
    /* メールフォーム -------------------------------------------------------------- */
    table select {
        text-align-last: left;
        background-position: right bottom 15px;
    }
    input[type="submit"],
    input[type="button"] {
        width: 135px;
        padding: 20px 0;
    }
    /* 基本情報登録 -------------------------------------------------------------- */
    .icon {
        flex-direction: row;
        width: initial;
        transform: translate(-10%, -50%);
        right: 0px;
    }
    .icon span {
        width: 55px;
    }
    .icon span:first-child {
        margin-bottom: 0;
        margin-right: 10px;
    }
    /*Q&A*/
    .btn-pink3 {
        width: 46%;
        margin-bottom: 3%;
        font-size: 1.6rem;
    }
    #clinic-page .btn-pink3 {
        width: 46%;
        margin-bottom: 3%;
        font-size: 1.6rem;
    }
    /*体験談--------------------------------------------------------------------------*/
    #inner-experiences .inner {
        width: 100%;
        padding: 40px 20px;
        margin-bottom: 50px;
    }
    #inner-experiences .experiences-img {
        float: left;
        width: 100%;
        text-align: center;
    }
    #inner-experiences .experiences-text {
        float: right;
        width: 100%;
        margin-top: 1em;
        font-size: 1.5rem;
        letter-spacing: 0px;
    }
    .experiences-number {
        position: absolute;
        top: 0px;
        left: 0px;
    }
    #inner-experiences .inner:nth-child(2n) .experiences-number {
        left: 0px;
        right: initial;
    }
    /*お問い合わせ*/
    input[type="submit"].btn-inquiry-pink,
    input[type="button"].btn-inquiry-pink {
        width: 135px;
        padding: 20px 0;
    }
    input[type="button"].btn-inquiry-gray {
        width: 135px;
        padding: 20px 0;
    }
    /*検索結果-------------------------------------------------------------------*/
    .clinic-info-inner {
        width: 100%;
    }
    .detail-btn {
        width: 100%;
    }
    .btn-clinic-detail {
        padding: 15px 15px;
        margin: 50px auto 0;
        display: block;
        text-align: center;
        width: 150px;
        background-color: #ff7540;
        color: #ffffff;
        border-radius: 5px;
        font-size: 1.4rem;
        line-height: 1.5;
    }
    /**/
    #search-result-detail .slick-prev {
        left: 5px;
        width: 44px;
        height: 56px;
        background: url("../images/common/arrow-left2.png") center center
            no-repeat;
    }
    #search-result-detail .slick-next {
        right: 5px;
        width: 44px;
        height: 56px;
        background: url("../images/common/arrow-right2.png") center center
            no-repeat;
    }
    /*/////////////////////////////////////////////////
   //医院掲載登録
   /////////////////////////////////////////////////*/
    /* ヘッダーリンクボタン -------------------------------------------------------------- */
    .green-head .h-btn {
        position: static;
        justify-content: flex-start;
    }
    .green-head .h-btn div:nth-child(4) {
        border-right: none;
    }
    .green-head .h-btn div:nth-child(n + 5) {
        display: none;
    }
    .green-head .h-btn div {
        width: 25%;
    }
    .green-head .h-btn div a {
        width: 100%;
        _border-right: solid 1px #ffffff;
        font-size: 1.2rem;
    }
    /* 22基本情報登録 -------------------------------------------------------------- */
    .btt {
        margin: 10px;
    }
    /* 25応募者一覧 -------------------------------------------------------------- */
    #talksearch-wrap input {
        background-image: url(../images/common/icon-search2.png);
        background-position: 10px center;
        background-repeat: no-repeat;
        background-size: 25px;
        z-index: 1;
        padding-left: 40px;
    }
    .applicants-day {
        font-size: 1.4rem;
        text-align: right;
    }
    .applicants-name,
    .applicants-day {
        font-size: 1.4rem;
        padding: 10px 10px 10px 10px;
        width: 50%;
    }
    /* 26連絡用チャット -------------------------------------------------------------- */
    .talkwrapper {
        display: flex;
        flex-direction: column;
    }
    .wrap1 {
        width: 100%;
    }
    .wrap2 {
        width: 100%;
        position: relative;
    }
    /* トークリスト ----------------------------------------------------------------------------------------- */
    .tk-search {
        background: #e8e8e8;
        padding: 12px;
    }
    .tk-search form {
        text-align: center;
    }
    .tk-search form input {
        font-family: FontAwesome;
        width: 100%;
        height: 39px;
        border: none;
        background-image: url(../images/common/icon-search2.png);
        background-position: 20px center;
        background-repeat: no-repeat;
        background-size: 20px;
        padding-left: 50px;
    }
    .tk-search form input::placeholder {
        color: #b9b9b9;
    }
    .tk-list {
        overflow-y: scroll;
        height: 575px;
    }
    .tk-list::-webkit-scrollbar {
        width: 0;
    }
    .tk-list::-webkit-scrollbar-thumb {
        background-color: #b9b9b9;
        height: 10px;
    }
    .select {
        background-color: #e5e5e5;
        border-top: 1px solid #fff;
    }
    .tk-one {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid #a1a1a1;
        align-items: flex-start;
        max-height: 100px;
    }
    .tk-one:last-child {
        border-bottom: none;
    }
    .tk-one:first-child {
        border-bottom: 1px solid #a1a1a1;
    }
    .tk-one .tk-one-l {
        display: flex;
        align-items: center;
    }
    .tk-one .tk-one-l img {
        width: 60px;
    }
    .tk-one .tk-one-l .per-name {
        padding-left: 20px;
        padding-top: 15px;
        padding-bottom: 20px;
    }
    .per-name .tk-li-name,
    .per-name .excerpt {
        padding-bottom: 0;
        line-height: 1.5em;
        padding-bottom: 0;
        font-size: 1.1em;
    }
    .per-name .excerpt {
        font-size: 0.8em;
        color: #b9b9b9;
    }
    .tk-one .tk-one-r .minute {
        color: #b9b9b9;
        padding-bottom: 0;
        white-space: nowrap;
    }
    .tk-one .tk-one-r .notice {
        background-color: #7db182;
        color: #fff;
        border-radius: 66px;
        padding: 3px 14px;
        position: absolute;
        right: 15px;
    }
    .blue-head .tk-one .tk-one-r .notice {
        background-color: #8bc3d8;
    }
    .tk-one .tk-one-r {
        text-align: center;
        position: relative;
    }
    /* トーク画面 ----------------------------------------------------------------------------------------- */
    .ch-top-ic {
        padding: 9px 0px 9px 30px;
        display: flex;
        background: #e5e5e5;
        align-items: center;
        font-size: 1.2em;
    }
    .ch-top-ic img {
        width: 45px;
    }
    .ch-name {
        padding-left: 20px;
        padding-bottom: 0;
    }
    .line__left .line__left-text .text.dental {
        background-color: #8bc3d8;
    }
    .line__container .line__left .text.dental::after {
        color: #8bc3d8;
    }
    .line__left .line__left-text .text.partner {
        background-color: #fdeeef;
    }
    .line__container .line__left .text.partner::after {
        border-right: 20px solid #fdeeef;
    }
    .line__right .text.self {
        background-color: #7db182;
    }
    .line__container .line__right .text.self::after {
        border-left: 20px solid #7db182;
    }
    .txt-input {
        display: flex;
        width: 98%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e8e8e8;
    }
    .txt-input .txt-holder {
        width: 93%;
    }
    .txt-input .txt-submit {
        width: 8%;
    }
    .txt-input .txt-holder input {
        border: none;
    }
    .txt-input .txt-submit input {
        color: #b9b9b9;
        background: #eee;
        /*padding: 21.15px;*/
        padding: 0;
        border-radius: 0;
        width: 100%;
        border-bottom: none;
        height: 100%;
    }
    .line__container .line__right {
        max-width: 95%;
    }
    .line__container .line__left {
        max-width: 95%;
    }
    .line__container .scroll::-webkit-scrollbar {
        width: 0;
    }
    .line__container .scroll {
        padding-bottom: 120px;
    }
    .line__attention {
        bottom: 6.75em;
    }
    /*/////////////////////////////////////////////////
   //運営者管理
   /////////////////////////////////////////////////*/
    /* ヘッダーリンクボタン -------------------------------------------------------------- */
    .blue-head .h-btn {
        position: static;
        justify-content: flex-start;
    }
    .blue-head .h-btn div:nth-child(4) {
        border-right: none;
    }
    .blue-head .h-btn div:nth-child(n + 5) {
        display: none;
    }
    .blue-head .h-btn div {
        width: 25%;
    }
    .blue-head .h-btn div a {
        width: 100%;
        _border-right: solid 1px #ffffff;
        font-size: 1.2rem;
    }
    /* 囲み -------------------------------------------------------------- */
    .wrap3 {
        padding: 4%;
    }
    .wrap4 {
        flex-wrap: wrap;
    }
    .wrap4 .wrap4-l {
        width: 100%;
        padding: 0;
    }
    .wrap4 .wrap4-r {
        width: 100%;
        padding: 10px;
    }
    .wrap-white {
        background: white;
        padding: 10px;
        margin-bottom: 0;
    }
    .wrap4 .wrap4-r.bk-rightside {
        background: #fff;
    }
    .bk-gray-sp {
        background: #eee;
        padding: 10px;
    }
    .wrap5 {
        flex-wrap: wrap;
    }
    .wrap5 .wrap5-l {
        width: 100%;
    }
    .wrap5 .wrap5-r {
        width: 100%;
        padding: 0 0 20px 0;
    }
    /* 見出し -------------------------------------------------------------- */
    h1.title5 {
        padding: 10px 10px 10px 10px;
    }
    h1.title6 {
        padding: 10px 10px 10px 10px;
    }
    #talksearch-wrap input {
        background-image: url(../images/common/icon-search2.png);
        background-position: 10px center;
        background-repeat: no-repeat;
        background-size: 25px;
        z-index: 1;
        padding-left: 40px;
    }
    .applicants-day {
        font-size: 1.4rem;
        text-align: right;
    }
    .applicants-name,
    .applicants-day {
        font-size: 1.4rem;
        padding: 10px 10px 10px 10px;
        width: 50%;
    }
    /* 33応募者一覧用table -------------------------------------------------------------- */
    table.table3 {
        table-layout: auto;
        font-size: 80%;
    }
    .table3 th,
    .table3 td {
        padding: 10px;
        width: 120px;
        min-width: 120px;
    }
    .scroll {
        width: 100%;
        overflow-x: scroll;
    }
    .scroll::-webkit-scrollbar {
        height: 10px;
    }
    .scroll::-webkit-scrollbar-track {
        background: #dddddd;
    }
    .scroll::-webkit-scrollbar-thumb {
        background: #ff8a91;
    }
    /* 34応募者一覧詳細 ----------------------------------------------------------------------------------------- */
    .wrap-aplc-pagenav {
        display: none;
    }
    .wrap-name {
        background: #e8e8e8;
        padding: 10px 0;
        margin-bottom: 0;
        width: 100%;
    }
    .aplc-d-top {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .aplc-d-top-l,
    .aplc-d-top-c,
    .aplc-d-top-r {
        display: flex;
        flex-wrap: nowrap;
    }
    .aplc-d-top-c {
        width: 100%;
    }
    .aplc-d-top-c .aplc-d-date {
        margin-left: 2em;
        font-size: 0.9rem;
        color: #737373;
    }
    .aplc-d-download {
        margin-top: 20px;
        width: 100%;
        display: block;
        text-align: center;
        background: #f8f8f8;
    }
    .aplc-d-download .btn-download a {
        padding: 10px 0 10px 0;
        display: block;
        width: 100%;
        background: url("../images/common/icon-download.png") 94% center
            no-repeat;
        text-indent: -2em;
    }
    .wrap-btnstatus,
    .aplc-status,
    .aplc-status2,
    table input[type="button"].aplc-status,
    .btn-white,
    .wrap-aplc-pagenav2 {
        font-size: 1.4rem !important;
    }
    div .btn-white,
    div input[type="button"].btn-white {
        margin-top: 10px;
    }
    .open_btn {
        padding: 0;
        height: 100%;
    }
    .btn-template label {
        background-size: 16px;
    }
    /* table ----------------------------------------------------------------------------------------- */
    .table4 th,
    .table4 td {
        padding: 10px 10px;
        font-size: 1.2rem;
    }
    .table-entry th,
    .table-entry td {
        padding: 10px;
        box-sizing: border-box;
    }

    /*/////////////////////////////////////////////////
   //歯科衛生士登録
   /////////////////////////////////////////////////*/
    /* ヘッダーリンクボタン -------------------------------------------------------------- */
    .orange-head .h-btn div.logout-btn {
        position: absolute;
        top: 0;
        right: 100px;
        width: 100px;
    }
    .orange-head .h-btn {
        position: static;
        justify-content: flex-start;
    }
    .orange-head .h-btn div {
        width: 20%;
    }
    .orange-head .h-btn div a {
        width: 100%;
        font-size: 1.2rem;
    }
}

/* タブレット -------------------------------------------------------------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .pc,
    .sp {
        display: none;
    }
    /* 見出し -------------------------------------------------------------- */
    h2.title3::after {
        width: 52%;
    }
    #clinic-page h2.title3::after {
        width: 52%;
    }
    /* ヘッダーリンクボタン -------------------------------------------------------------- */
    .h-btn {
        right: 100px;
    }
    /* メイン -------------------------------------------------------------- */
    select,
    input[type="text"],
    .search3 a {
        font-family: "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "メイリオ",
            Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", "Osaka", sans-serif !important;
        font-size: 1.2rem;
    }
    /* 場所を変えて検索 -------------------------------------------------------------- */
    .btn-pink2::before {
        right: 25px;
    }

    /* 26連絡用チャット -------------------------------------------------------------- */
    .line__container .line__right {
        max-width: 80%;
    }
    .line__container .line__left {
        max-width: 80%;
    }

    /* トップ絞り込み検索 -------------------------------------------------------------- */
    .list-conditions {
        top: 229px;
    }
    .list-conditions-inner {
        width: 50%;
    }
}

/* スマホ -------------------------------------------------------------- */
@media screen and (max-width: 767px) {
    .pc,
    .tablet {
        display: none;
    }
    img,
    object,
    embed {
        max-width: 100%;
        height: auto;
    }
    img {
        -ms-interpolation-mode: bicubic;
    }
    /* column -------------------------------------------------------------- */
    .flex-column {
        flex-direction: column;
    }
    #userguide .inner {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    .column4 .inner,
    .column3.column3-w49-sp .inner {
        width: calc(49% - 5px);
    }
    .column3.column3-w49-sp .inner:nth-child(2n) {
        margin-right: 0;
    }
    /* 07医院検索結果 ----------------------------------------------------------------------------------------- */
    .conditions-second-wrap .list-conditions {
        top: -100px;
        left: 0;
        z-index: 10000000;
    }
    /* メールフォーム -------------------------------------------------------------- */
    input.field-w9 {
        width: 20%;
    }
    /* 見出し -------------------------------------------------------------- */
    h2.title3::after {
        width: 12%;
    }
    #clinic-page h2.title3::after {
        width: 12%;
    }
    /* ヘッダー -------------------------------------------------------------- */
    #logo {
        margin-top: 16px;
        margin-bottom: 17px;
    }
    .logo-l {
        width: 145px;
    }
    .h-btn {
        right: 64px;
    }
    .h-btn a {
        padding: 28px 10px 5px 10px;
    }
    .h-btn div.signup a {
        background: url(../images/common/icon-signup.png) center 10px no-repeat;
        background-color: #ffab6d;
        background-size: 20px;
        font-size: 1.2rem;
    }
    .h-btn div.login a {
        background: url(../images/common/icon-login.png) center 10px no-repeat;
        background-color: #ff7540;
        background-size: 20px;
        font-size: 1.2rem;
    }
    /* メイン -------------------------------------------------------------- */
    .main-wrapper {
        background-image: url("../images/common/main-sp.jpg");
    }
    .main {
        padding: 100px 0 50px 0;
        width: 96%;
    }
    .catchcopy {
        width: 100%;
        margin: 0;
    }
    .main-btn {
        width: 100%;
        margin: 0 0 40px;
        text-align: center;
    }
    .main-btn a:first-child {
        margin-right: 3%;
    }
    .btn-orange {
        font-size: 1.6rem;
        background-color: #ffab6d;
        background-position: calc(50% - 44px) center;
        background-size: 23px;
        padding: 10px 0px 10px 20px;
        width: 48%;
        height: 80px;
        box-sizing: border-box;
    }
    .btn-pink {
        font-size: 1.6rem;
        background-color: #ff8a91;
        background-position: calc(50% - 44px) center;
        background-size: 23px;
        padding: 10px 0px 10px 20px;
        width: 48%;
        height: 80px;
        box-sizing: border-box;
    }
    .main-search {
        flex-wrap: wrap;
        justify-content: center;
    }
    .main-search div {
        width: 48%;
        margin-bottom: 20px;
        margin-right: 4%;
    }
    .main-search div:nth-child(2n) {
        margin-right: 0;
    }
    .main-search div:nth-child(5) {
        margin-bottom: 0;
    }
    select,
    input[type="text"] {
        font-size: 1.4rem;
    }
    select {
        background-position: center bottom 15px;
        text-align-last: center;
        padding: 15px 10px 35px;
    }
    .search3 a {
        background-position: center bottom 15px;
        text-align-last: center;
        padding: 6px 10px 25px;
        font-size: 1.4rem;
    }
    .main-search input[type="text"] {
        text-align-last: center;
        padding: 25px 10px 25px;
    }
    /* トップ絞り込み検索 -------------------------------------------------------------- */
    .list-conditions {
        top: 80px;
    }
    .list-conditions-inner {
        width: 50%;
    }
    /* トップページ新着情報 -------------------------------------------------------------- */
    .btn-pink2 {
        padding: 15px 20px;
        margin: 50px auto 50px;
        display: block;
        text-align: center;
        width: 200px;
        background-color: #ff8a91;
        color: #ffffff;
        border-radius: 12px;
        font-size: initial;
    }
    .btn-pink2::before {
        right: 37px;
        width: 10px;
        height: 10px;
    }
    /* デンタルリンクの特徴 -------------------------------------------------------------- */
    .features-inner {
        width: 100%;
        padding: 60px 30px;
        margin: 0px 0px 15%;
    }
    .features-img {
        float: none;
        display: block;
        margin: 0 auto 20px;
    }
    .features-text {
        float: none;
        width: 100%;
    }
    .features-inner:nth-child(3),
    .features-inner:nth-child(4) {
        margin: 0 0 50px 0;
    }
    .features-inner:nth-child(2) h3.title2 img {
        position: absolute;
        top: -90px;
        right: -50px;
        width: 60%;
    }
    h3.title2 img {
        position: absolute;
        top: -90px;
        width: 80%;
        right: initial;
    }
    /* 体験談 -------------------------------------------------------------- */
    .experiences-img,
    .experiences-text {
        float: none;
        width: 100%;
    }
    .experiences-img {
        margin-bottom: 10px;
    }
    #experiences .inner {
        padding: 20px 15px;
        width: 49%;
    }
    .column .inner img {
        max-width: 120px;
    }
    /* バナー -------------------------------------------------------------- */
    .wrap.banner {
        justify-content: flex-start;
        padding: 60px 0 40px;
    }
    .banner li {
        margin-right: 2%;
        width: 49%;
    }
    .banner li:nth-child(even) {
        margin-right: 0;
    }
    /* フッター -------------------------------------------------------------- */
    footer {
        flex-direction: column;
    }
    #footer {
        background-color: transparent;
        padding: 0;
    }
    .footer-right {
        background-color: #ff8a91;
        width: 100%;
        box-sizing: border-box;
        padding: 20px 0 20px 30px;
    }
    .footer-right a {
        color: #ffffff;
    }
    .footer-left {
        position: static;
        width: 100%;
        text-align: center;
        display: block;
        margin: 0 auto;
    }
    .footer-left-inner {
        position: static;
        top: 0;
        left: 0;
        transform: initial;
        -webkit-transform: initial;
        -ms-transform: initial;
        padding: 30px;
        background-color: #d6d6d6;
        width: 100%;
        box-sizing: border-box;
    }
    .footer-menu li a {
        font-size: 1.4rem;
    }
    .footer-menu a:before {
        top: 0px;
        left: -15px;
    }
    .footer-logo {
        text-align: center;
        display: block;
        margin: 0 auto 10px;
        max-width: 220px;
    }
    #backtop a {
        width: 40px;
        height: 40px;
    }
    #backtop a::before {
        left: 14px;
        top: 6px;
        width: 10px;
        height: 10px;
    }
    /* 第二階層 -------------------------------------------------------------- */
    /* 面接サポートとは ----------------------------------------------------------------------------------------- */
    .border-b-pink {
        _border-bottom: solid 1px #ff848b;
    }
    .border-t-pink {
        border-top: none;
    }
    h2.title1 {
        position: relative;
        padding-right: 60px;
    }
    h2.title1::after {
        content: "";
        position: absolute;
        background-color: #ff848b;
        width: 50px;
        height: 1px;
        top: 50%;
        right: 0;
        display: block;
    }
    .support-wrap {
        flex-direction: column;
        padding: 0 0 45px 0;
        margin-bottom: 60px;
    }
    .support-photo {
        max-width: 451px;
        width: 100%;
        margin-bottom: 20px;
    }
    .support-text {
        width: 100%;
    }
    .order1 {
        order: 1;
    }
    .order2 {
        order: 2;
    }
    /*Q&A-------------------------------------------------------------------------------*/
    dl.toggle dt {
        margin-top: 25px;
        cursor: pointer;
        padding: 1.5em 2em 1.5em 3em;
        position: relative;
        background: #fefdfc;
        color: #222222;
        font-size: 1em;
        text-align: left;
        position: relative;
        border-bottom: 0px solid #dadadb;
        font-weight: 500;
    }
    .faq-q img {
        position: absolute;
        left: -35px;
        top: -5px;
    }
    dl.toggle dt::after {
        position: absolute;
        top: 30px;
        right: 15px;
        width: 10px;
        height: 10px;
        border-top: 2px solid #ff848b;
        border-right: 2px solid #ff848b;
        -webkit-transform: rotate(45deg);
        transform: rotate(135deg);
    }
    dl.toggle dt.active::after {
        position: absolute;
        top: 34px;
        right: 15px;
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        border-top: 2px solid #ff848b;
        border-right: 2px solid #ff848b;
        transform: translateX(-50%);
        transform: rotate(-45deg);
    }
    #clinic-page dl.toggle dt::after {
        position: absolute;
        top: 30px;
        right: 15px;
        width: 10px;
        height: 10px;
    }
    #clinic-page dl.toggle dt.active::after {
        position: absolute;
        top: 34px;
        right: 15px;
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
    }
    dl.toggle dd {
        display: none;
        background: #fefdfc;
        text-align: left;
        padding: 1em 1em;
    }
    .faq-a {
        position: relative;
        padding: 1.5em 2em;
        background-color: #f2f2f2;
        line-height: 1.5;
    }
    .btn-pink3 {
        width: 90%;
        margin: 0 auto;
        padding: 10px 0;
        margin-bottom: 3%;
        font-size: 1.6rem;
        letter-spacing: 0px;
    }
    #clinic-page .btn-pink3 {
        width: 90%;
        margin: 0 auto;
        padding: 10px 0;
        margin-bottom: 3%;
        font-size: 1.6rem;
        letter-spacing: 0px;
    }
    .title-qa-2 {
        margin: 2em 0;
        font-weight: 500;
        padding-left: 1em;
        border-left: 7px solid #ff848b;
    }
    .qa-list {
        text-align: initial;
        font-size: 1.4rem;
        margin: 2em 0;
        padding-right: 30px;
        letter-spacing: 0px;
    }
    /*広告募集----------------------------------------------------------------------*/
    .koukoku-ex-inner {
        border: 1px solid #ffd2d5;
        padding: 2em 0em;
        margin: 0em;
        margin-bottom: 2em;
    }
    .btn-inquiry {
        padding: 10px 15px;
        margin: 0px auto 20px;
        display: block;
        text-align: center;
        width: 200px;
        background-color: #ff7540;
        color: #ffffff;
        border-radius: 12px;
        vertical-align: middle;
        position: relative;
        font-size: 1.6rem;
    }
    .koukoku-ex {
        padding: 0em;
    }
    #koukoku .text-center {
        font-size: 1.4rem;
    }
    .eg-pc {
        display: none;
    }
    .eg-sp {
        display: block;
        width: 90%;
        margin: 0 auto;
    }
    /*検索結果---------------------------------------------------------------------*/
    .clinic-info-inner {
        flex-wrap: wrap;
        width: 100%;
    }
    .clinic-info-inner img {
        margin: 0 auto;
        margin-bottom: 2em;
        max-width: 100%;
        max-height: 250px;
    }
    .clinic-info-inner table {
        margin-left: 0%;
        width: 100%;
    }
    .detail-btn {
        width: 100%;
        align-self: self-end;
    }
    .btn-clinic-detail {
        padding: 15px 15px;
        margin: 15px auto 0;
        display: block;
        text-align: center;
        width: 150px;
        background-color: #ff7540;
        color: #ffffff;
        border-radius: 5px;
        font-size: 1.4rem;
        line-height: 1.5;
    }
    .pagenavi {
        flex-wrap: wrap;
        font-size: 0.7em;
    }
    /*医院検索結果詳細網掛け*/
    #search-result-detail h4 {
        margin-bottom: 10%;
    }
    .clinic-img {
        width: 90%;
        margin: 0 auto;
    }
    #search-result-detail .choice-btn .slick-track {
        width: 100% !important;
    }
    .detail-info {
        margin-top: 3em;
        margin-bottom: 3em;
    }
    .detail-info td:nth-child(1) {
        width: 35%;
    }
    .job-feature li {
        width: 47%;
    }
    .detail-info-2 {
        margin-top: 2em;
        margin-bottom: 5em;
    }
    .filter-text {
        top: 10%;
    }
    .filter-orange .btn-orange {
        top: 20%;
    }
    /*14いいねした医院------------------------------------------------------------------------------------------*/
    #good .btn-clinic-detail {
        padding: 20px 15px;
        margin: 15px auto 0;
        display: block;
        text-align: center;
        width: 150px;
        background-color: #ff7540;
        color: #ffffff;
        border-radius: 12px;
        font-size: 1.4rem;
        line-height: 1.5;
    }
    #good .article-like {
        color: #858181;
        font-size: 10px;
        position: absolute;
        right: 0;
        top: 15px;
        text-align: center;
        line-height: 1.2;
        cursor: pointer;
    }
    /*15医院詳細情報------------------------------------------------------------*/
    .detail-info .flex-sp td:nth-child(1) {
        width: 100%;
    }
    .clinic-message-box img {
        width: 100%;
        text-align: center;
        max-width: 500px;
        max-height: 100%;
        margin: 0 auto;
    }
    .clinic-message-text {
        width: 96%;
        padding: 2%;
    }
    #search-result-detail .clinic-message h4 {
        margin: 2% 0;
    }
    .flex-sp {
        display: flex;
        flex-wrap: wrap;
    }
    /*12,18フォーム------------------------------------------------------------*/
    .table-entry th,
    .table-entry td {
        padding: 10px;
        box-sizing: border-box;
    }

    /*/////////////////////////////////////////////////
   //歯科衛生士登録
   /////////////////////////////////////////////////*/
    /* ヘッダーリンクボタン -------------------------------------------------------------- */
    .orange-head .h-btn div.logout-btn {
        right: 62px;
        width: 64px;
        height: 64px;
    }
    .orange-head .h-btn div.logout-btn a {
        height: 15px;
    }
    .orange-head .h-btn div a {
        font-size: 1rem;
    }
    /* 09歯科衛生士新規会員登録 -------------------------------------------------------------- */
    .registration-inner {
        padding: 30px 20px;
    }

    /*/////////////////////////////////////////////////
   //医院掲載登録
   /////////////////////////////////////////////////*/
    /* 22基本情報登録 -------------------------------------------------------------- */
    .btt {
        display: block;
        width: 100%;
        box-sizing: border-box;
        margin: 0;
    }
    /* 23エントリーシート登録 -------------------------------------------------------------- */
    .fileup1 label,
    .fileup2 label,
    .fileup3 label {
        padding: 10px 10px;
        margin-left: 20px;
    }
}

/* スマホ(中) -------------------------------------------------------------- */
@media screen and (max-width: 550px) {
    .job-feature li {
        width: 80%;
        margin: 2% 0;
    }
    /*15医院詳細情報-------------------------------------------------------------*/
    .detail-info table {
        font-size: 1.3rem;
    }
}

/* スマホ(小) -------------------------------------------------------------- */
@media screen and (max-width: 360px) {
    .logo-l {
        width: 100px;
    }
    table th,
    table td,
    .checkbox-label {
        font-size: 1.2rem !important;
    }
    .column4-ver2 .inner:nth-child(4n + 1) {
        font-size: 1.3rem;
    }
    .column-salary .inner div.title-sougaku {
        margin-right: 12px;
    }
    .column-salary .inner br.tablet + input {
        margin-left: 18px;
    }
    /* column特定個所 -------------------------------------------------------------- */
    .column-salary .inner div.title-sougaku {
        margin-right: 12px;
    }
    .column-salary .inner br.tablet + input {
        margin-left: 18px;
    }
    /*/////////////////////////////////////////////////
   //医院掲載登録
   /////////////////////////////////////////////////*/
    /* ヘッダーリンクボタン -------------------------------------------------------------- */
    .green-head .h-btn div a {
        font-size: 1rem;
    }
    /* 22基本情報登録 -------------------------------------------------------------- */
    .registration {
        padding: 15px;
    }
    .btt {
        padding: 20px;
    }
}

/*パソコン（大）---------------------------------------------------------------*/
@media screen and (max-width: 1200px) {
    .clinic-info-inner table td:first-child {
        width: initial;
    }
    .clinic-info-inner table td:nth-child(2) {
        width: initial;
    }
}
