@charset "utf-8";
.btn-offer {
    display: block;
    border-radius: 6px;
    padding: 0.5em 1em;
    color: $white;
    background-color: $light-blue;
}
.btn-cancel {
    display: inline-block;
    border-radius: 6px;
    padding: 0.2em 0.5em;
    color: $white;
    background-color: $dark-gray;
}

input[type="button"].aplc-delete {
    padding: 0.5em;
}

.favarite-delete-btn {
    text-decoration: underline;
    margin-left: 10px;
    &:hover {
        color: $light-blue;
    }
}

.btn-resume {
    display: inline-block;
    border-radius: 6px;
    background: $default-gray;
    padding: 0.5em 1.5em;
    color: $white;
    line-height: 140%;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0.1em;
    &:hover {
        color: $white;
    }
}
