@charset "utf-8";
$clinic-light-green: #d1e8d3;
$chat-title-bg-clinic: #e5f9e7;
$chat-title-bg-hygienist: #fdeeef;
$chat-title-bg-select: #e5e5e5;
$light-gray: #8f8f8f;
$dark-gray: #676565;
$gray: #818181;
$border-gray: #d6d6d6;
$default-gray: #959595;
$green: #7db182;
$pink: #ff8a91;
$dark-pink: #ff8124;
$white: #fff;
$red: #d91515;
$alert-red: #ff0000;
$black: black;
$light-blue: #0b5ed7;
/*device width*/
$pc: 1024px;
$tb: 912px;
$sp: 767px;
$sp_s: 555px;
//default
%maru-gothic {
    font-family: "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "メイリオ",
        Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", "Osaka", sans-serif;
}

%link-blue {
    color: #0d779e;
}

%bg-green {
    background-color: $green;
}

%bg-pink {
    background-color: $pink;
}

%radius {
    border-radius: 6px;
}
